import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CircularProgress, Grid, Stack } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import { saveOrderState } from "redux-store/reducers/common";
import { slugifyCompare } from "utils/common";
import dayjs from 'utils/dayjs-timezone';
import { isMobile } from "utils/ui-uitils";
import BaseInput from "./BaseInput";
import BaseSelect from "./BaseSelect";

type Order = "asc" | "desc";

// function RowMenu() {
//   return (
//     <Dropdown>
//       <MenuButton
//         slots={{ root: IconButton }}
//         slotProps={{ root: { variant: "plain", color: "neutral", size: "sm" } }}
//       >
//         <MoreHorizRoundedIcon />
//       </MenuButton>
//       <Menu size="sm" sx={{ minWidth: 140 }}>
//         <MenuItem>Edit</MenuItem>
//         <MenuItem>Rename</MenuItem>
//         <MenuItem>Move</MenuItem>
//         <Divider />
//         <MenuItem color="danger">Delete</MenuItem>
//       </Menu>
//     </Dropdown>
//   );
// }

const getSortListInCurrentPage = ({
  data,
  page,
  pageSize,
  orders
}: {
  data: any[];
  page: number;
  pageSize: number;
  orders: {
    key: string;
    order: Order;
  }[];
}) => {
  const sortedList = orders?.length ? _.orderBy(data, orders.map(o => o.key), orders.map(o => o.order)) : data;
  if (!sortedList?.length) return [];
  if (sortedList.length <= pageSize) return sortedList;
  return sortedList?.slice((page - 1) * pageSize, page * pageSize);
};

const Pagination = ({
  currentPage,
  pageSize,
  total,
  onChange,
}: {
  currentPage: number;
  pageSize: number;
  total: number;
  onChange: (page: number) => void;
}) => {
  const maxPageNumber = isMobile() ? 4 : 7;
  const beforeNum = Math.ceil(maxPageNumber / 2);
  const afterNum = Math.floor(maxPageNumber / 2);
  const betweenNum = isMobile() ? 0 : 2;
  const listPages = useMemo(() => {
    const list: {
      value: string | number;
      isBack?: boolean;
    }[] = [];
    if (total <= pageSize) {
      return [];
    }
    if (total <= pageSize * maxPageNumber) {
      for (let i = 1; i <= Math.ceil(total / pageSize); i++) {
        list.push({
          value: i,
        });
      }
      return list;
    }
    if (currentPage <= beforeNum) {
      for (let i = 1; i <= beforeNum; i++) {
        list.push({
          value: i,
        });
      }
      list.push({
        value: "...",
      });
      for (
        let i = Math.ceil(total / pageSize) - 1;
        i <= Math.ceil(total / pageSize);
        i++
      ) {
        list.push({
          value: i,
        });
      }
      return list;
    }
    if (currentPage >= Math.ceil(total / pageSize) - afterNum) {
      list.push({
        value: 1,
      });
      list.push({
        value: "...",
        isBack: true,
      });
      for (
        let i = Math.ceil(total / pageSize) - afterNum;
        i <= Math.ceil(total / pageSize);
        i++
      ) {
        list.push({
          value: i,
        });
      }
      return list;
    }
    list.push({
      value: 1,
    });
    list.push({
      value: "...",
      isBack: true,
    });
    for (let i = currentPage; i <= currentPage + betweenNum; i++) {
      list.push({
        value: i,
      });
    }
    list.push({
      value: "...",
    });
    list.push({
      value: Math.ceil(total / pageSize),
    });

    return list;
  }, [currentPage, pageSize, total]);
  return (
    <Box
      className="Pagination-laptopUp"
      sx={{
        pt: 2,
        gap: {
          xs: 0.5,
          sm: 1,
        },
        [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
        display: total <= pageSize ? "none" : "flex",
      }}
    >
      <Button
        size="sm"
        variant="outlined"
        color="neutral"
        disabled={currentPage === 1}
        onClick={() => {
          onChange(currentPage - 1);
        }}
      >
        <KeyboardArrowLeftIcon />
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "initial",
            },
          }}
        >
          Previous
        </Box>
      </Button>

      <Box sx={{ flex: 1 }} />
      {listPages.map(({ value: page, isBack }, idx) => (
        <IconButton
          key={page + "_" + idx}
          size="sm"
          variant={Number(page) ? "outlined" : "plain"}
          color={Number(page) === currentPage ? "primary" : "neutral"}
          onClick={() => {
            if (typeof page === "number") {
              onChange(page);
            } else {
              // handle click on ...
              if (page === "...") {
                if (!isBack) {
                  onChange(currentPage + 1);
                } else {
                  onChange(currentPage - 1);
                }
              }
            }
          }}
        >
          {page}
        </IconButton>
      ))}
      <Box sx={{ flex: 1 }} />

      <Button
        size="sm"
        variant="outlined"
        color="neutral"
        disabled={!total || currentPage === Math.ceil(total / pageSize)}
        onClick={() => {
          onChange(currentPage + 1);
        }}
      >
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "initial",
            },
          }}
        >
          Next
        </Box>
        <KeyboardArrowRightIcon />
      </Button>
    </Box>
  );
};

const applyFilterToList1Time = ({
  data,
  selectedStatus,
  statusKey,
  searchText,
  searchKeys,
  customFilter,
}: {
  data: any[];
  selectedStatus: string;
  statusKey: string;
  searchText: string;
  searchKeys: string[];
  customFilter?: (item: any, selectedStatus: string) => boolean;
}) => {
  if (!data?.length) return [];
  return _.filter(data, (item) => {
    if (selectedStatus) {
      if (customFilter) {
        const filterVal = customFilter(item, selectedStatus);

        if (!filterVal) return false;
      }

      if (selectedStatus !== item[statusKey]) return false;
    }
    if (searchText) {
      if (
        !searchKeys.some((key) =>
          item[key]?.toLowerCase().includes(searchText.toLowerCase())
        )
      )
        return false;
    }
    return true;
  });
};


const applyFilterToList = ({
  data,
  filterValue,
  filterFields,
}: {
  data: any[];
  filterValue?: any;
  filterFields?: {
    type: string;
    key: string;
    keys?: string[];
  }[];
}) => {
  if (!data?.length) return [];
  if (!filterValue) return data;

  return _.filter(data, (item) => {
    return _.every(filterFields, (filterField) => {
      const { type, key } = filterField || {};
      const value = filterValue[key];

      if (!_.isNumber(value) && _.isEmpty(value)) return true;
      if (type === "text") {
        return slugifyCompare(item[key], value);
      }
      if (type === "texts") {
        const keys = filterField.keys || [];
        return keys.some((k) => slugifyCompare(item[k], value));
      }
      if (type === "multiple-select") {
        return value?.includes?.(item[key]);
      }
      if (type === "dropdown-select") {
        return value === item[key];
      }
      if (type === "date") {
        if (!item[key]) {
          return false;
        }

        const dateNumber = new Date(item[key]).getTime();
        const from = dayjs(value?.from).valueOf();
        const to = dayjs(value?.to).valueOf();
        if (from && to) {
          return dateNumber >= from && dateNumber <= to;
        }
        if (from) {
          return dateNumber >= from;
        }
        if (to) {
          return dateNumber <= to;
        }
      }

      return true;
    });
  });
};


interface IProps<T> {
  data: T[];
  noLoading?: boolean;
  columns: {
    key: string;
    label: React.ReactNode;
    hide?: boolean;
    stopPropagation?: boolean;
    rawValue?: (item: T) => any;
    render?: (item: T) => React.ReactNode;
    width?: number | string;
  }[];
  searchKeys?: string[];
  searchTextProp?: string
  sortKeys?: string[];
  filterStatus?: {
    statusKey: string;
    data: {
      label: string;
      value: string;
    }[];
    customFilter?: (item: T, selectedStatus: string) => boolean;
  };
  pageSize?: number;
  onRowClick?: (item: T) => void;
  size?: "sm" | "md";
  tableActions?: React.ReactNode;
  mobileTitle?: string;
  renderSubList?: (list: any) => React.ReactNode;
  defaultStatusFilter?: string;
  onChangeStatusFilter?: (status: string) => void;
  onChangeList?: (list: any) => void;
  filterValue?: any;
  filterFields?: { type: string; key: string; keys?: string[] }[];
  maxHeight?: string | number;
}
export default function CommonList<T>({
  data: _data,
  noLoading = false,
  columns,
  searchKeys: searchKeysProps,
  searchTextProp,
  sortKeys,
  filterStatus,
  pageSize = 40,
  onRowClick,
  size,
  tableActions,
  mobileTitle,
  renderSubList,
  defaultStatusFilter = "",
  onChangeStatusFilter,
  onChangeList,
  filterValue,
  filterFields,
  maxHeight = "calc(100vh - 300px)",
}: IProps<T>) {
  const dispatch = useAppDispatch();
  const orderKeyHash = sortKeys?.join("_");
  const order = useAppSelector((state) => {
    return (orderKeyHash && sortKeys && (state.common.sortOrderState[orderKeyHash] || [{
      key: sortKeys[0],
      order: "asc",
    }])) || [];
  });

  const searchKeys = searchKeysProps || [];
  const data = useMemo(() => {
    return _data?.map((item) => {
      const newItem = { ...item };
      columns.forEach((column) => {
        if (column.rawValue) {
          // @ts-ignore
          newItem[column.key] = column.rawValue(item);
        }
      });
      return newItem;
    });
  }, [_data, columns]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const {
    statusKey,
    data: statusData,
    customFilter,
  } = filterStatus || {
    statusKey: "",
    data: [],
  };

  const [selectedStatus, setSelectedStatus] = useState<string>("");

  useEffect(() => {
    setSelectedStatus(defaultStatusFilter);
  }, [defaultStatusFilter]);
  const filter1Time = useMemo(() => {
    return applyFilterToList1Time({
      data,
      selectedStatus,
      statusKey,
      searchText,
      searchKeys,
      customFilter,
    });
  }, [data, selectedStatus, statusKey, searchText, searchKeys]);

  const filteredRows = useMemo(() => {
    return applyFilterToList({
      data: filter1Time,
      filterValue,
      filterFields,
    });
  }, [filter1Time, filterValue, filterFields]);


  const displayRows = useMemo(() => {
    return getSortListInCurrentPage({
      data: filteredRows,
      page,
      pageSize,
      orders: order,
    });
  }, [filteredRows, page, pageSize, JSON.stringify(order)]);
  useEffect(() => {
    setPage(1);
  }, [searchText, selectedStatus, filterValue]);
  useEffect(() => {
    const displayRowIds = displayRows.map((row) => row.id);
    onChangeList?.(displayRowIds);
  }, [JSON.stringify(displayRows.map((row) => row.id))]);

  useEffect(() => {
    setSearchText(searchTextProp || "")
  }, [searchTextProp]);


  const searchTextPlaceholder = `Search by ${searchKeys
    .map((key) => columns.find((column) => column.key === key)?.label || key)
    .join(", ")}`;

  const renderFilters = () => (
    <>
      <FormControl size="sm">
        <BaseSelect
          label={"Filter by status"}
          options={statusData}
          value={selectedStatus}
          onChange={(e) => {
            setSelectedStatus(e);
            onChangeStatusFilter?.(e);
          }}
        />
      </FormControl>
    </>
  );

  const shouldShowLoading =
    !noLoading && !(_data as any)?.finishLoading && !_data?.length;
  const loadingComponent = (
    <tr>
      <td
        colSpan={columns.filter((v) => !v.hide).length}
        style={{
          textAlign: "center",
          padding: "16px 0",
        }}
      >
        <CircularProgress color="neutral" />
      </td>
    </tr>
  );

  const emptyComponent = (
    <tr>
      <td
        colSpan={columns.filter((v) => !v.hide).length}
        style={{
          textAlign: "center",
          padding: "16px 0",
        }}
      >
        <Typography>No data</Typography>
      </td>
    </tr>
  );
  return (
    <Stack spacing={0}>
      <Grid
        container
        spacing={2}
        sx={{
          my: 0,
        }}
      >
        {!!searchKeys.length && (
          <Grid sm={7} xs={12}>
            <FormControl sx={{ flex: 1 }} size="sm">
              <BaseInput
                label={searchTextPlaceholder}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </FormControl>
          </Grid>
        )}
        {!!statusKey && (
          <Grid sm xs={12}>
            {!!statusKey && renderFilters()}
          </Grid>
        )}
        {!!tableActions && (
          <Grid
            sm
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {tableActions}
          </Grid>
        )}
      </Grid>
      <Sheet
        variant="outlined"
        sx={{
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
          maxHeight: maxHeight,
        }}
      >
        <Table
          stickyHeader
          hoverRow
          stripe="even"
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "8px",
            "--TableCell-paddingX": "8px",
            "table-layout": "auto",
            "& tbody tr:nth-of-type(even)": {
              backgroundColor: "var(--joy-palette-background-level1)", // Adjust the color as needed
            },
          }}
        >
          <thead>
            <tr>
              {columns
                .filter((v) => !v.hide)
                .map((column) => {
                  const orderData = order.find((o) => o.key === column.key);
                  const orderIndex = order.findIndex(
                    (o) => o.key === column.key
                  );


                  return <th
                    key={column.key}
                    style={{ padding: "8px", width: isMobile() ? "" : column.width }}
                  >
                    {orderKeyHash && sortKeys?.includes(column.key) ? (
                      <Typography
                        component="a"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (!orderData) {
                            dispatch(saveOrderState({
                              hashKey: orderKeyHash,
                              data: [
                                ...order,
                                {
                                  key: column.key,
                                  order: "asc",
                                },
                              ]
                            }));
                          } else if (orderData?.order === 'desc') {
                            const newOrder = [...order];
                            newOrder.splice(orderIndex, 1);
                            dispatch(saveOrderState({
                              hashKey: orderKeyHash,
                              data: newOrder
                            }));
                          } else {
                            const newOrder = [...order];
                            newOrder[orderIndex] = {
                              key: column.key,
                              order: orderData?.order === "asc" ? "desc" : "asc",
                            };
                            dispatch(saveOrderState({
                              hashKey: orderKeyHash,
                              data: newOrder
                            }));
                          }

                        }}
                        endDecorator={
                          orderData ? (
                            <Box sx={{
                              position: "relative",
                              width: 20,
                            }}>
                              <ArrowDropDownIcon sx={{
                                top: -11,
                                right: -4,
                                position: "absolute",
                              }} />
                              <span style={{
                                position: "absolute",
                                top: -3,
                                right: 0,
                                transform: "translate(50%, -50%)",
                                fontSize: 10,
                                fontWeight: 400
                              }}>{orderIndex + 1}</span>
                            </Box>
                          ) : null
                        }
                        sx={{
                          "& svg": {
                            transition: "0.2s",
                            transform:
                              orderData?.key === column.key && orderData?.order === "desc"
                                ? "rotate(0deg)"
                                : "rotate(180deg)",
                          },
                        }}
                      >
                        {column.label}
                      </Typography>
                    ) : (
                      <Typography>{column.label}</Typography>
                    )}
                  </th>
                })}
            </tr>
          </thead>
          <tbody>
            {shouldShowLoading
              ? loadingComponent
              : displayRows?.length
                ? displayRows?.map((row: any) => {
                  const subListRenderCmp = renderSubList?.(row);
                  return (
                    <>
                      <tr
                        key={row.id}
                        onClick={() => onRowClick?.(row)}
                        style={{
                          cursor: onRowClick ? "pointer" : "default",
                        }}
                      >
                        {columns
                          .filter((v) => !v.hide)
                          .map((column) => (
                            <td
                              style={{ padding: "8px" }}
                              key={column.key}
                              onClick={(e) => {
                                if (column.stopPropagation) {
                                  e.stopPropagation();
                                }
                              }}
                            >
                              {column.render?.(row) ?? row[column.key]}
                            </td>
                          ))}
                        {/* <td key={"actions"}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <RowMenu />
                      </Box>
                    </td> */}
                      </tr>
                      {subListRenderCmp && (
                        <tr>
                          <td></td>
                          <td colSpan={columns.length - 1}>
                            {subListRenderCmp}
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })
                : emptyComponent}
          </tbody>
        </Table>
      </Sheet>

      <Pagination
        currentPage={page}
        pageSize={pageSize}
        total={filteredRows?.length ?? 0}
        onChange={(page) => setPage(page)}
      />
    </Stack>
  );
}
