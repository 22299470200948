import { TextField, TextFieldProps } from "@mui/material";
import { isNumber } from "lodash";

export default function BaseNumberInput(
  props: {
    className?: string;
    disabled?: boolean;
    value?: number;
    onChange?: (newVal: any) => void;
    min?: number;
    max?: number;
  } & Omit<TextFieldProps, "value" | "onChange">
) {
  const handleInput = (e: any) => {
    const textVal = e.target.value.replace(/[^0-9]/g, "");
    if (textVal === "") {
      props.onChange?.("");
      return;
    }
    const newVal = Number(textVal);
    if (isNumber(props.min) && newVal < props.min) {
      props.onChange?.(props.min);
    } else if (isNumber(props.max) && newVal > props.max) {
      props.onChange?.(props.max);
    } else {
      props.onChange?.(newVal);
    }
  };
  return (
    <TextField
      {...props}
      value={props.value}
      onChange={handleInput}
      size="small"
      disabled={props.disabled}
    />
  );
}
