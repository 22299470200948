import { Box } from "@mui/joy";
import CustomerDetailPage from "pages/customer-admin/detail";
import InvoiceDetailPage from "pages/invoice/detail";
import SalesOrderDetailPage from "pages/sales-order/detail";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ROUTERS from "routers/constants";
import BaseFullScreenModal from "./BaseFullScreenModal";

interface IViewDetailModalProps {
  type: "CUSTOMER" | "INVOICE" | "SO";
  id: any;
}
let showDetailModal = null as unknown as ({
  type,
  id,
}: IViewDetailModalProps) => Promise<unknown>;

export function ViewDetailModalWrapper() {
  let [searchParams, setSearchParams] = useSearchParams();


  const [isOpen, setIsOpen] = useState<
    | (IViewDetailModalProps & {
      onUpdate: (value?: any) => void;
      onClose: () => void;
    })
    | null
  >(null);

  const showDetailModalLocal = useCallback(
    ({ type, id }: IViewDetailModalProps) => {
      return new Promise<boolean>((resolve) => {
        setIsOpen({
          type,
          id,
          onUpdate: () => {
            resolve(true);
            setIsOpen(null);
          },
          onClose: () => {
            resolve(false);
            setIsOpen(null);
            setSearchParams({
              ...searchParams,
              [type + 'detail_popup_id']: '',
            });
          },
        });
      });
    },
    []
  );

  useEffect(() => {
    showDetailModal = showDetailModalLocal;
  }, [showDetailModalLocal]);


  const type = isOpen?.type;
  const id = isOpen?.id;
  const detailViewProps = {
    detailId: id,
    onViewModalCancel: isOpen?.onClose,
    onViewModalUpdate: isOpen?.onUpdate,
  };
  return (
    <>
      {!!type && !!id && (
        <BaseFullScreenModal
          isOpen={true}
          onClose={isOpen.onClose}
          title={""}
          fullscreen
          showRefresh
        >
          <Box
            sx={{
              py: 2,
            }}
          >
            {type === "CUSTOMER" && <CustomerDetailPage {...detailViewProps} />}
            {type === "INVOICE" && <InvoiceDetailPage {...detailViewProps} />}
            {type === "SO" && <SalesOrderDetailPage {...detailViewProps} />}
          </Box>
        </BaseFullScreenModal>
      )}
    </>
  );
}
const ViewDetail = {
  show: (params: IViewDetailModalProps) => showDetailModal(params),
};

export const ViewDetailLink = (
  props: IViewDetailModalProps & {
    children: React.ReactNode;
    openInNewTab?: boolean;
  }
) => {
  if (!props.id) return <>{props.children}</>;
  return (
    <Box
      sx={{
        color: (theme) => theme.palette.primary[600],
        "&:hover *": {
          color: (theme) => theme.palette.primary[600],
        }
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (props.openInNewTab) {
          switch (props.type) {
            case "CUSTOMER":
              window.open(
                ROUTERS.CUSTOMER_DETAIL.replace(":id", props.id as any),
                "_blank"
              );
              break;
            case "INVOICE":
              window.open(
                ROUTERS.INVOICE_DETAIL.replace(":id", props.id as any),
                "_blank"
              );
              break;
            case "SO":
              window.open(
                ROUTERS.SALES_ORDER_DETAIL.replace(":id", props.id as any),
                "_blank"
              );
              break;
          }
        } else {
          showDetailModal(props);
        }
      }}
    >
      {props.children}
    </Box>
  );
};
export default ViewDetail;
