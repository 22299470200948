import {
  FileDownload,
  MoreVert,
  Visibility,
  VisibilityOff,
  WidgetsOutlined
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Dropdown,
  FormControl,
  Grid,
  IconButton,
  ListDivider,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Table,
  Tooltip,
  Typography,
  useTheme
} from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { ITEM_TYPE, ITEM_TYPE_VALUE, POStatusEnum, PalletStatusEnum, SERIAL_VERSIONS } from "app-constants";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  assignMultiPalletV2,
  generateSerialNumberV2,
  getPOFactoryItemV2,
  removePalletFromSerial,
  resetSerialNumberV2
} from "redux-store/reducers/manufacturing";
import { IItemPallet } from "types/inventory";
import { IPurchaseOrder, IPurchaseOrderItem } from "types/purchase";
import { exportBarCodeCSV, showProductItemName } from "utils/common";

import dayjs from 'utils/dayjs-timezone';
import notification from "utils/notification";
import { RolesEnum } from "utils/roles";
import BaseDatePicker from "./BaseDatePicker";
import BaseInput from "./BaseInput";
import BaseModal from "./BaseModal";
import BaseNumberInput from "./BaseNumberInput";
import BaseSelect from "./BaseSelect";
import ConfirmUtils from "./ConfirmUtils";

const grid = 8;
const getItemStyle = (
  realItem: any,
  draggableStyle: any,
  colorMap: any
) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: `4px 8px`,
  margin: `0 0 ${grid}px 0`,
  // change background colour if dragging
  border: `1px solid ${colorMap[realItem?.item_id]}`,
  borderBottom: `4px solid ${colorMap[realItem?.item_id]}`,
  borderRadius: 4,
  background: "var(--mui-palette-common-background)",
  ...draggableStyle,
});

export function LotRealItemDragV2({
  purchaseOrderDetail,
  colorMap,
  viewMode,
  lineItem,
  palletActive
}: {
  purchaseOrderDetail: IPurchaseOrder;
  colorMap: {
    [key: string]: string;
  };
  viewMode?: "warehouse" | "manufacturing";
  lineItem: IPurchaseOrderItem;
  palletActive?: any;
}) {
  const [confirmCreateLot, setConfirmCreateLot] = useState<{
    numberLot: number;
    selectedLot: string;
  } | null>(null);

  const dispatch = useAppDispatch();
  const roleName = useAppSelector((state) => state.auth.user?.roleName);
  const [alllowSerial, setAlllowSerial] = useState<number>(0);
  const [showRealItem, setShowRealItem] = useState<boolean>(false);
  const [openSelectMultiPallet, setOpenSelectMultiPallet] = useState<boolean>(false);
  const [palletItems, setPalletItems] = useState<IItemPallet[]>([]);
  const [palletObject, setPalletObject] = useState<any>({});

  const [serialListPickup, setSerialListPickup] = useState<any[]>([]);
  const [serialSelected, setSerialSelected] = useState<number[]>([]);

  const [openGenerateSerialNumber, setOpenGenerateSerialNumber] = useState<{
    version: string;
    version_date: string;
    version_quantity: number;
    version_package?: string;
  }>();

  const [gernerateVersionLoading, setGernerateVersionLoading] = useState(false);

  const theme = useTheme();
  const onUpdateSuccess = () => {
    fetchRealItems(lineItem?.item?.id);
  };

  const fetchRealItems = (item_id: number) => {
    if (!item_id) return;
    dispatch(
      getPOFactoryItemV2({
        po_id: purchaseOrderDetail.id,
        item_id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        let totalSerial = _.sumBy(response?.data || [], 'quantity');
        if ([ITEM_TYPE_VALUE.fset, ITEM_TYPE_VALUE.rset].includes(lineItem.item.type)) {
          totalSerial = Math.floor(totalSerial / lineItem.item.compo)
        }
        setAlllowSerial((lineItem.quantity || 0) - totalSerial)
        setPalletItems(response?.data || []);
      });
  };

  const resetSerilaNumber = async () => {
    const item_id: any = lineItem?.item.id;
    if (!item_id) return;
    if (!(await ConfirmUtils.sure())) return;
    dispatch(
      resetSerialNumberV2({
        po_id: purchaseOrderDetail.id,
        item_id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          fetchRealItems(lineItem?.item?.id);
        } else {
          notification.error('Action failed')
        }
      });
  };

  function ItemMenuActions(palletDefault: IItemPallet) {
    return (
      <Dropdown>
        <MenuButton variant="plain" size="sm">
          <MoreVert fontSize="small" />
        </MenuButton>
        <Menu size="sm" placement="left-end">

          <MenuItem
            onClick={() => {
              setPalletObject({})
              setSerialListPickup(palletDefault.serials || [])
              setOpenSelectMultiPallet(true)
            }}
          >
            <ListItemDecorator sx={{ color: "inherit" }}>
              <WidgetsOutlined fontSize="small" />
            </ListItemDecorator>{" "}
            Select Pallet
          </MenuItem>

          <MenuItem
            color="danger"
            onClick={resetSerilaNumber}
          >
            <ListItemDecorator sx={{ color: "inherit" }}>
              <WidgetsOutlined fontSize="small" />
            </ListItemDecorator>{" "}
            Reset Serial Number
          </MenuItem>

          <MenuItem
            onClick={() => {
              let srals: any[] = palletDefault?.serials?.map((v: { serial_number: any; }) => ({
                serial_number: v.serial_number,
                code: lineItem?.item?.code,
              })) || []
              exportBarCodeCSV(
                srals
              );
            }}
          >
            <ListItemDecorator sx={{ color: "inherit" }}>
              <FileDownload fontSize="small" />
            </ListItemDecorator>{" "}
            Export Serial Numer
          </MenuItem>
        </Menu>
      </Dropdown>
    );
  }

  useEffect(() => {
    onUpdateSuccess();
  }, []);

  const handleSerialDate = (serial: any) => {
    const n: string = serial?.serial_number
    return n ? `${n.slice(0, 2)} - ${n.slice(2, 4)}/${n.slice(4, 6)}/20${n.slice(6, 8)}` : ''
  }

  const allowEdit =
    viewMode !== "warehouse" &&
    [
      POStatusEnum.MANUFACTURING_IN_PROCESS,
      POStatusEnum.AWAITING_SHIP,
    ].includes(purchaseOrderDetail?.status as any);


  const handleMultiPallet = () => {
    dispatch(
      assignMultiPalletV2({
        po_id: purchaseOrderDetail.id,
        ids: serialSelected,
        item_id: lineItem?.item.id,
        pallet_id: palletObject.pallet_id
      })
    ).then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          onUpdateSuccess();
          notification.success();
          setOpenSelectMultiPallet(false);
        } else {
          notification.error();
        }
      })
  }

  const handleRemovePallet = async (id: number, pallet_id: number) => {
    if (!(await ConfirmUtils.sure(
      "Important Confirm",
      `This action will remove pallet for this serial number. Are you sure you want to perform this action?`
    ))) return;

    dispatch(
      removePalletFromSerial({
        po_id: purchaseOrderDetail.id,
        id,
        item_id: lineItem?.item.id,
        pallet_id
      })
    ).then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          onUpdateSuccess();
          notification.success();
        } else {
          notification.error();
        }
      })
  }

  const handleGenerateSerial = () => {
    if (!openGenerateSerialNumber || !openGenerateSerialNumber?.version_date || !openGenerateSerialNumber?.version_quantity || openGenerateSerialNumber?.version_date === "Invalid Date") return notification.error('Need input data');
    setGernerateVersionLoading(true);
    dispatch(
      generateSerialNumberV2({
        po_id: purchaseOrderDetail.id,
        item_id: lineItem?.item?.id,
        version: openGenerateSerialNumber.version,
        version_quantity: openGenerateSerialNumber.version_quantity,
        version_package: openGenerateSerialNumber.version_package,
        version_date: dayjs(openGenerateSerialNumber.version_date).format(
          "DDMMYY"
        ),
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success();
          setOpenGenerateSerialNumber(undefined);
          onUpdateSuccess();
        } else {
          notification.error();
        }
        setGernerateVersionLoading(false);
      });
  };

  return (
    <Stack spacing={1}>
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid sx={{ flex: 1 }}>
          <Typography level="body-lg" className="lineItem">
            <strong>{lineItem?.item?.code}</strong>
            {ITEM_TYPE[lineItem?.item?.type] && <small className={lineItem?.item?.type}>
              {ITEM_TYPE[lineItem?.item?.type]} {[ITEM_TYPE_VALUE.fset, ITEM_TYPE_VALUE.rset].includes(lineItem?.item?.type) ? <> - {lineItem?.item?.compo} components</> : ''}
            </small>}
          </Typography>
          <Typography level="body-sm">
            Order Quantity: <strong>{lineItem?.quantity} {lineItem?.item?.unit}</strong>
          </Typography>
        </Grid>

        {<Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {allowEdit && alllowSerial > 0 && (
              <Button
                size="sm"
                variant="outlined"
                onClick={() => {
                  setOpenGenerateSerialNumber({
                    version: "",
                    version_date: "",
                    version_package: "",
                    version_quantity: alllowSerial
                  });
                }}
              >
                Generate Serial
              </Button>
            )}

            <IconButton
              color="primary"
              variant="outlined"
              size="sm"
              onClick={() => {
                setShowRealItem(!showRealItem);
              }}
            >
              <Tooltip title="Show/Hide Serial Number">
                {!showRealItem ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
              </Tooltip>
            </IconButton>
          </Box>

        </Grid>}
      </Grid>

      <Divider />

      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        {palletItems.map((pallet: any, ind: any) => (
          <Grid
            key={ind}
            lg={3}
            sm={4}
            md={4}
            sx={{
              background: pallet.pallet.id ? "" : "#ff7171",
              border: (theme) => `1px solid ${theme.palette.divider}`,
              p: 1,
              mb: 1
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography level="body-lg" sx={{ fontWeight: 500 }}>
                  {pallet.pallet.id ? "Pallet No:" + pallet.pallet.pallet_number : "In Progress"}
                  <Tooltip title={pallet.pallet.status ? pallet.pallet.status + (pallet.pallet.re_date ? ' at ' + pallet.pallet.re_date : '') : ''} arrow>
                    <strong style={
                      {
                        borderRadius: "10px",
                        marginLeft: "5px",
                        width: "10px",
                        height: "10px",
                        display: "inline-block",
                        background: pallet.pallet.status === "Received" ? theme.palette.success[500] : (pallet.pallet.status === "Shipped" ? theme.palette.primary[500] : theme.palette.neutral[300])
                      }
                    }></strong>
                  </Tooltip>
                </Typography>

                {!!pallet.serials[0] && <Typography level="body-sm">
                  Component Qty: {pallet.quantity}
                </Typography>}

              </Box>
              {
                [RolesEnum.CEO, RolesEnum.Director,
                RolesEnum.COO, RolesEnum.GeneralManager,
                RolesEnum.Administrator,
                RolesEnum.VietnamPlanning].includes(roleName as RolesEnum) &&
                !pallet.pallet.id &&
                viewMode === "manufacturing" &&
                ItemMenuActions(pallet)}
            </Box>

            {showRealItem && <ListDivider sx={{ mb: 1, mt: 1 }} />}

            {showRealItem && pallet.serials?.map((realItem: any, index: any) => (
              <Box
                style={getItemStyle(
                  realItem,
                  {},
                  colorMap
                )}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center"
                  }}
                >

                  {lineItem?.item.type === ITEM_TYPE_VALUE.n && <Typography sx={{ flex: 1, fontSize: 12 }}>
                    {realItem.serial_number} - {realItem.qty}
                  </Typography>}

                  {lineItem?.item.type === ITEM_TYPE_VALUE.y && <Typography sx={{ flex: 1, fontSize: 12 }}>
                    {realItem.serial_number}
                  </Typography>}

                  {lineItem?.item.type === ITEM_TYPE_VALUE.fset && <Typography sx={{ flex: 1, fontSize: 12 }}>
                    {realItem.serial_number}
                  </Typography>}

                  {lineItem?.item.type === ITEM_TYPE_VALUE.rset && <Typography sx={{ flex: 1, fontSize: 12 }}>
                    {realItem.serial_number}
                  </Typography>}

                  <Typography sx={{ fontSize: 8 }}>
                    <span onClick={(e) => {
                      e.preventDefault();
                      if (pallet.pallet.id && pallet.pallet.status === PalletStatusEnum.ACTIVE) {
                        handleRemovePallet(realItem.id, pallet.pallet.id)
                      }
                    }}
                    >{realItem.status}</span>
                  </Typography>

                </Box>
              </Box>
            ))}
          </Grid>
        ))}


      </Grid>


      {
        openSelectMultiPallet && (
          <BaseModal
            isOpen={openSelectMultiPallet}
            onClose={() => setOpenSelectMultiPallet(false)}
            title="Select Pallet"
            actions={
              <Button
                disabled={
                  !palletObject?.pallet_id ||
                  !lineItem?.item?.id
                }
                onClick={() => {
                  handleMultiPallet()
                }}
              >
                Process
              </Button>
            }
          >
            <Stack>
              <Grid container spacing={2}>
                <Grid sm={12}>
                  <FormControl>
                    <BaseSelect
                      value={palletObject?.pallet_id}
                      label={"Select Pallet"}
                      options={palletActive.map((lot: any) => ({
                        label: lot.pallet_number,
                        value: lot.id,
                      }))}
                      onChange={(e) => {
                        setPalletObject({
                          ...palletObject,
                          pallet_id: Number(e)
                        })
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid sm={12}>
                  <Box sx={{
                    height: 290,
                    overflow: "auto"
                  }}>
                    <Table size="sm">
                      <thead>
                        <tr>
                          <th style={{ width: 40 }}>
                            <Checkbox
                              checked={serialSelected.length === serialListPickup.length && serialSelected.length !== 0}
                              size="sm"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSerialSelected(
                                    serialListPickup.map(l => l.id as number)
                                  );
                                } else {
                                  setSerialSelected([]);
                                }
                              }}
                            />
                          </th>
                          <th>
                            Serial Number
                          </th>

                        </tr>
                      </thead>
                      <tbody>
                        {
                          serialListPickup.map((serial: any) =>
                          (
                            <tr>
                              <td>
                                <Checkbox
                                  size="sm"
                                  checked={serialSelected.includes(serial.id as number)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSerialSelected([...serialSelected, serial.id as number]);
                                    } else {
                                      setSerialSelected(serialSelected.filter((id: number) => id !== serial.id));
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                {serial.serial_number}
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>

                    </Table>
                  </Box>

                </Grid>

              </Grid>
            </Stack>
          </BaseModal>
        )
      }

      {
        !!openGenerateSerialNumber && (
          <BaseModal
            isOpen={true}
            closeOnBackdropClick={false}
            onClose={() => setOpenGenerateSerialNumber(undefined)}
            title="Generate Serial Number"
            width={400}
            actions={
              <Button
                disabled={
                  !openGenerateSerialNumber?.version ||
                  !openGenerateSerialNumber?.version_date ||
                  !openGenerateSerialNumber?.version_quantity
                }
                loading={!!gernerateVersionLoading}
                onClick={() => {
                  handleGenerateSerial();
                }}
              >
                Process
              </Button>
            }
          >
            <Stack spacing={2}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                This action will generate new serial number for item{" "} <b style={{ fontSize: 16 }} >{lineItem.item.code}</b> - {showProductItemName(lineItem.item, false)} of this purchase
                order. Are you sure you want to proceed?
              </Typography>

              <FormControl>
                <BaseSelect
                  label={"Version Number"}
                  value={openGenerateSerialNumber.version}
                  options={SERIAL_VERSIONS}
                  onChange={(e) => {
                    setOpenGenerateSerialNumber({
                      ...openGenerateSerialNumber,
                      version: e,
                    });
                  }}
                />
              </FormControl>

              <FormControl>
                <BaseDatePicker
                  label={"Version Date"}
                  value={openGenerateSerialNumber.version_date}
                  onChange={(e) => {
                    setOpenGenerateSerialNumber({
                      ...openGenerateSerialNumber,
                      version_date: e,
                    });
                  }}
                />
              </FormControl>


              {lineItem && lineItem.item.type === ITEM_TYPE_VALUE.n ? <FormControl>
                <>
                  <BaseInput
                    label={"Package Quantity"}
                    placeholder="10,30,10"
                    value={openGenerateSerialNumber?.version_package}
                    onChange={(e) => {
                      setOpenGenerateSerialNumber({
                        ...openGenerateSerialNumber,
                        version_package: e.target.value,
                      });
                    }}
                  />
                  Total Qty: {openGenerateSerialNumber?.version_quantity}
                </>

              </FormControl>
                :
                <FormControl>
                  <BaseNumberInput
                    max={alllowSerial}
                    label={"Version Quantity"}
                    value={openGenerateSerialNumber?.version_quantity}
                    onChange={(e) => {
                      setOpenGenerateSerialNumber({
                        ...openGenerateSerialNumber,
                        version_quantity: e,
                      });
                    }}
                  />
                </FormControl>
              }


            </Stack>
          </BaseModal>
        )
      }
    </Stack >
  );
}
