import { useParams } from "react-router-dom";

import { Typography } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import PageWrapper from "components/PageWrapper";
import { DetailSkeleton } from "components/SkeletonComponents";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import { getDetailCustomer } from "redux-store/reducers/customer";
import ROUTERS from "routers/constants";
import { ICustomer } from "types/customer";
import AppEvent from "utils/app-events";
import useListenEmitEvent from "utils/common-hooks/useListenEmitEvent";
import AddressesContent from "./components/AddressesContent";
import ContactContent from "./components/ContactContent";
import DetailContent from "./components/DetailContent";
import InvoiceList from "./components/InvoiceList";

export default function CustomerDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const id = useParams()?.id || detailId;
  const customerFromState = useAppSelector((state) =>
    state.customer.list?.find((customer) => customer.id === id)
  );
  const [customerDetail, setCustomerDetail] = useState<ICustomer | undefined>(
    customerFromState
  );

  const dispatch = useAppDispatch();
  const fetchDetail = () => {
    if (!id) return;
    dispatch(
      getDetailCustomer({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        const customer = response.data;

        if (response.data?.id) {
          setCustomerDetail(customer);
        }
      });
  };
  useEffect(() => {
    fetchDetail();
  }, [id]);

  useListenEmitEvent({
    event: AppEvent.types.FULL_SCREEN_MODAL_REFRESH,
    handle: () => {
      fetchDetail();
    },
  });


  return (
    <>
      <PageWrapper
        pageName={"Customer"}
        pageAction={
          <Typography color="success">
            Verified Distributor : <strong>{customerDetail?.cus_number}</strong>
          </Typography>
        }
        breadcrumb={[
          {
            label: "Customer",
            link: ROUTERS.CUSTOMER,
          },
          {
            label: "Detail",
          },
        ]}

        skeletonLoading={!customerDetail ? <DetailSkeleton /> : undefined}
        tabs={[
          {
            label: "Detail",
            content: !!customerDetail && (
              <DetailContent
                onUpdateSuccess={() => {
                  fetchDetail();
                }}
                customerDetailProp={customerDetail}
              />
            ),
          },
          {
            label: "Invoices",
            content: !!customerDetail && (
              <InvoiceList
                customerDetailProp={customerDetail}
              />
            ),
          },
          {
            label: "Addresses",
            content: !!customerDetail && (
              <AddressesContent
                onUpdateSuccess={() => {
                  fetchDetail();
                }}
                customerDetailProp={customerDetail}
              />
            ),
          },
          {
            label: "Contacts",
            content: !!customerDetail && (
              <ContactContent
                onUpdateSuccess={() => {
                  fetchDetail();
                }}
                customerDetailProp={customerDetail}
              />
            ),
          },
        ]}
      ></PageWrapper>
    </>
  );
}
