import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Button, CircularProgress, Table, Typography } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { ITEM_TYPE, ITEM_TYPE_VALUE, SOStatusEnum } from "app-constants";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  getPickingItemsList,
  pickSerialNumber,
} from "redux-store/reducers/sales-order";
import {
  ISalesOrder,
  IShipmenSOPickingItem,
  IShipmentPackage,
} from "types/sales-order";
import { displayAreasLevel, findAreaLevel } from "utils/common";
import notification from "utils/notification";
import { isMobile } from "utils/ui-uitils";
import BaseFullScreenModal from "./BaseFullScreenModal";
import BaseInput from "./BaseInput";

let scanningText = "";
export default function PickingProcessModal({
  salesOrderDetail,
  selectedPackage,
  pickedList,
  onClose,
  onPick,
}: {
  salesOrderDetail: ISalesOrder;
  selectedPackage: IShipmentPackage;
  pickedList: any;
  onClose: () => void;
  onPick: () => void;
}) {
  const [salesOrderItems, setSalesOrderItems] = useState<
    IShipmenSOPickingItem[]
  >([]);
  const warehouseAreas = useAppSelector(
    (state) => state.warehouse.warehouseAreas
  );
  const [inputValue, setInputValue] = useState<string>("");
  const [collapseList, setCollapseList] = useState<any[]>([]);
  const [serialNumbers, setSerialNumbers] = useState<string[]>([]);
  const [triggerReload, setTriggerReload] = useState(0);
  const [scaning, setScaning] = useState(false);
  console.log('788888', pickedList)
  const dispatch = useAppDispatch();
  const callApi = (scanningT: string) => {
    // call api
    setScaning(true);
    return dispatch(
      pickSerialNumber({
        so_id: selectedPackage.so_id,
        package_id: selectedPackage.id,
        serial_number: scanningT,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setScaning(false);
        scanningText = "";
        if (response.apiStatus) {
          setTriggerReload(triggerReload + 1);
          notification.success("Picked success", {
            autoHideDuration: 1000,
          });
          onPick();
        } else {
          notification.error(response.data?.message);
        }
      });
  }

  const handlePickManual = async () => {
    await callApi(inputValue);
    setInputValue("");
  }
  const handleKeyDown = async (e: KeyboardEvent) => {
    // Assuming your scanner adds a special character before/after the scanned code
    // You may need to adjust this based on your scanner's behavior
    if (e.key === "Enter") {
      await callApi(scanningText);
      scanningText = "";
    } else {
      scanningText += e.key.replace(/^Shift/, "");
    }
  };

  useEffect(() => {
    scanningText = "";
  }, []);

  useEffect(() => {
    if (!isMobile()) {
      return;
    }
    document.addEventListener("keypress", handleKeyDown);
    return () => {
      document.removeEventListener("keypress", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (selectedPackage && salesOrderDetail) {
      let tempSerial: string[] = [];
      dispatch(
        getPickingItemsList({
          so_id: selectedPackage.so_id,
          package_id: selectedPackage.id,
        })
      )
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) {
            setSerialNumbers(tempSerial);
            setSalesOrderItems(response.data);
          }
        });
    }
  }, [selectedPackage, triggerReload, salesOrderDetail]);

  const showHideItem = function (salesOrderItem: any) {
    if (collapseList.includes(salesOrderItem.id)) {
      setCollapseList(
        collapseList.filter((item) => item !== salesOrderItem.id)
      );
    } else {
      setCollapseList([...collapseList, salesOrderItem.id]);
    }
  };



  const isReadyToPick = salesOrderDetail.status === SOStatusEnum.READY_TO_PICK;
  const isReadyToPack = salesOrderDetail.status === SOStatusEnum.READY_TO_PACK;

  return (
    <BaseFullScreenModal title={isReadyToPick ? 'Scan to pick item' : `Scan to pack item for package ${selectedPackage.id}`} isOpen={!!selectedPackage} onClose={onClose}>

      <Box sx={{
        display: isMobile() ? "none" : "flex",
        gap: 1,
        py: 1,
      }}>
        <BaseInput
          label="Manual Serial Number"
          value={inputValue}
          onChange={(e) =>
            setInputValue(e.target.value)
          }
        />
        <Button
          size="sm"
          onClick={handlePickManual}
        >
          Pick
        </Button>
      </Box>
      {salesOrderItems.map((salesOrderItem) => {
        return (
          <>
            <Table
              id={salesOrderItem.id}
              borderAxis="bothBetween"
              sx={{
                border: "1px solid var(--TableCell-borderColor)",
                "--TableCell-height": "30px",
                "--TableCell-paddingX": "6px",
                "--TableCell-paddingY": "6px",
                mb: 1,
                fontSize: "14px",
              }}
            >
              <tr
                style={{
                  visibility: "hidden",
                }}
              >
                <th
                  style={{
                    height: 1,
                    padding: 0,
                  }}
                ></th>
                <th
                  style={{
                    width: 90,
                    height: 1,
                    padding: 0,
                  }}
                ></th>
                <th
                  style={{
                    width: 70,
                    height: 1,
                    padding: 0,
                  }}
                ></th>
              </tr>
              <tr>
                <td colSpan={3}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box>
                      <Typography level="body-lg" className="lineItem">
                        <strong>{salesOrderItem.item?.code}</strong>
                        {ITEM_TYPE[salesOrderItem?.item?.type] && <small className={salesOrderItem?.item?.type}>
                          {ITEM_TYPE[salesOrderItem?.item?.type]} {[ITEM_TYPE_VALUE.fset, ITEM_TYPE_VALUE.rset].includes(salesOrderItem?.item?.type) ? <> - {salesOrderItem?.item?.compo} components</> : ''}
                        </small>}
                      </Typography>
                      {salesOrderItem.item.type !== ITEM_TYPE_VALUE.bom && isReadyToPack && <>
                        <Typography
                          sx={{
                            fontSize: 14,
                            display: "inline",
                          }}
                        >
                          In Package: <strong>{salesOrderItem?.picked || 0}</strong>
                        </Typography>
                        <br />
                      </>
                      }
                      {salesOrderItem.item.type !== ITEM_TYPE_VALUE.bom && isReadyToPick && salesOrderItem && <>

                        Picked : {salesOrderItem.item.compo > 1 ? <><b>{pickedList[salesOrderItem.item?.id] || 0}/{(salesOrderItem.quantity || 1) * salesOrderItem.item.compo} </b> parts of <b>{salesOrderItem.quantity}</b></> : <b>{pickedList[salesOrderItem.item?.id] || 0}</b>} ({salesOrderItem.item?.unit})

                        {salesOrderItem.item?.sku && <Typography
                          sx={{
                            fontSize: 14,
                          }}
                        >
                          Location notice: <b>{salesOrderItem.item?.sku}</b>
                        </Typography>}
                      </>}
                    </Box>

                    {salesOrderItem.item.type !== ITEM_TYPE_VALUE.bom && Number(pickedList[salesOrderItem.item?.id] || 0) < ((salesOrderItem.quantity || 0) * salesOrderItem.item.compo) && <Box sx={{ ml: "auto" }}>
                      {!collapseList.includes(salesOrderItem.id) ? (
                        <Box onClick={() => showHideItem(salesOrderItem)} sx={{ display: "flex", alignItems: "center" }}>Hide <KeyboardArrowUp
                        /></Box>
                      ) : (
                        <Box onClick={() => showHideItem(salesOrderItem)} sx={{ display: "flex", alignItems: "center" }}>Show <KeyboardArrowDown
                        /></Box>
                      )}
                    </Box>}
                  </Box>
                </td>
              </tr>
              {salesOrderItem.item.type !== ITEM_TYPE_VALUE.bom && Number(pickedList[salesOrderItem.item?.id] || 0) < ((salesOrderItem.quantity || 0) * salesOrderItem.item.compo) && !collapseList.includes(salesOrderItem.id) && (
                <>
                  <tr style={{ background: "var(--variant-plainActiveBg, var(--joy-palette-neutral-plainActiveBg, var(--joy-palette-neutral-200, #DDE7EE)))" }}>
                    <td>
                      <Typography fontWeight={500} >
                        Location
                      </Typography>
                    </td>
                    <td >
                      <Typography fontWeight={500} >
                        WH Qty
                      </Typography>
                    </td>
                    <td >
                      <Typography fontWeight={500} >
                        Picked
                      </Typography>
                    </td>
                  </tr>
                  {!salesOrderItem.real_item?.length && (
                    <>
                      <tr>
                        <td colSpan={3}>
                          <Typography textAlign={"center"}>
                            No items available for picking
                          </Typography>
                        </td>
                      </tr>
                    </>
                  )}
                  {salesOrderItem.real_item?.map((realItem) => {
                    return (
                      <tr key={realItem.warehouse_area_id}>
                        <td
                          style={{
                            width: 170,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              wordBreak: "break-all",
                              fontWeight: 500,
                            }}
                          >
                            {displayAreasLevel(
                              findAreaLevel(
                                realItem.warehouse_area_id,
                                warehouseAreas
                              )
                            )}
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              wordBreak: "break-all",
                            }}
                          >
                            {Math.floor(realItem.qty / salesOrderItem.item.compo)}
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              wordBreak: "break-all",
                            }}
                          >
                            {Math.floor((salesOrderItem.item_picked && salesOrderItem.item_picked[realItem.warehouse_area_id] ? Number(salesOrderItem.item_picked[realItem.warehouse_area_id]) : 0) / salesOrderItem.item.compo)}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </Table >
          </>
        );
      })}
      {
        scaning && (
          <Box sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(2px)",
          }}>
            <CircularProgress variant="plain" size="lg" value={60} />
          </Box>
        )
      }
    </BaseFullScreenModal >
  );
}
