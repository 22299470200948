import { Typography } from "@mui/joy";
import { InvoiceStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import { ViewDetailLink } from "components/ViewDetailModal";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import { getListInvoiceCustomer } from "redux-store/reducers/invoice";
import ROUTERS from "routers/constants";
import { ICustomer } from "types/customer";
import { IInvoice } from "types/invoice";
import { formatDate, getOverdueStatus, isOverDue, showCurrency } from "utils/common";

export default function InvoiceList({
  customerDetailProp,
}: {
  customerDetailProp: ICustomer;
}) {
  const dispatch = useAppDispatch();
  const [invoiceList, setInvoiceList] = useState<IInvoice[]>([]);
  const fetchList = () => {
    if (!customerDetailProp.id) return;
    dispatch(
      getListInvoiceCustomer({
        customers: [customerDetailProp.id],
      })
    )
      .unwrap()
      .then(({ response }) => {
        if (response.apiStatus) setInvoiceList(response.data);
      });
  };

  useEffect(() => {
    fetchList();
  }, [customerDetailProp]);

  return (
    <>
      <CommonList
        data={invoiceList}
        noLoading
        columns={[
          {
            key: "so_number",
            label: "Sales Order",
            width: 120,
            render: (row) => (
              <ViewDetailLink type="SO" id={row?.id}
                openInNewTab
              >
                {row.so_number}
              </ViewDetailLink>
            ),
          },
          {
            key: "invoice_total",
            label: "Total",
            width: 120,
            rawValue: (row) => showCurrency(row.invoice?.total),
          },
          {
            key: "invoice_status",
            label: "Paid Status",
            width: 90,
            rawValue: (row) => row.invoice?.status,
            render: (row) => (
              <ChipStatus status={row.invoice?.status} size="small" />
            ),
          },
          {
            key: "due_date",
            label: "Due Date",
            width: 120,
            rawValue: (row) => row.invoice?.due_date,
            render: (row) => (
              <Typography>{formatDate(row.invoice?.due_date)}</Typography>
            ),
          },
          {
            key: "due_date_status",
            label: "Status",
            width: 120,
            rawValue: (row) => row.invoice?.status !== InvoiceStatusEnum.PAID ? row.invoice?.due_date : '',
            render: (row) => (
              row.invoice?.status !== InvoiceStatusEnum.PAID ? <Typography
                color={isOverDue(row.invoice?.due_date) ? "danger" : 'neutral'}
              >
                {getOverdueStatus(row.invoice?.due_date)}
              </Typography> : ''
            ),
          },
        ]}
        searchKeys={["so_number"]}
        sortKeys={["due_date", "so_number", "invoice_total", "invoice_status", 'due_date_status']}
        filterStatus={{
          statusKey: "invoice_status",
          data: [
            { label: "Unpaid", value: "Unpaid" },
            { label: "Partially Paid", value: "Partially Paid" },
            { label: "Paid", value: "Paid" },
          ],
        }}
        onRowClick={(row) =>
          window.open(
            `${ROUTERS.INVOICE_DETAIL.replace(":id", row.invoice?.id as any)}`,
            "_blank"
          )
        }
      />
    </>
  );
}
