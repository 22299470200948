import Typography from "@mui/joy/Typography";

import { Box, Button } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "redux-store";
import ROUTERS from "routers/constants";

import { POStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import ConfirmUtils from "components/ConfirmUtils";
import Documents from "components/Documents";
import HistoryTimeline from "components/HistoryTimeline";
import InlineEditModal from "components/InlineEditModal";
import MoreActions from "components/MoreActions";
import PageWrapper from "components/PageWrapper";
import { DetailSkeleton } from "components/SkeletonComponents";
import ApprovalContent from "pages/manufacturing/components/ApprovalContent";
import ItemsManagementV2 from "pages/manufacturing/components/ItemsManagementV2";
import ProductionQueue from "pages/manufacturing/components/ProductionQueueContent";
import { useApproveHook } from "pages/manufacturing/components/useApproveHook";
import { getProductItems } from "redux-store/reducers/product";
import {
  cancelPurchaseOrder,
  getDetailPurchase,
} from "redux-store/reducers/purchase";
import AppEvent from "utils/app-events";
import { exportPOExcel, formatDate } from "utils/common";
import useListenEmitEvent from "utils/common-hooks/useListenEmitEvent";
import { getPermission } from "utils/roles";
import DetailContent from "./components/DetailContent";

export default function PurchaseDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const id = useParams()?.id || detailId;
  const [actionLoading, setActionLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Detail");
  const dispatch = useAppDispatch();
  const [purchaseDetail, setPurchaseDetail] = useState<any>();
  const fetchDetail = () => {
    if (!id) return;
    dispatch(
      getDetailPurchase({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.id) setPurchaseDetail(response.data);
      });
  };
  useEffect(() => {
    fetchDetail();
  }, [id]);

  useEffect(() => {
    dispatch(getProductItems({}));
  }, []);

  const handleCancelOrder = async () => {
    if (!purchaseDetail?.id) return;

    if (!(await ConfirmUtils.sure())) return;

    setActionLoading(true);
    dispatch(
      cancelPurchaseOrder({
        po_id: purchaseDetail?.id as any,
      })
    )
      .then(unwrapResult)
      .then(() => {
        fetchDetail();
        setActionLoading(false);
      })
      .catch(() => {
        setActionLoading(false);
      });
  };

  const handleExportOrder = async () => {
    if (!purchaseDetail?.id) return;
    exportPOExcel(purchaseDetail, !getPermission('NOT_PO_VIEW_PRICE'))
  };

  const {
    activeWorkflow,
    warehouseApprovalRows,
    isActionLoading,
    handleDenyAction,
    activeStatusAfterApprove,
    handleAction,
    fetchApprovalList,
    allowAccess,
  } = useApproveHook({
    manufacturing: purchaseDetail,
    onApproveSuccess: () => {
      fetchDetail();
    },
  });

  useListenEmitEvent({
    event: AppEvent.types.PO_STATUS_CHANGE,
    matchCondition: (payload) =>
      purchaseDetail?.id && payload.po_id == purchaseDetail.id,
    handle: () => {
      fetchDetail();
      fetchApprovalList();
    },
  });

  return (
    <PageWrapper
      onChangeTab={setSelectedTab}
      pageName={
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography>
            PO: #{purchaseDetail?.po_number || ""} <br />
            <Typography sx={{ fontSize: 14, fontWeight: 300 }}>
              Date: {formatDate(purchaseDetail?.created_at, true)}
            </Typography>
          </Typography>
          {!!purchaseDetail && (
            <ChipStatus status={purchaseDetail.status} size="medium" />
          )}
        </Box>
      }
      pageAction={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {allowAccess && selectedTab === "Detail" && !!activeWorkflow && (
            <InlineEditModal
              label="Reason"
              placement="right-end"
              saveText="Save"
              type="textarea"
              onSave={(newData, closeEdit) => {
                handleDenyAction({
                  workflow: activeWorkflow,
                  reason: newData,
                });
                closeEdit();
              }}
            >
              <Button
                variant="plain"
                color="danger"
                loading={isActionLoading === "deny_loading"}
              >
                Deny
              </Button>
            </InlineEditModal>
          )}

          <MoreActions
            loading={actionLoading}
            actions={[
              {
                disabled: ![
                  POStatusEnum.PENDING_APPROVAL,
                  POStatusEnum.PROCESSOR_APPROVED,
                  POStatusEnum.MANUFACTURING_APPROVED,
                  POStatusEnum.MANAGER_APPROVED,
                  POStatusEnum.ACCOUNTING_APPROVED,
                ].includes(purchaseDetail?.status as POStatusEnum),
                label: "Cancel",
                onClick: handleCancelOrder,
              },
              {
                label: "Export Excel",
                onClick: handleExportOrder,
              },
            ]}
          />
        </Box>
      }
      breadcrumb={[
        {
          label: "Purchase Order",
          link: ROUTERS.PURCHASE,
        },
        {
          label: "Detail",
        },
      ]}
      skeletonLoading={!purchaseDetail ? <DetailSkeleton /> : undefined}
      tabs={[
        {
          label: "Detail",
          content: !!purchaseDetail && (
            <DetailContent
              purchaseDetail={purchaseDetail}
              onUpdateSuccess={() => {
                fetchDetail();
              }}
            />
          ),
        },
        {
          label: "Approval",
          content: !!purchaseDetail && (
            <ApprovalContent
              warehouseApprovalRows={warehouseApprovalRows}
              activeWorkflow={activeWorkflow}
              activeStatusAfterApprove={activeStatusAfterApprove}
              isActionLoading={isActionLoading}
              handleAction={handleAction}
              allowAccess={allowAccess}
            />
          ),
        },
        {
          label: "Production Queue",
          content: !!purchaseDetail && (
            <ProductionQueue
              viewMode="warehouse"
              manufacturing={purchaseDetail}
              onApproveSuccess={() => {
                fetchDetail();
              }}
            />
          ),
        },
        {
          label: "Pallet Management",
          content: !!purchaseDetail && (
            <ItemsManagementV2
              manufacturing={purchaseDetail}
              viewMode="warehouse"
              onUpdateSuccess={() => {
                fetchDetail();
              }}
            />
          ),
        },
        {
          label: "Documents",
          content: !!purchaseDetail && (
            <Documents source_id={"po_" + purchaseDetail?.id} />
          ),
        },
        {
          label: "History",
          content: <HistoryTimeline source_id={"po_" + purchaseDetail?.id} />,
        },
      ]}
    ></PageWrapper>
  );
}
