import { unwrapResult } from "@reduxjs/toolkit";
import { POStatusEnum } from "app-constants";
import ConfirmUtils from "components/ConfirmUtils";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  approveManufacturing,
  denyManufacturing,
  getApprovalsManufacturing,
} from "redux-store/reducers/manufacturing";
import { IPurchaseOrder, IPurchaseOrderApproval } from "types/purchase";
import notification from "utils/notification";
import { roleFeatures, RolesEnum } from "utils/roles";

const rawStatuses = [
  {
    workflow: "Processor Approval",
    status_after_approve: "Approved",
    icon: require("assets/images/hiring.png"),
    status: POStatusEnum.PENDING_APPROVAL,
    region: "US",
    roles: roleFeatures.Processor_Approval_Manufacturing,
  },
  {
    workflow: "Manufacturing Approval",
    status_after_approve: "Approved",
    icon: require("assets/images/businessman.png"),
    status: POStatusEnum.PROCESSOR_APPROVED,
    region: "VN",
    roles: roleFeatures.Manufacturing_Approval,
  },
  {
    workflow: "Accounting Approval",
    status_after_approve: "Approved",
    icon: require("assets/images/give-money.png"),
    status: POStatusEnum.MANUFACTURING_APPROVED,
    region: "VN",
    roles: roleFeatures.Accounting_Approval_Manufacturing,
  },
  {
    workflow: "Manager Approval",
    status_after_approve: "Approved",
    icon: require("assets/images/approved.png"),
    status: POStatusEnum.ACCOUNTING_APPROVED,
    region: "US",
    roles: roleFeatures.Manager_Approval_Manufacturing,
  },
];

const cloneObj = (obj: IPurchaseOrderApproval[]) =>
  JSON.parse(JSON.stringify(obj)) as IPurchaseOrderApproval[];
export const useApproveHook = ({
  manufacturing,
  onApproveSuccess,
}: {
  manufacturing?: IPurchaseOrder | null;
  onApproveSuccess: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [isActionLoading, setIsActionLoading] = useState("");
  const [warehouseApprovalRows, setWarehouseApprovalRows] = useState<
    IPurchaseOrderApproval[]
  >(cloneObj(rawStatuses));
  const roleName = useAppSelector((state) => state.auth.user?.roleName);

  const fetchApprovalList = () => {
    if (!manufacturing?.id) return;
    const rawList = cloneObj(rawStatuses);
    dispatch(
      getApprovalsManufacturing({
        po_id: manufacturing.id as any,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setWarehouseApprovalRows([
          ...rawList.map((v) => {
            const item = response.data?.find?.(
              (v2) => v2.workflow === v.workflow
            );
            if (item) {
              return {
                ...v,
                ...item,
              };
            }
            return v;
          }),
        ]);
      });
  };
  useEffect(() => {
    if (manufacturing?.id) {
      fetchApprovalList();
    }
  }, [manufacturing?.id]);

  const handleDenyAction = async ({
    workflow,
    reason,
  }: {
    workflow: string;
    reason: string;
  }) => {
    if (!manufacturing?.id || !reason) return;
    setIsActionLoading("deny_loading");
    dispatch(
      denyManufacturing({
        po_id: manufacturing.id as any,
        workflow,
        deny_reason: reason,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.po_id) {
          notification.success();
          fetchApprovalList();
          onApproveSuccess();
        } else {
          notification.error();
        }
      })
      .finally(() => {
        setIsActionLoading("");
      });
  };

  const handleAction = async ({
    workflow,
    status_after_approve,
  }: IPurchaseOrderApproval) => {
    if (!manufacturing?.id) return;
    if (!(await ConfirmUtils.sure())) return;

    setIsActionLoading("loading");
    dispatch(
      approveManufacturing({
        po_id: manufacturing.id as any,
        workflow,
        status: status_after_approve,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.po_id) {
          notification.success();
          fetchApprovalList();
          onApproveSuccess();
        } else {
          notification.error();
        }
      })
      .finally(() => {
        setIsActionLoading("");
      });
  };

  const {
    workflow: activeWorkflow,
    status_after_approve: activeStatusAfterApprove,
    roles: activeRoles,
  } = [...warehouseApprovalRows].find(
    (v) => (!v.id || !v.approval_status) && v.status === manufacturing?.status
  ) || {};

  const allowAccess = activeRoles?.includes(roleName as RolesEnum);

  return {
    warehouseApprovalRows,
    activeWorkflow,
    activeStatusAfterApprove,
    fetchApprovalList,
    isActionLoading,
    handleAction,
    handleDenyAction,
    allowAccess,
  };
};
