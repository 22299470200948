import { Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Stack
} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { unwrapResult } from "@reduxjs/toolkit";
import { SOStatusEnum } from "app-constants";
import ConfirmUtils from "components/ConfirmUtils";
import { useState } from "react";
import { useAppDispatch } from "redux-store";

import AddressModal from "components/AddressModal";
import _ from "lodash";
import { updateCustomerAddress } from "redux-store/reducers/customer-portal-so";
import {
  approveSOFull,
  splitSOItem,
  updateSOItem
} from "redux-store/reducers/sales-order";
import { ICustomerAddress } from "types/customer-portal-so";
import { ISalesOrder, ISalesOrderItem } from "types/sales-order";
import {
  formatDate,
  showCurrency,
} from "utils/common";
import notification from "utils/notification";
import { getPermission } from "utils/roles";
import EditAddressSection from "./EditAddressSection";
import EditShipSection from "./EditShipSection";
import ModalAddNewSOItem from "./ModalAddNew";
import SOInternalNote from "./SOInternalNote";
import SOMoneyInfo from "./SOMoneyInfo";
import SOTableItems from "./SOTableItems";

export default function DetailContent({
  salesOrderDetail,
  onUpdateSuccess,
}: {
  salesOrderDetail: ISalesOrder;
  onUpdateSuccess: () => void;
}) {
  const dispatch = useAppDispatch();
  const [approveLoading, setApproveLoading] = useState(false);
  const [itemSelected, setItemSelected] = useState<ISalesOrderItem>();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEditShip, setIsOpenEditShip] = useState(false);
  const [isOpenEditAddress, setIsOpenEditAddress] = useState(false);
  const selectedCustomer = salesOrderDetail.customer;
  const [isOpenAddAdress, setIsOpenAddAdress] = useState<ICustomerAddress>();

  const onCloseModal = () => {
    setIsOpen(false);
    setItemSelected(undefined);
  };

  const onCreateItem = (item: ISalesOrderItem) => {
    setIsOpen(false);
    setItemSelected(undefined);
    dispatch(
      updateSOItem({
        so_id: salesOrderDetail.id,
        id: item.id || undefined,
        item_id: item.item?.id || item.item_id,
        quantity: item.quantity,
        cost: item.cost,
        discount: item.discount,
        percent: item.percent,
        note: item.note,
      })
    )
      .then(unwrapResult)
      .then((rs) => {
        if (rs.response.apiStatus) {
          onUpdateSuccess();
          notification.success();
        } else {
          notification.error(rs.response.message);
        }
      });
  };

  const onRemoveItem = (item: ISalesOrderItem) => {
    ConfirmUtils.sure().then((isOk) => {
      if (!isOk) return;

      dispatch(
        updateSOItem({
          so_id: salesOrderDetail.id,
          id: item.id || undefined,
          item_id: item.item?.id || item.item_id,
          quantity: item.quantity,
          cost: item.cost,
          note: item.note,
          is_delete: true,
        })
      )
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) {
            onUpdateSuccess();
            notification.success();
          } else {
            notification.error(response.message);
          }
        });
    });
  };

  const handleSplitOrder = () => {
    const title = "Order Split Confirm"
    const itemsWillSplit = salesOrderDetail?.sales_order_items?.filter(e => e.is_ready).map(f => f.item?.code)
    const dest = <>
      <p>
        This Sales Order is <b>{salesOrderDetail.delivery_type}</b>
      </p>
      Items such as <b>{itemsWillSplit?.join(', ')}</b> will be split into a new sales order marked as "Ready to Pick." They will be removed from the current sales order.
      <p> Please review carefully, as this action <b>cannot</b> be undone.</p>
      <p> Do you confirm the split order action?</p>
    </>
    ConfirmUtils.sure(title, dest).then((isOk) => {
      if (!isOk) return;
      dispatch(
        splitSOItem({
          so_id: salesOrderDetail.id
        })
      )
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) {
            onUpdateSuccess();
            notification.success();
          } else {
            notification.error(response.message);
          }
        });
    });
  };



  const onEditItem = (itemSelected: ISalesOrderItem) => {
    setItemSelected(itemSelected);
    setIsOpen(true);
  };

  const allowUpdateItems =
    getPermission("ALLOW_UPDATE_SO") &&
    [
      SOStatusEnum.IN_REVIEW,
      SOStatusEnum.READY_TO_PICK,
    ].includes(salesOrderDetail.status as any);

  const allowSplit = () => {
    const all = _.every(salesOrderDetail.sales_order_items, (lineItem) => {
      return lineItem.is_ready
    })
    return salesOrderDetail.is_back_order === 'yes' && !all && salesOrderDetail.is_ready && (salesOrderDetail?.sales_order_items?.length || 0) > 1
  }

  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <Card
          color="neutral"
          invertedColors={false}
          orientation="vertical"
          size="sm"
          variant="outlined"
          sx={{
            position: "relative",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid sx={{ flex: 1, mr: 1 }}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Customer
              </Typography>

              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {salesOrderDetail?.customer?.first_name}{" "}
                {salesOrderDetail?.customer?.last_name}

              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                <b>ID:</b> {salesOrderDetail?.customer?.cus_number}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                <b>Level:</b> {salesOrderDetail?.customer?.price_level}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                <b>P</b>: {salesOrderDetail?.customer?.phone}
              </Typography>
              <Typography sx={{ fontSize: 14, mb: 1 }}>
                <b>M</b>: {salesOrderDetail?.customer?.email}
              </Typography>

              {salesOrderDetail?.customer?.ups_acc && <Typography sx={{ fontSize: 14, mb: 1 }}>
                <b>UPS ACC</b>: {salesOrderDetail?.customer?.ups_acc}
              </Typography>}
            </Grid>

            {!!salesOrderDetail?.shipping_address && <Grid sx={{ flex: 1, mr: 1, position: "relative" }}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Ship To{" "}
                {getPermission("ALLOW_UPDATE_SO") && ![SOStatusEnum.SHIPPED, SOStatusEnum.COMPLETED].includes(salesOrderDetail.status as SOStatusEnum) && (
                  <Edit
                    onClick={() => {
                      setIsOpenAddAdress(salesOrderDetail?.shipping_address);
                    }}
                    fontSize="small"
                    style={{
                      position: "absolute",
                      left: 55,
                      top: -2,
                      cursor: "pointer",
                    }}
                  />
                )}
              </Typography>

              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {salesOrderDetail?.shipping_address?.name}
              </Typography>

              <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                {salesOrderDetail?.shipping_address?.company}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {salesOrderDetail?.shipping_address?.address}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {salesOrderDetail?.shipping_address?.city},{" "}
                {salesOrderDetail?.shipping_address?.state}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {salesOrderDetail?.shipping_address?.zip_code}{" "}
                {salesOrderDetail?.shipping_address?.country}
              </Typography>
              {salesOrderDetail?.shipping_address.phone && (
                <Typography sx={{ fontSize: 14 }}>
                  Phone: {salesOrderDetail?.shipping_address.phone}
                </Typography>
              )}
            </Grid>}

            {!!salesOrderDetail?.billing_address && <Grid sx={{ flex: 1, ml: 1, position: "relative" }}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Bill To{" "}
                {getPermission("ALLOW_UPDATE_SO") && (
                  <Edit
                    onClick={() => {
                      setIsOpenAddAdress(salesOrderDetail?.billing_address);
                    }}
                    fontSize="small"
                    style={{
                      position: "absolute",
                      left: 46,
                      top: -2,
                      cursor: "pointer",
                    }}
                  />
                )}
              </Typography>

              <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                {salesOrderDetail?.customer?.first_name}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {salesOrderDetail?.billing_address.address}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {salesOrderDetail?.billing_address.city},{" "}
                {salesOrderDetail?.billing_address.state}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {salesOrderDetail?.billing_address.zip_code}{" "}
                {salesOrderDetail?.billing_address.country}
              </Typography>

              {salesOrderDetail?.billing_address.phone && (
                <Typography sx={{ fontSize: 14 }}>
                  Phone: {salesOrderDetail?.billing_address.phone}
                </Typography>
              )}
            </Grid>}

            <Grid sx={{ flex: 1, ml: 1 }}>
              <Typography fontSize="sm">Sales Rep</Typography>
              {salesOrderDetail && (
                <Typography fontSize="sm" fontWeight={500}>
                  {salesOrderDetail?.user?.name}
                </Typography>
              )}
              <Typography fontSize="sm" sx={{ mt: 1 }}>
                Customer PO
                {getPermission("ALLOW_UPDATE_SO") && ![SOStatusEnum.SHIPPED, SOStatusEnum.COMPLETED].includes(salesOrderDetail.status as SOStatusEnum) && (
                  <Edit
                    onClick={() => {
                      setIsOpenEditAddress(true);
                    }}
                    fontSize="small"
                    style={{ cursor: "pointer", marginLeft: 8 }}
                  ></Edit>
                )}
              </Typography>

              <Typography fontSize="sm" fontWeight={500}>
                {salesOrderDetail?.name || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Card>

        <Card
          color="neutral"
          orientation="vertical"
          size="sm"
          variant="outlined"
          sx={{
            position: "relative",
          }}
        >
          {getPermission("ALLOW_UPDATE_SO") && ![SOStatusEnum.SHIPPED, SOStatusEnum.COMPLETED].includes(salesOrderDetail.status as SOStatusEnum) && (
            <Edit
              onClick={() => {
                setIsOpenEditShip(true);
              }}
              fontSize="small"
              style={{
                position: "absolute",
                right: 10,
                top: 10,
                cursor: "pointer",
              }}
            ></Edit>
          )}

          <Grid
            container
            sx={{
              display: "flex",
            }}
          >
            <Grid sx={{ flex: 1 }}>
              <Grid container>
                <Grid sx={{ flex: 1, ml: 1, mr: 1 }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    Ship Method
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    {salesOrderDetail?.delivery_method}
                  </Typography>
                </Grid>

                {["UPS", "LTL"].includes(salesOrderDetail?.delivery_method) && (
                  <>
                    <Grid sx={{ flex: 1, mr: 1 }}>
                      {["LTL"].includes(salesOrderDetail?.delivery_method) ? (
                        <>
                          <Typography
                            sx={{
                              fontSize: 14,
                            }}
                          >
                            Logistics Company
                          </Typography>
                          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                            {salesOrderDetail?.delivery_company || "-"}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            sx={{
                              fontSize: 14,
                            }}
                          >
                            Ship Service
                          </Typography>
                          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                            {salesOrderDetail?.shipping_service || "-"}
                          </Typography>
                        </>
                      )}
                    </Grid>

                    <Grid sx={{ flex: 1, ml: 1 }}>
                      <Typography
                        sx={{
                          fontSize: 14,
                        }}
                      >
                        Freight Cost
                      </Typography>
                      <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                        {showCurrency(salesOrderDetail?.shipping_cost)}
                      </Typography>
                    </Grid>

                    <Grid sx={{ flex: 1, ml: 1 }}>
                      <Typography
                        sx={{
                          fontSize: 14,
                        }}
                      >
                        Tracking #
                      </Typography>
                      <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                        {(salesOrderDetail?.packages || []).map(e => e.tracking_number).join(',') || "-"}
                      </Typography>
                    </Grid>


                  </>
                )}

                {["LTL", "Will Call"].includes(salesOrderDetail?.delivery_method) && (
                  <Grid sx={{ flex: 1, ml: 1 }}>
                    <Typography
                      sx={{
                        fontSize: 14,
                      }}
                    >
                      Pallet Charge
                    </Typography>
                    <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                      {salesOrderDetail?.is_pallet ? salesOrderDetail?.pallet_num + ' pallet(s)' : "No"}
                    </Typography>
                  </Grid>
                )}

                <Grid sx={{ flex: 1, ml: 1 }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    Ship Date
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    {salesOrderDetail?.ship_date
                      ? formatDate(salesOrderDetail?.ship_date)
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Stack>

      <Stack spacing={2}>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 1,
          }}
        >

          {allowUpdateItems && (
            <IconButton
              color="primary"
              size="sm"
              sx={{
                mr: 1
              }}
              onClick={() => {
                setIsOpen(true);
              }}
              variant="outlined"
            >
              <AddIcon />
            </IconButton>
          )}

          {allowSplit() &&

            <>
              <Button
                color="primary"
                size="sm"
                sx={{ mr: 1 }}
                onClick={() => {
                  handleSplitOrder()
                }}
                variant="outlined"
              >
                Split Order
              </Button>

            </>
          }
        </Box>

        <SOTableItems
          listItems={salesOrderDetail?.sales_order_items || []}
          onEditItem={onEditItem}
          onRemoveItem={onRemoveItem}
          allowUpdateItems={allowUpdateItems}
          salesOrderDetail={salesOrderDetail}
        />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flex: 1 }}>
            {salesOrderDetail ? (
              <>
                <SOInternalNote salesOrderDetail={salesOrderDetail} onUpdateSuccess={onUpdateSuccess}
                  noteLabel="Note or Special Instructions"
                  noteKey="note"
                />
                <SOInternalNote salesOrderDetail={salesOrderDetail} onUpdateSuccess={onUpdateSuccess}
                  noteLabel="Internal Note"
                  noteKey="internal_note"
                />
              </>
            ) : (
              <></>
            )}
          </Box>
          <Box>
            <SOMoneyInfo salesOrderDetail={salesOrderDetail} />
          </Box>
        </Box>

        {getPermission("ALLOW_UPDATE_SO") &&
          (
            (
              [SOStatusEnum.IN_REVIEW].includes(salesOrderDetail?.status as SOStatusEnum) && salesOrderDetail?.is_back_order !== 'yes'
            ) ||
            (
              [SOStatusEnum.BACKORDER_APPROVED].includes(salesOrderDetail?.status as SOStatusEnum) && salesOrderDetail?.is_back_order === 'yes'
            )
          ) && <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              loading={approveLoading}
              onClick={async () => {
                if (!await ConfirmUtils.sure()) return;
                setApproveLoading(true);
                dispatch(approveSOFull({
                  so_id: salesOrderDetail.id,
                })).unwrap().then((rs) => {
                  if (rs.response.apiStatus) {
                    notification.success();
                    onUpdateSuccess();
                  } else {
                    notification.error(rs.response?.message);
                  }
                  setApproveLoading(false);
                });
              }}
            >
              Approve
            </Button>
          </Box>}
      </Stack>

      <Divider />

      <ModalAddNewSOItem
        isOpen={isOpen}
        itemSelected={itemSelected}
        onCloseModal={onCloseModal}
        onCreateItem={onCreateItem}
        customer={selectedCustomer}
        items={salesOrderDetail?.sales_order_items}
        salesOrderDetail={salesOrderDetail}
      />

      {
        isOpenEditShip && (
          <EditShipSection
            isOpen={isOpenEditShip}
            so={salesOrderDetail}
            onClose={() => setIsOpenEditShip(false)}
            onUpdateSuccess={onUpdateSuccess}
          />
        )
      }

      {
        isOpenEditAddress && (
          <EditAddressSection
            isOpen={isOpenEditAddress}
            so={salesOrderDetail}
            onClose={() => setIsOpenEditAddress(false)}
            onUpdateSuccess={onUpdateSuccess}
          />
        )
      }

      {
        !!isOpenAddAdress && (
          <AddressModal
            isOpen={!!isOpenAddAdress}
            onClose={() => setIsOpenAddAdress(undefined)}
            onSave={(data) => {
              if (!salesOrderDetail?.customer_id) return;

              const addJob = {
                ...data,
                customer_id: salesOrderDetail?.customer_id,
                fromAdmin: true,
              };
              dispatch(updateCustomerAddress(addJob))
                .then(unwrapResult)
                .then(({ response }) => {
                  if (response.apiStatus) {
                    onUpdateSuccess();
                    notification.success();
                    setIsOpenAddAdress(undefined);
                  } else {
                    notification.error();
                  }
                });
            }}
            selectedAddress={isOpenAddAdress}
          />
        )
      }
    </Stack >
  );
}
