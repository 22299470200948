import { Box, Card, Divider, Grid, Stack, Table, Typography } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { ChipStatus } from "components/ChipStatus";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import { getCustomerPOPackages } from "redux-store/reducers/customer-portal-so";
import { ISalesOrder, IShipmentPackage } from "types/sales-order";
import { formatDate, showProductItemName } from "utils/common";
import notification from "utils/notification";

const ShipmentItem = ({
  shipmentItem,
  salesOrderDetail,
}: {
  shipmentItem: IShipmentPackage;
  salesOrderDetail: ISalesOrder;
}) => {
  const listSOItems = salesOrderDetail.sales_order_items;

  const pickedNumberGroup = _.groupBy(shipmentItem?.real_item, "item_id");

  return (
    <Card
      sx={{
        p: 1,
      }}
    >
      <Grid container sx={{ display: "flex" }} spacing={1}>
        <Grid sx={{ flex: 1 }}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{ flex: 1, display: "flex", alignItems: "center", gap: 2 }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <Box>
                    <Typography level="body-md">
                      Package ID #{shipmentItem.id}{" "}
                      <ChipStatus status={shipmentItem.status} size="small" />
                    </Typography>
                    <Typography level="body-xs" color="neutral">
                      {formatDate(shipmentItem.created_at, true)}
                    </Typography>
                  </Box>
                </Box>

                <Table
                  borderAxis="x"
                  sx={{
                    "--TableCell-height": "30px",
                    "--TableCell-paddingX": "6px",
                    "--TableCell-paddingY": "6px",
                    fontSize: "14px",
                    display: {
                      xs: "none",
                      md: "table",
                    },
                  }}
                >
                  <tr
                    style={{
                      visibility: "hidden",
                    }}
                  >
                    <th
                      style={{
                        height: 1,
                        padding: 0,
                      }}
                    ></th>
                    <th
                      style={{
                        height: 1,
                        padding: 0,
                      }}
                    ></th>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>{shipmentItem.type}</td>
                  </tr>
                  <tr>
                    <td>Weight</td>
                    <td>{shipmentItem.weight}</td>
                  </tr>
                  <tr>
                    <td>Dimensions</td>
                    <td>
                      {shipmentItem.length}x{shipmentItem.width}x
                      {shipmentItem.height}
                    </td>
                  </tr>
                  <tr>
                    <td>CBM</td>
                    <td>{shipmentItem.cbm}</td>
                  </tr>
                  <tr>
                    <td>Carrier</td>
                    <td>{shipmentItem.carrier}</td>
                  </tr>
                  <tr>
                    <td>Tracking</td>
                    <td>{shipmentItem.tracking_number}</td>
                  </tr>

                  {!!shipmentItem.note && (
                    <tr>
                      <td>Note</td>
                      <td>{shipmentItem.note}</td>
                    </tr>
                  )}
                </Table>

                <Divider sx={{ my: 1 }} />

                <Table
                  borderAxis="x"
                  sx={{
                    "--TableCell-height": "30px",
                    "--TableCell-paddingX": "6px",
                    "--TableCell-paddingY": "6px",
                    fontSize: "14px",
                  }}
                >
                  <tr
                    style={{
                      visibility: "hidden",
                    }}
                  >
                    <th
                      style={{
                        height: 1,
                        padding: 0,
                      }}
                    ></th>
                    <th
                      style={{
                        height: 1,
                        padding: 0,
                        width: 80,
                      }}
                    ></th>
                  </tr>
                  {_.map(listSOItems, (val, index) => {
                    const hasPicked =
                      pickedNumberGroup[(val.item_id || "") as string]
                        ?.length || 0;

                    if (!hasPicked) return null;
                    return (
                      <tr
                        key={index}
                        style={{
                          color: !hasPicked
                            ? "var(--joy-palette-neutral-500)"
                            : "var(--joy-palette-text-primary)",
                        }}
                      >
                        <td>{showProductItemName(val.item, true)}</td>
                        <td>{hasPicked} qty</td>
                      </tr>
                    );
                  })}
                </Table>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default function ShipmentContent({
  salesOrderDetail,
  onUpdateSuccess,
}: {
  salesOrderDetail: ISalesOrder;
  onUpdateSuccess: () => void;
}) {
  const dispatch = useAppDispatch();
  const [shipmentList, setShipmentList] = useState<IShipmentPackage[]>([]);

  const fetchList = () => {
    if (!salesOrderDetail.id) return;
    dispatch(
      getCustomerPOPackages({
        so_id: salesOrderDetail.id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          setShipmentList(
            _.orderBy(response.data, ["created_at"], ["asc"]) || []
          );
        } else {
          notification.error();
        }
      });
  };

  useEffect(() => {
    fetchList();
  }, [salesOrderDetail]);

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
          }}
        >
          Packages
        </Typography>
      </Box>
      {!shipmentList.length && (
        <Typography level="body-sm" textAlign={"center"}>
          No shipped items at the moment. Please check back later
        </Typography>
      )}
      {shipmentList.map((shipmentItem, index) => {
        return (
          <ShipmentItem
            shipmentItem={shipmentItem}
            salesOrderDetail={salesOrderDetail}
          />
        );
      })}
    </Stack>
  );
}
