import { Add, ClearOutlined, ModeEdit } from "@mui/icons-material";
import { Box, FormLabel, Grid, IconButton, Tooltip } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import AddressModal from "components/AddressModal";
import CommonList from "components/CommonList";
import ConfirmUtils from "components/ConfirmUtils";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import { getCustomerAddressList } from "redux-store/reducers/customer";
import {
  createCustomerAddress,
  deleteCustomerAddress,
  updateCustomerAddress,
} from "redux-store/reducers/customer-portal-so";
import { ICustomer } from "types/customer";
import { ICustomerAddress } from "types/customer-portal-so";
import { parseAddress } from "utils/common";
import notification from "utils/notification";
import { getPermission } from "utils/roles";

export default function AddressesContent({
  customerDetailProp,
  onUpdateSuccess,
}: {
  customerDetailProp: ICustomer;
  onUpdateSuccess: () => void;
}) {
  const dispatch = useAppDispatch();
  const [addressList, setAddressList] = useState<ICustomerAddress[]>([]);

  const shippingAddress = addressList.filter((item) => item.type === "ship");
  const billingAddress = addressList.filter((item) => item.type === "bill");
  const [newAddress, setNewAddress] = useState<ICustomerAddress | null>(null);
  const openCreateAddressModal = (type: string) => {
    if (!getPermission('ALLOW_UPDATE_CUSTOMER')) {
      notification.error("You don't have permission to perform this action");
      return;
    }
    setNewAddress({
      phone: "",
      type,
    });
  };

  const fetchList = () => {
    if (!customerDetailProp.id) return;
    dispatch(
      getCustomerAddressList({
        customer_id: customerDetailProp.id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) setAddressList(response.data);
      });
  };

  const handleSaveAddress = (newAddress: ICustomerAddress) => {
    if (!getPermission('ALLOW_UPDATE_CUSTOMER')) {
      notification.error("You don't have permission to perform this action");
      return;
    }
    const addJob = {
      ...newAddress,
      customer_id: customerDetailProp.id,
      fromAdmin: true,
    };

    delete addJob.QBId

    dispatch(
      newAddress?.id
        ? updateCustomerAddress(addJob)
        : createCustomerAddress(addJob)
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success();
          setNewAddress(null);
          fetchList();
        } else {
          notification.error();
        }
      });
  };

  useEffect(() => {
    fetchList();
  }, [customerDetailProp]);

  const onRemoveItem = async (item: ICustomerAddress) => {
    if (!getPermission('ALLOW_UPDATE_CUSTOMER')) {
      notification.error("You don't have permission to perform this action");
      return;
    }

    if (!(await ConfirmUtils.sure())) {
      return;
    }

    dispatch(
      deleteCustomerAddress({
        id: item.id,
        customer_id: customerDetailProp.id,
        fromAdmin: true,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success();
          fetchList();
        } else {
          const err: any = { ...response.data }
          notification.error(err.message);
        }
      });
  };

  return (
    <>
      <Grid container spacing={2}>

        {[
          {
            type: "ship",
            list: shippingAddress,
          },
          {
            type: "bill",
            list: billingAddress,
          },
        ].map(({ type, list }, index) => (
          <Grid xs={12} sm={12} key={type}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 1,
                alignItems: "center"
              }}
            >
              <FormLabel sx={{ mb: 1 }}>
                {index === 0 ? "Shipping" : "Billing"}
              </FormLabel>
              <IconButton
                size="sm"
                onClick={() => openCreateAddressModal(type)}
                variant="outlined"
                color="primary"
              >
                <Add />
              </IconButton>
            </Box>

            <CommonList
              pageSize={100}
              noLoading={true}
              data={list}
              size="sm"
              columns={[
                {
                  key: "full_address",
                  label: "Address",
                  width: 320,
                  rawValue: (item) => {
                    return <> <b>{parseAddress(item)}</b> <br /> {item.is_default && " (Default)"}</>;
                  },
                },
                {
                  key: "name",
                  label: "Contact Name",
                },
                {
                  key: "company",
                  label: "Company",
                },
                {
                  key: "phone",
                  label: "Phone",
                },
                {
                  key: "email",
                  label: "Email",
                },
                {
                  key: "actions",
                  label: "",
                  width: 70,
                  render: (item) => (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                      }}
                    >
                      <Tooltip title="Edit" arrow>
                        <ModeEdit
                          fontSize="small"
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (!getPermission('ALLOW_UPDATE_CUSTOMER')) {
                              notification.error("You don't have permission to perform this action");
                              return;
                            }
                            setNewAddress(item);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Remove" arrow>
                        <ClearOutlined
                          sx={{ cursor: "pointer" }}
                          fontSize="small"
                          onClick={(e) => {
                            onRemoveItem(item);
                          }}
                        />
                      </Tooltip>
                    </Box>
                  ),
                },
              ]}
              searchKeys={[]}
              filterStatus={{
                statusKey: "",
                data: [],
              }}
            />
          </Grid>
        ))}
      </Grid>

      <AddressModal
        isOpen={!!newAddress}
        onClose={() => setNewAddress(null)}
        onSave={(data) => handleSaveAddress(data)}
        selectedAddress={newAddress}
      />
    </>
  );
}
