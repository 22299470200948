
import { Box, Typography } from "@mui/joy";
import { InvoiceStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import CustomerView from "components/CustomerView";
import FilterDrawer, { IFilterItem } from "components/FilterDrawer";
import PageWrapper from "components/PageWrapper";
import {
  ViewDetailModalWrapper
} from "components/ViewDetailModal";
import _ from "lodash";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import { savePageFilter } from "redux-store/reducers/common";
import { getListCustomer } from "redux-store/reducers/customer";
import { getListInvoice } from "redux-store/reducers/invoice";
import { formatDate, getOverdueStatus, isOverDue, showCurrency } from "utils/common";
import useOpenDetailPopup from "utils/common-hooks/useOpenDetailPopup";

export default function InvoiceListPage() {
  const filterValue = useAppSelector((state) => state.common.searchFilter.INVOICE_LIST)
  const productItems =
    useAppSelector((state) => state.product.productItems) || [];
  const customerList = useAppSelector((state) => state.customer.list);
  const invoiceList = useAppSelector((state) => state.invoice.list);

  const {
    openDetailPopup,
  } = useOpenDetailPopup({
    type: 'INVOICE'
  })

  const {
    openDetailPopup: openDetailSOPopup,
  } = useOpenDetailPopup({
    type: 'SO'
  })

  const dispatch = useAppDispatch();
  const handleGetListInvoice = () => {
    dispatch(getListInvoice({
      ...filterValue
    }));
  };

  useEffect(() => {
    handleGetListInvoice();
  }, [filterValue])

  useEffect(() => {
    handleGetListInvoice();
    dispatch(getListCustomer({}));
  }, []);


  const filterFields: IFilterItem[] = [
    {
      label: "Sales Order / Customer PO",
      type: "texts",
      key: "textSearch",
      placeholder: "Type Sales Order, Customer PO",
    },
    {
      label: "Customers",
      placeholder: "Select customer",
      type: "multiple-select",
      key: "customers",
      options: _.map(customerList, (cus) => ({ label: cus.first_name, value: cus.id })),
    },
    {
      label: "Items",
      placeholder: "Select item",
      type: "multiple-select",
      key: "items",
      options: _.map(productItems, (cus) => ({ label: cus.code, value: cus.code })),
    },
    {
      label: "Status",
      placeholder: "Select status",
      type: "multiple-select",
      key: "statuses",
      options: [
        InvoiceStatusEnum.UNPAID,
        InvoiceStatusEnum.PARTIALLY_PAID,
        InvoiceStatusEnum.PAID,
      ].map(e => ({
        label: e,
        value: e
      }))
    },
    {
      label: "Due Date",
      placeholder: "Due Date",
      type: "date",
      key: "due_date",
    },
  ];

  return (
    <>
      <PageWrapper
        pageName="Invoices"
        breadcrumb={[
          {
            label: "Invoices",
          },
        ]}
        pageAction={
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}>
            <FilterDrawer
              filters={filterFields}
              value={filterValue}
              onChange={(e) => {
                dispatch(savePageFilter({
                  name: 'INVOICE_LIST',
                  data: e,
                }))
              }}
            />
          </Box>

        }
      >
        <CommonList
          data={invoiceList}
          columns={[
            {
              key: "so_number",
              label: "Sales Order",
              width: 120,
              render: (row) => <Typography
                color='primary'
                onClick={(e: any) => {
                  e.stopPropagation()
                  e.preventDefault()
                  openDetailSOPopup(row.id)
                }}
              >{row.so_number}</Typography>
            },
            {
              key: "customerName",
              label: "Customer",
              render: (row) => <CustomerView customer={row.customer as any} />,
              rawValue: (row) =>
                `${row.customer?.first_name} ${row.customer?.last_name} ${row.customer?.email} ${row.customer?.cus_number}`,
            },

            {
              key: "name",
              label: "Customer PO",
              render: (row) => row.name,
            },
            {
              key: "invoice_total",
              label: "Total",
              width: 120,
              rawValue: (row) => showCurrency(row.invoice?.total),
            },
            {
              key: "invoice_status",
              label: "Paid Status",
              width: 90,
              rawValue: (row) => row.invoice?.status,
              render: (row) => (
                <ChipStatus status={row.invoice?.status} size="small" />
              ),
            },
            {
              key: "due_date",
              label: "Due Date",
              width: 120,
              rawValue: (row) => row.invoice?.due_date,
              render: (row) => (
                <Typography>{formatDate(row.invoice?.due_date)}</Typography>
              ),
            },
            {
              key: "sent_date",
              label: "Sent Date",
              width: 120,
              rawValue: (row) => row.invoice?.sent_date,
              render: (row) => (
                <Typography>{formatDate(row.invoice?.sent_date)}</Typography>
              ),
            },

            {
              key: "due_date_status",
              label: "Status",
              width: 120,
              rawValue: (row) => row.invoice?.status !== InvoiceStatusEnum.PAID ? row.invoice?.due_date : '',
              render: (row) => (
                row.invoice?.status !== InvoiceStatusEnum.PAID ? <Typography
                  color={isOverDue(row.invoice?.due_date) ? "danger" : 'neutral'}
                >
                  {getOverdueStatus(row.invoice?.due_date)}
                </Typography> : ''
              ),
            },

          ]}
          sortKeys={["due_date", "so_number", "name", "customerName", "invoice_total", "invoice_status", 'due_date_status', 'sent_date']}
          searchKeys={[
            'so_number',
            'customerName',
            'name'
          ]}
          onRowClick={(row) =>
            openDetailPopup(row.invoice?.id)
          }
        />
      </PageWrapper>
      <ViewDetailModalWrapper />
    </>
  );
}
