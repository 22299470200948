import {
  Clear,
  DeleteOutline,
  DownloadOutlined,
  FileUploadOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAppDispatch } from "redux-store";
import {
  deleteDocument,
  downloadDocument,
  getDocumentFromSourceId,
} from "redux-store/reducers/common";
import { createDocumentFromSourceIdApi } from "services/common.service";
import { IDocument, IProgressData } from "types/common";
import { formatDate, showFileSize } from "utils/common";
import notification from "utils/notification";
import CommonList from "./CommonList";
import ConfirmUtils from "./ConfirmUtils";

const dropzoneStyle: React.CSSProperties = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  textAlign: "center",
  cursor: "pointer",
  padding: "20px",
  marginBottom: "20px",
};

const ProgressItem = ({
  data,
  onRemove,
}: {
  data: IProgressData;
  onRemove?: (data: IProgressData) => void;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "&:not(:last-child)": {
          marginBottom: 1,
        },
        gap: 1,
      }}
    >
      <LinearProgress
        determinate
        variant="outlined"
        size="sm"
        thickness={12}
        value={data.progress}
        sx={{
          "--LinearProgress-progressThickness": "20px",
        }}
      >
        <Typography
          level="body-xs"
          textColor="text.primary"
          sx={{
            zIndex: 1,
          }}
        >
          {data.fileName} - {showFileSize(data.fileSize)}
        </Typography>
      </LinearProgress>

      <IconButton
        size="sm"
        disabled={!onRemove}
        onClick={(e) => {
          onRemove?.(data);
          e.stopPropagation();
        }}
      >
        <Clear />
      </IconButton>
    </Box>
  );
};

export default function Documents({ source_id }: { source_id?: string }) {
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [deleteLoadingIds, setDeleteLoadingIds] = useState<any[]>([]);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [documentList, setDocumentList] = useState<IDocument[]>([]);
  const dispatch = useAppDispatch();
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [progressData, setProgressData] = useState<IProgressData[]>([]);
  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    if (!source_id) return;
    dispatch(
      getDocumentFromSourceId({
        source_id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        const newList: any = response.data || [];
        (newList as any).finishLoading = true;
        setDocumentList(newList);
      });
  };

  useEffect(() => {
    setProgressData(
      newFiles.map((file, idx) => ({
        fileIdx: idx,
        fileName: file.name,
        progress: 0,
        url: "",
        isLoading: false,
        isError: false,
        fileSize: file.size,
      }))
    );
  }, [newFiles]);

  const onDeleteFile = (data: IProgressData) => {
    const newFileUpdate = newFiles.filter(
      (item) => item.name !== data.fileName
    );
    setNewFiles(newFileUpdate);
  };
  const onDrop = (acceptedFiles: File[]) => {
    const allFiles = _.uniqBy([...newFiles, ...acceptedFiles], "name");
    // uniq by name

    setNewFiles(allFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const handleUploadFile = (e: any) => {
    e.stopPropagation();
    if (!newFiles?.length) return;
    setUploadLoading(true);
    setProgressData([]);
    createDocumentFromSourceIdApi({
      source_id,
      files: newFiles,
      progressCallback(data, finish) {
        setProgressData([...data]);
        if (finish) {
          notification.success();
          setUploadLoading(false);
          setNewFiles([]);
          fetchList();
        }
      },
    });
  };
  const handleDownloadFile = (item: IDocument) => {
    dispatch(downloadDocument({ url: item.url }))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          const url = response.data;
          window.open(url, "_blank");
        }
      });
  };

  const handleDeleteFile = async (item: IDocument) => {

    if (!(await ConfirmUtils.sure())) return;

    setDeleteLoadingIds([...deleteLoadingIds, item.id]);
    dispatch(
      deleteDocument({
        id: item.id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setDeleteLoadingIds(deleteLoadingIds.filter((id) => id !== item.id));
        if (response.apiStatus) {
          notification.success();
          fetchList();
        } else {
          notification.error();
        }
      });
  };

  return (
    <Box>
      {!!showUpload && (
        <div {...getRootProps()}>
          <Box
            sx={{
              ...dropzoneStyle,
            }}
          >
            {!uploadLoading && <input {...getInputProps()} />}

            {!!progressData?.length && (
              <Box>
                {progressData?.map((item, index) => (
                  <ProgressItem
                    key={index}
                    data={item}
                    onRemove={uploadLoading ? undefined : onDeleteFile}
                  />
                ))}
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
                padding: "40px 0",
              }}
            >
              <FileUploadOutlined fontSize="large" />
              <p>Drag and drop an file here, or click to select one</p>
              <Box>
                <Button
                  variant="plain"
                  color="primary"
                  onClick={(e) => {
                    setShowUpload(false);
                    e.stopPropagation();
                  }}
                  disabled={uploadLoading}
                  sx={{
                    mr: 1,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  loading={uploadLoading}
                  onClick={handleUploadFile}
                  disabled={!newFiles?.length}
                >
                  Start Upload
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
      )}

      <Stack>
        <CommonList
          tableActions={
            !showUpload && (
              <Button
                onClick={() => setShowUpload(true)}
                disabled={uploadLoading}
                color="primary"
                size="sm"
              >
                Upload Files
              </Button>
            )
          }
          data={documentList}
          columns={[
            {
              label: "Name",
              key: "file_name",
              render: (item) => (
                <Box>
                  <Typography>{item.file_name}</Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 300 }}>
                    {showFileSize(item.size)}
                  </Typography>
                </Box>
              ),
            },
            {
              label: "Created By",
              key: "user",
              width: 200,
              render: (item) => item.user?.name,
            },
            {
              label: "Created At",
              key: "created_at",
              width: 150,
              render: (item) => formatDate(item.created_at, true),
            },
            {
              label: "Action",
              key: "action",
              width: 100,
              render: (item) => (
                <Box>
                  <IconButton
                    color="success"
                    size="sm"
                    onClick={() => handleDownloadFile(item)}
                  >
                    <DownloadOutlined />
                  </IconButton>
                  <IconButton
                    color="danger"
                    size="sm"
                    sx={{ ml: 1 }}
                    onClick={() => handleDeleteFile(item)}
                    disabled={deleteLoadingIds.includes(item.id)}
                  >
                    <DeleteOutline />
                  </IconButton>
                </Box>
              ),
            },
          ]}
          searchKeys={["file_name"]}
          sortKeys={["created_at", "file_name", "size"]}
          size="sm"
          filterStatus={{
            statusKey: "",
            data: [],
          }}
        />
      </Stack>
    </Box>
  );
}
