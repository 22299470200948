import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridColDef, GridColumnVisibilityModel,
  GridEventListener,
  GridRenderCellParams,
  GridRowEditStopReasons,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridToolbar,
  useGridApiContext
} from '@mui/x-data-grid';
import { unwrapResult } from '@reduxjs/toolkit';
import { ITEM_TYPE_V2 } from 'app-constants';
import BaseCurrencyInput from 'components/BaseCurrencyInput';
import _ from 'lodash';
import * as React from 'react';
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux-store';
import { setBreadcrumb } from 'redux-store/reducers/common';
import { getListInventoryItem, updateInventoryItemLocal, updateInventoryItemPrice } from 'redux-store/reducers/inventory';
import ROUTERS from 'routers/constants';
import { showCurrency } from 'utils/common';
import notification from 'utils/notification';
import { RolesEnum, getPermission } from 'utils/roles';

export default function InventoryGrid() {
  const dispatch = useAppDispatch();
  const handleGetListInventoryItem = () => {
    dispatch(getListInventoryItem({}));
  }
  const navigate = useNavigate();

  const inventoryItemList = useAppSelector((state) => {
    return state.inventory.allItems.map(e => {
      return { ...e, total: e.quantity * e.cost, type: ITEM_TYPE_V2[e.type] }
    })
  });

  const allowValueStock = getPermission("VIEW_STOCK_VALUE")
  const roleName = useAppSelector((state) => state.auth.user?.roleName);
  const isAllowEdit = [RolesEnum.CEO, RolesEnum.VietnamAccounting, RolesEnum.Director, RolesEnum.COO].includes(roleName as RolesEnum)

  const totalMoney = useAppSelector((state) => state.inventory.totalMoney);

  useEffect(() => {
    handleGetListInventoryItem();
    dispatch(setBreadcrumb([
      {
        label: "Inventory",
        link: ROUTERS.INVENTORY,
      },
      {
        label: "Item Management",
      },
    ]));
  }, []);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  function normalizeValue(value: any): number | any {
    if (typeof value === 'string' && !isNaN(Number(value))) {
      return parseFloat(value);
    }
    return value;
  }

  function areNumbersClose(val1: any, val2: any, precision = 2) {
    return Math.abs(val1 - val2) < Math.pow(10, -precision);
  }

  const hasObjectChanged = (obj1: any, obj2: any) => {
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        const val1 = normalizeValue(obj1[key]);
        const val2 = normalizeValue(obj2[key]);
        if (typeof val1 === 'number' && typeof val2 === 'number') {
          if (!areNumbersClose(val1, val2)) {
            return true;
          }
        } else if (val1 !== val2) {
          return true;
        }
      }
    }
    for (let key in obj2) {
      if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
        return true;
      }
    }
    return false;
  }

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const checking = hasObjectChanged(newRow, oldRow)
    const updatedRow = { ...newRow, isNew: false };
    if (checking) {
      // call api update
      if (!isAllowEdit) return updatedRow;
      dispatch(
        updateInventoryItemPrice(
          _.pick(newRow, [
            'code',
            'cost',
            'fob_price',
            'id',
            'price',
            'regular_price',
            'special_price',
            'unit',
            'name'
          ])
        )
      )
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) {
            notification.success();
            dispatch(updateInventoryItemLocal(newRow));
          } else {
            notification.error();
          }
          return updatedRow
        });
    }
    // setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function MoneyEditInputCell(props: GridRenderCellParams) {
    const apiRef = useGridApiContext();
    const { id, value, field } = props;
    const handleChange = async (data: any) => {
      data = !isNaN(Number(data)) ? data : 0
      await apiRef.current.setEditCellValue({ id, field, value: (data || 0) });
    };
    return (
      <BaseCurrencyInput
        placeholder={'$0.00'}
        className="no-css"
        value={value}
        onChange={handleChange}
      ></BaseCurrencyInput>
    );
  }

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});
  const handleVisibilityChange = (newModel: GridColumnVisibilityModel) => {
    if (!Object.keys(newModel).length) {
      return setColumnVisibilityModel({ ...newModel })
    }
    setColumnVisibilityModel((prevModel) => ({
      ...prevModel,   // Spread previous values
      ...newModel     // Override with new visibility states
    }));
  };

  const handleCellClick = (params: any) => {
    if (params.field === 'code') {
      return navigate(`${ROUTERS.INVENTORY_DETAIL.replace(":id", params.id as any)}`)
    }
  };

  let columns: GridColDef[] = [
    {
      field: 'code',
      headerName: 'Code',
      width: 180,
      hideable: false,
      editable: false,
      renderHeader: () => <b>Code</b>,
      renderCell: (e) => <><div
        style={{
          cursor: "pointer",
          color: rowModesModel[e.id]?.mode === GridRowModes.Edit ? "red" : '',
          fontWeight: 600
        }}>{e.value}</div></>,
    }];

  if (allowValueStock) {
    columns.push({
      field: 'cost',
      headerName: 'Cost',
      renderCell: (e) => <>{showCurrency(e.value)}</>,
      renderEditCell: (e) => MoneyEditInputCell(e),
      type: 'number',
      width: 140,
      align: 'left',
      headerAlign: 'left',
      editable: isAllowEdit
    })
  }

  const column2: GridColDef[] = [{
    field: 'price',
    headerName: 'US WH Price',
    type: 'number',
    renderCell: (e) => <>{showCurrency(e.value)}</>,
    renderEditCell: (e) => MoneyEditInputCell(e),
    width: 160,
    align: 'left',
    headerAlign: 'left',
    editable: isAllowEdit,
  },
  {
    field: 'fob_price',
    headerName: 'FOB Price',
    renderCell: (e) => <>{showCurrency(e.value)}</>,
    renderEditCell: (e) => MoneyEditInputCell(e),
    type: 'number',
    width: 140,
    align: 'left',
    headerAlign: 'left',
    editable: isAllowEdit,
  },
  {
    field: 'special_price',
    headerName: 'Special Price',
    renderCell: (e) => <>{showCurrency(e.value)}</>,
    renderEditCell: (e) => MoneyEditInputCell(e),
    type: 'number',
    width: 140,
    align: 'left',
    headerAlign: 'left',
    editable: isAllowEdit,
  },
  {
    field: 'regular_price',
    headerName: 'End User Price',
    renderCell: (e) => <>{showCurrency(e.value)}</>,
    renderEditCell: (e) => MoneyEditInputCell(e),
    type: 'number',
    width: 140,
    align: 'left',
    headerAlign: 'left',
    editable: isAllowEdit,
  }]

  columns = columns.concat(column2)

  const column3: GridColDef[] = [
    {
      field: 'unit',
      headerName: 'Unit',
      type: 'string',
      width: 90,
      align: 'left',
      headerAlign: 'left',
      editable: isAllowEdit,
    },
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      width: 240,
      align: 'left',
      headerAlign: 'left',
      editable: isAllowEdit
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'string',
      width: 130,
      align: 'left',
      headerAlign: 'left',
      renderCell: (e) => <><Chip label={e.value} size='small'></Chip> </>,
    }
  ]

  columns = columns.concat(column3)



  return (
    <Box
      sx={{
        height: 700,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        sx={{ p: 1 }}
        pageSizeOptions={[50, 100]}
        rows={inventoryItemList}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: false },
            showQuickFilter: true,
          },
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleVisibilityChange}
        onCellClick={handleCellClick} // Handle cell click for specific column
      />
    </Box >
  );
}

