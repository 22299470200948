import { Grid, Switch } from "@mui/joy";
import AspectRatio from "@mui/joy/AspectRatio";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import { unwrapResult } from "@reduxjs/toolkit";
import BaseInput from "components/BaseInput";
import BaseSelect from "components/BaseSelect";
import PageWrapper from "components/PageWrapper";
import { DetailSkeleton } from "components/SkeletonComponents";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  getDetailEmployee,
  updateEmployee,
} from "redux-store/reducers/employee";
import ROUTERS from "routers/constants";
import { IEmployee } from "types/employee";
import notification from "utils/notification";

export default function EmployeeDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const id = useParams()?.id || detailId;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [employeeDetail, setEmployeeDetail] = useState<IEmployee | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const roleList = useAppSelector((state) => state.employee.roleList);
  const [checked, setChecked] = useState<boolean>(false);

  const onCancel = () => {
    navigate(ROUTERS.EMPLOYEE);
  };

  const onSave = () => {
    if (!employeeDetail) return;
    setIsLoading(true);
    dispatch(
      updateEmployee({
        id: employeeDetail.id,
        status: checked ? "Active" : "Inactive",
        email: employeeDetail.email,
        dob: employeeDetail.dob,
        phone: employeeDetail.phone,
        username: employeeDetail.username,
        password: employeeDetail.password,
        name: employeeDetail.name,
        pin: employeeDetail.pin,
        address: employeeDetail.address,
        photo: employeeDetail.photo || "",
        roles: employeeDetail.roles,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setIsLoading(false);

        if (response.data?.id) {
          notification.success();
          fetchDetail();
        } else {
          notification.error();
        }
      });
  };

  const fetchDetail = () => {
    if (!id) return;
    dispatch(
      getDetailEmployee({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        const employee = response.data;

        if (response.data?.id) {
          try {
            response.data.roles = _.get(response.data, "roles[0].id")
              ? [_.get(response.data, "roles[0].id") as unknown as number]
              : [];
          } catch (e) { }
          setChecked(employee.status === "Active");
          setEmployeeDetail(employee);
        }
      });
  };
  useEffect(() => {
    fetchDetail();
  }, [id]);

  return (
    <>
      <PageWrapper
        pageName={`Employee Number: #` + employeeDetail?.employee_number}
        breadcrumb={[
          {
            label: "Employee",
            link: ROUTERS.EMPLOYEE,
          },
          {
            label: "Detail",
          },
        ]}
        pageAction={
          <Box>
            <Button
              color="primary"
              size="sm"
              variant="plain"
              onClick={onCancel}
              disabled={isLoading}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              size="sm"
              onClick={onSave}
              loading={isLoading}
            >
              Save
            </Button>
          </Box>
        }
        skeletonLoading={!employeeDetail ? <DetailSkeleton /> : undefined}
      >
        {!!employeeDetail && (
          <Box
            sx={{
              display: "flex",
              py: 1,
            }}
          >
            <AspectRatio
              ratio="1"
              maxHeight={200}
              sx={{ flex: 1, minWidth: 100, borderRadius: "100%", mr: 3 }}
            >
              <Avatar variant="outlined" size="sm" />
            </AspectRatio>
            <Grid container spacing={3}>
              <Grid sm={6}>
                <FormControl>
                  <BaseInput
                    label="Name"
                    value={employeeDetail.name}
                    onChange={(e) =>
                      setEmployeeDetail({
                        ...employeeDetail,
                        name: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid sm={6}>
                <FormControl>
                  <BaseSelect
                    label="Role"
                    options={roleList.map((v) => ({
                      label: v.name,
                      value: v.id,
                    }))}
                    value={
                      employeeDetail.roles?.length
                        ? employeeDetail.roles[0]
                        : ""
                    }
                    onChange={(e) =>
                      setEmployeeDetail({
                        ...employeeDetail,
                        roles: e ? [e] : [],
                      })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid sm={6}>
                <FormControl>
                  <BaseInput
                    label="Username"
                    value={employeeDetail.username}
                    onChange={(e) =>
                      setEmployeeDetail({
                        ...employeeDetail,
                        username: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid sm={6}>
                <FormControl>
                  <BaseInput
                    label="Password"
                    type="password"
                    value={employeeDetail.password}
                    onChange={(e) =>
                      setEmployeeDetail({
                        ...employeeDetail,
                        password: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid sm={6}>
                <FormControl>
                  <BaseInput
                    label="Email"
                    value={employeeDetail.email}
                    onChange={(e) =>
                      setEmployeeDetail({
                        ...employeeDetail,
                        email: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid sm={6}>
                <FormControl>
                  <BaseInput
                    label="Phone"
                    value={employeeDetail.phone}
                    onChange={(e) =>
                      setEmployeeDetail({
                        ...employeeDetail,
                        phone: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Grid>

              {employeeDetail.id !== 1 && (
                <Grid sm={3}>
                  <Switch
                    checked={checked}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setChecked(event.target.checked)
                    }
                    color={checked ? "success" : "neutral"}
                    variant={checked ? "solid" : "outlined"}
                    endDecorator={checked ? "Active" : "Inactive"}
                    slotProps={{
                      endDecorator: {
                        sx: {
                          minWidth: 24,
                        },
                      },
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        )}
      </PageWrapper>
    </>
  );
}
