import { BoxProps } from "@mui/joy"
import { ReactNode } from "react"

export default function CardWithLabel(
  { children, label, ...props }: BoxProps & { label: string | ReactNode }
) {
  return <fieldset
    style={{
      border: `1px solid #ccc`,
      borderRadius: 4,
      padding: '12px',
      ...props.style
    }}
  >
    <legend
      style={{
        fontSize: '14px'
      }}
    >{label}</legend>
    <div
      style={{
        marginTop: '-8px'
      }}
    >{children}</div>
  </fieldset>

}