import _ from "lodash";

const ROUTERS = {
  LOGIN: "/admin",

  NOT_FOUND: "/404",

  DASHBOARD: "/dashboard",

  QUICKBOOKAUTH: "/quickbook/auth/v2",
  UPSAUTH: "/ups/auth/v2",

  PROFILE: "/profile",

  PRODUCT: "/product",
  PRODUCT_NEW: "/product/new",
  PRODUCT_DETAIL: "/product/detail/:id",
  PRODUCT_IMPORT: "/product/import",

  PURCHASE: "/purchase",
  PURCHASE_NEW: "/purchase/new",
  PURCHASE_DETAIL: "/purchase/detail/:id",

  RECEIVE: "/receive",
  AUDIT: "/item-audit",
  ITEM_SCAN: "/item-scan",

  PALLET_PACKING: "/packing/pallet",
  RM_SERIAL: "/scan-rm-serial-69",

  MANUFACTURING: "/manufacturing",
  MANUFACTURING_DETAIL: "/manufacturing/detail/:id",

  PALLET: "/pallet",
  PALLET_DETAIL: "/pallet/detail/:id",

  CUSTOMER: "/customer",
  CUSTOMER_NEW: "/customer/new",
  CUSTOMER_DETAIL: "/customer/detail/:id",

  EMPLOYEE: "/employee",
  EMPLOYEE_NEW: "/employee/new",
  EMPLOYEE_DETAIL: "/employee/detail/:id",

  SALES_ORDER: "/sales-order",
  SALES_ORDER_NEW: "/sales-order/new",
  SALES_ORDER_DETAIL: "/sales-order/detail/:id",

  SALES_ORDER_BACKORDER: "/backorder",
  SALES_ORDER_BACKORDER_DETAIL: "/backorder/detail/:id",

  SALES_ORDER_PICK: "/pps-processing",
  SALES_ORDER_PICK_DETAIL: "/pps-processing/detail/:id",

  CONTAINER: "/container",

  PROMOTION: "/promotion",
  PROMOTION_NEW: "/promotion/new",
  PROMOTION_DETAIL: "/promotion/detail/:id",

  INVENTORY: "/inventory",
  INVENTORY_GRID: "/inventory/grid",
  INVENTORY_DETAIL: "/inventory/item/:id",

  WAREHOUSE: "/warehouse",
  WAREHOUSE_NEW: "/warehouse/new",
  WAREHOUSE_DETAIL: "/warehouse/detail/:id",

  FREIGHT_QUOTE: "/freight-quote",
  FREIGHT_QUOTE_DETAIL: "/freight-quote/detail/:id",

  INVOICE: "/invoice",
  INVOICE_DETAIL: "/invoice/detail/:id",

  RETURN: "/return",
  RETURN_NEW: "/return/new",
  RETURN_DETAIL: "/return/detail/:id",

  ACCOUNTING: "/accounting",

  REPORT: "/report",
  SETTINGS: "/settings",
  PRINT: "/print",
};
export const screensEnum = _.mapValues(ROUTERS, (value, key) => key);
export const notificationBadge = {
  BACKORDER: 'backorder'
}
export const ROUTERS_CUSTOMER = {
  CUSTOMER_HOME: "/",
  LOGIN: "/login",
  DASHBOARD: "/customer-portal/dashboard",
  MY_PROFILE: "/customer-portal",
  INVOICE: "/customer-portal/invoice",
  INVOICE_DETAIL: "/customer-portal/invoice/detail/:id",
  PURCHASE_ORDER: "/customer-portal/purchase-order",
  PURCHASE_ORDER_NEW: "/customer-portal/purchase-order/new",
  PURCHASE_ORDER_DETAIL: "/customer-portal/purchase-order/detail/:id",

  FREIGHT_QUOTE: "/customer-portal/freight-quote",
  FREIGHT_QUOTE_NEW: "/customer-portal/freight-quote/new",
  FREIGHT_QUOTE_DETAIL: "/customer-portal/freight-quote/detail/:id",

  SUPPORT_CASE: "/customer-portal/support-case",

  POLICY: "/customer-portal/policy",
};

export default ROUTERS;
