import Check from '@mui/icons-material/Check';
import { Skeleton } from '@mui/joy';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux-store';
import { getOpenAction } from 'redux-store/reducers/sales-order';

export interface IQuickListReport {
  title: string;
}
export default function QuickListReport({ data }: { data: IQuickListReport }) {

  const openActionReport = useAppSelector(
    (state) => state.salesOrder.openActionReport
  );

  const dispatch = useAppDispatch()

  const handleGet = () => {
    dispatch(getOpenAction({}))
  };
  useEffect(() => {
    handleGet();
  }, []);

  return (
    <Box

    >
      <Card sx={{ height: 391 }} variant="outlined">
        <Chip size="sm" variant="outlined" color="neutral">
          Today
        </Chip>
        <Typography level="h3">{data.title}</Typography>
        <Divider inset="none" />


        {openActionReport ? <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
          <ListItem>
            <ListItemDecorator>
              <Check fontSize='small' />
            </ListItemDecorator>
            Unpaid Invoice: <b>{openActionReport.unpaidInvoiceCount}</b>
          </ListItem>

          <ListItem>
            <ListItemDecorator>
              <Check fontSize='small' />
            </ListItemDecorator>
            Past Due Invoice: <b>{openActionReport.dueInvoiceCount}</b>
          </ListItem>

          <ListItem sx={{ mb: 1 }}>
            <ListItemDecorator>
              <Check fontSize='small' />
            </ListItemDecorator>
            Invoice need Send Email: <b>{openActionReport.invoiceNeedSendEmailCount}</b>
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check fontSize='small' />
            </ListItemDecorator>
            Backorder need Proccess: <b>{openActionReport.backorderNeedProcessCount}</b>
          </ListItem>
          <ListItem sx={{ mb: 1 }}>
            <ListItemDecorator>
              <Check fontSize='small' />
            </ListItemDecorator>
            Sales Order need Review : <b>{openActionReport.salesOrderNeedReviewCount}</b>
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check fontSize='small' />
            </ListItemDecorator>
            Ready to Pick: <b>{openActionReport.readyToPickCount}</b>
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check fontSize='small' />
            </ListItemDecorator>
            Ready to Pack: <b>{openActionReport.readyToPackCount}</b>
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check fontSize='small' />
            </ListItemDecorator>
            Ready to Ship: <b>{openActionReport.readyToShipCount}</b>
          </ListItem>

        </List>
          : <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
            <Skeleton level="body-xs" variant="text" width="92%" />
            <Skeleton level="body-xs" variant="text" width="99%" />
            <Skeleton level="body-xs" variant="text" width="96%" />
            <Skeleton level="body-lg" variant="text" width="99%" />
            <Skeleton level="body-xs" variant="text" width="96%" />
            <Skeleton level="body-xs" variant="text" width="99%" />
            <Skeleton level="body-lg" variant="text" width="96%" />
          </List>
        }
      </Card>
    </Box>
  );
}
