import { Box, Typography } from "@mui/joy";
import Button from "@mui/joy/Button";
import { POStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import FilterDrawer, { IFilterItem } from "components/FilterDrawer";
import PageWrapper from "components/PageWrapper";
import _ from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { savePageFilter } from "redux-store/reducers/common";
import {
  getListPurchase,
  getListPurchaseSupplier,
  getListPurchaseWarehouse,
} from "redux-store/reducers/purchase";
import ROUTERS from "routers/constants";
import { formatDate, showCurrency } from "utils/common";
import { getAllowAccessRoute, getPermission } from "utils/roles";

export default function PurchaseListPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const filterValue = useAppSelector((state) => state.common.searchFilter.PO_LIST)
  const purchaseList = useAppSelector((state) => state.purchase.list);
  const productItems =
    useAppSelector((state) => state.product.productItems) || [];
  const filterFields: IFilterItem[] = [
    {
      label: "Purchase Number / Name",
      type: "texts",
      key: "textSearch",
      placeholder: "Type Purchase Number or Name",
    },
    {
      label: "Items",
      placeholder: "Select item",
      type: "multiple-select",
      key: "items",
      options: _.map(productItems, (cus) => ({ label: cus.code, value: cus.code })),
    },
    {
      label: "Status",
      type: "multiple-select",
      key: "statuses",
      placeholder: "Select status",
      options: [
        POStatusEnum.PENDING_APPROVAL,
        POStatusEnum.PROCESSOR_APPROVED,
        POStatusEnum.MANAGER_APPROVED,
        POStatusEnum.MANUFACTURING_APPROVED,
        POStatusEnum.ACCOUNTING_APPROVED,
        POStatusEnum.MANUFACTURING_IN_PROCESS,
        POStatusEnum.AWAITING_SHIP,
        POStatusEnum.SENT_TO_WAREHOUSE,
        POStatusEnum.RECEIVED_AT_WAREHOUSE,
        POStatusEnum.CANCELLED
      ].map(e => ({
        label: e,
        value: e
      }))
    },
  ];

  useEffect(() => {
    dispatch(getListPurchase(filterValue));
  }, [filterValue])

  useEffect(() => {
    dispatch(getListPurchaseSupplier({}));
    dispatch(getListPurchaseWarehouse({}));
  }, []);

  return (
    <PageWrapper
      pageAction=
      {<Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {getAllowAccessRoute("PURCHASE_NEW") && (
          <Button
            color="primary"
            size="sm"
            onClick={() => navigate(ROUTERS.PURCHASE_NEW)}
            sx={{ mr: 1 }}
          >
            Create
          </Button>
        )}
        <FilterDrawer
          value={filterValue}
          filters={filterFields}
          onChange={e => {
            dispatch(savePageFilter({
              name: 'PO_LIST',
              data: e,
            }))
          }}
        />
      </Box>
      }
      pageName="Purchase Orders"
      breadcrumb={[
        {
          label: "Purchase Order",
        },
      ]}
    >
      <CommonList
        data={purchaseList}
        columns={[
          {
            key: "po_number",
            label: "#",
            width: 80,
            render: (row) => <b>{row.po_number}</b>,
          },
          {
            key: "name",
            label: "Name",
          },
          {
            key: "status",
            label: "Status",
            width: 200,
            render: (row) => <ChipStatus status={row.status} />,
          },
          {
            key: "total",
            label: "Total",
            width: 140,
            hide: getPermission('NOT_PO_VIEW_PRICE'),
            render: (row) => <Typography>{showCurrency(row.total)}</Typography>,
          },
          {
            key: "created_at",
            label: "Created At",
            width: 120,
            render: (row) => (
              <Typography>{formatDate(row.created_at)}</Typography>
            ),
          }
        ]}
        sortKeys={["created_at", "po_number", "name", "total"]}
        onRowClick={(row) =>
          navigate(`${ROUTERS.PURCHASE_DETAIL.replace(":id", row.id as any)}`)
        }
      />
    </PageWrapper>
  );
}
