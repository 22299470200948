import { Box, Typography } from "@mui/joy";
import { calcGrantTotal, showCurrency } from "utils/common";

const SOMoneyInfo = ({ salesOrderDetail }: {
  salesOrderDetail?: {
    total?: number;
    tax_cost?: number;
    shipping_cost?: number;
    discount_item?: number;
    pallet_cost?: number;
    is_pallet?: boolean;
  }
}) => {
  if (!salesOrderDetail) return null

  return <Box sx={{
    display: "flex",
    justifyContent: "space-between",
    gap: 1,
  }}>
    <Box>
      <Typography fontSize="sm" fontWeight={600} textAlign="right">
        Subtotal
      </Typography>
      <Typography fontSize="sm" textAlign="right">
        Tax
      </Typography>
      <Typography fontSize="sm" textAlign="right">
        Shipping
      </Typography>

      {salesOrderDetail.is_pallet && (
        <Typography fontSize="sm" textAlign="right">
          Pallet fee
        </Typography>
      )}

      <Typography fontSize="sm" textAlign="right">
        Total Discount
      </Typography>

      <Typography fontSize="sm" fontWeight={600} textAlign="right">
        Total
      </Typography>
    </Box>
    <Box>
      <Typography fontSize="sm" textAlign="right">
        {showCurrency(salesOrderDetail?.total)}
      </Typography>
      <Typography fontSize="sm" textAlign="right">
        {showCurrency(salesOrderDetail?.tax_cost)}
      </Typography>
      <Typography fontSize="sm" textAlign="right">
        {showCurrency(salesOrderDetail?.shipping_cost)}
      </Typography>

      {salesOrderDetail.is_pallet && (
        <Typography fontSize="sm" textAlign="right">
          {showCurrency(salesOrderDetail?.pallet_cost)}
        </Typography>
      )}

      <Typography fontSize="sm" textAlign="right">
        -{showCurrency(salesOrderDetail?.discount_item)}
      </Typography>

      <Typography fontSize="sm" fontWeight={600} textAlign="right">
        {salesOrderDetail
          ? showCurrency(calcGrantTotal(salesOrderDetail))
          : ""}
      </Typography>
    </Box>
  </Box>
}

export default SOMoneyInfo