import { Grid } from "@mui/joy";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import { PAYMENT_METHODS } from "app-constants";
import BaseCurrencyInput from "components/BaseCurrencyInput";
import BaseDatePicker from "components/BaseDatePicker";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import BaseSelect from "components/BaseSelect";
import { useEffect, useState } from "react";
import { IPaymentItem } from "types/invoice";

type TCreateInvoiceModal = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (item: IPaymentItem) => void;
};

export default function CreateInvoicePaymentModal({
  isOpen,
  onClose,
  onCreate,
}: TCreateInvoiceModal) {
  const [paymentItem, setPaymentItem] = useState<IPaymentItem | null>({
    amount: 0,
    payment_date: "",
    payment_transaction: "",
    payment_method: "",
    note: "",
    receive_bank: "",
  });

  useEffect(() => {
    if (!isOpen) {
      onResetState();
    }
  }, [isOpen]);

  const onResetState = () => {
    setPaymentItem({
      amount: 0,
      payment_date: "",
      payment_transaction: "",
      payment_method: "",
      note: "",
    });
  };

  const onCloseModal = () => {
    onClose();
  };

  const onCreateClick = () => {
    onCreate({
      ...paymentItem,
    });
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onCloseModal}
      title="Add payment"
      actions={<Button onClick={onCreateClick}>{"Save"}</Button>}
    >
      <Grid container spacing={2}>
        <Grid sm={6}>
          <FormControl>
            <BaseCurrencyInput
              label={"Amount"}
              value={Number(paymentItem?.amount)}
              onChange={(e) =>
                setPaymentItem({
                  ...paymentItem,
                  amount: Number(e),
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid sm={6}>
          <FormControl>
            <BaseDatePicker
              label={"Payment Date"}
              value={paymentItem?.payment_date}
              onChange={(e) =>
                setPaymentItem({
                  ...paymentItem,
                  payment_date: e,
                })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={12}>
          <FormControl>
            <BaseSelect
              options={PAYMENT_METHODS.map((v) => ({
                label: v,
                value: v,
              }))}
              label="Payment Method"
              value={paymentItem?.payment_method}
              onChange={(value: any) =>
                setPaymentItem({
                  ...paymentItem,
                  payment_method: value || "",
                })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={12}>
          <FormControl>
            <BaseInput
              label={"Payment Transaction"}
              value={paymentItem?.payment_transaction}
              onChange={(e) =>
                setPaymentItem({
                  ...paymentItem,
                  payment_transaction: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={12}>
          <FormControl>
            <BaseInput
              label={"Abaco Bank Account"}
              multiline
              minRows={2}
              value={paymentItem?.receive_bank}
              onChange={(e) =>
                setPaymentItem({
                  ...paymentItem,
                  receive_bank: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={12}>
          <FormControl>
            <BaseInput
              label={"Note"}
              multiline
              minRows={2}
              value={paymentItem?.note}
              onChange={(e) =>
                setPaymentItem({
                  ...paymentItem,
                  note: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </BaseModal>
  );
}
