import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import _ from "lodash";

import {
  Button,
  Grid,
  Stack,
  Tab,
  tabClasses,
  TabList,
  Tabs
} from "@mui/joy";
import { SOStatusEnum } from "app-constants";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ROUTERS from "routers/constants";
import { formatDate, isOverDueByDays } from "utils/common";
import { isMobile } from "utils/ui-uitils";
import BaseInput from "./BaseInput";
import { ChipStatus } from "./ChipStatus";
import CommonList from "./CommonList";

type Order = "asc" | "desc";

const getSortListInCurrentPage = ({
  data,
  page,
  pageSize,
}: {
  data: any[];
  page: number;
  pageSize: number;
}) => {
  const sortedList = data;
  if (!sortedList?.length) return [];
  if (sortedList.length <= pageSize) return sortedList;
  return sortedList?.slice((page - 1) * pageSize, page * pageSize);
};

const applyFilterToList = ({
  data,
  selectedStatus,
  statusKey,
  searchText,
  searchKeys,
}: {
  data: any[];
  selectedStatus: string;
  statusKey: string;
  searchText: string;
  searchKeys: string[];
}) => {
  if (!data?.length) return [];
  return _.filter(data, (item) => {
    if (selectedStatus) {
      if (selectedStatus !== item[statusKey]) return false;
    }
    if (searchText) {
      if (
        !searchKeys.some((key) =>
          item[key]?.toLowerCase().includes(searchText.toLowerCase())
        )
      )
        return false;
    }
    return true;
  });
};

interface IProps<T> {
  data: T[];
  noLoading?: boolean;
  columns: {
    key: string;
    label: string;
    hide?: boolean;
    rawValue?: (item: T) => any;
    render?: (item: T) => React.ReactNode;
    width?: number | string;
  }[];
  searchKeys: string[];
  sortKeys: string[];
  filterStatus: {
    statusKey: string;
    data: {
      label: string;
      value: string;
    }[];
  };
  pageSize?: number;
  onRowClick?: (item: T) => void;
  size?: "sm" | "md";
  tableActions?: React.ReactNode;
  tabName: string;
  setTabName: (value: any) => void;
}
export default function PickingList<T>({
  data: _data,
  noLoading = false,
  columns,
  searchKeys,
  sortKeys,
  filterStatus,
  pageSize = 12,
  onRowClick,
  tabName,
  setTabName,
  tableActions,
}: IProps<T>) {
  const navigate = useNavigate();
  const tabItems = [{
    label: "Pick",
    value: SOStatusEnum.READY_TO_PICK
  }, {
    label: "Pack",
    value: SOStatusEnum.READY_TO_PACK
  }, {
    label: "Ship",
    value: SOStatusEnum.READY_TO_SHIP
  }]


  const data = useMemo(() => {
    return _data?.map((item) => {
      const newItem = { ...item };
      columns.forEach((column) => {
        if (column.rawValue) {
          // @ts-ignore
          newItem[column.key] = column.rawValue(item);
        }
      });
      return newItem;
    });
  }, [_data, columns]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const { statusKey, data: statusData } = filterStatus;
  const [selectedStatus, setSelectedStatus] = useState<string>("");

  const filteredRows = useMemo(() => {
    return applyFilterToList({
      data,
      selectedStatus,
      statusKey,
      searchText,
      searchKeys,
    });
  }, [data, selectedStatus, statusKey, searchText, searchKeys]);

  useEffect(() => {
    setPage(1);
  }, [searchText, selectedStatus]);

  const searchTextPlaceholder = `Search by ${searchKeys
    .map((key) => columns.find((column) => column.key === key)?.label || key)
    .join(", ")}`;


  const shouldShowLoading =
    !noLoading && !(_data as any)?.finishLoading && !_data?.length;

  const emptyComponent = (
    <Box
      style={{
        textAlign: "center",
        padding: "16px 0",
      }}
    >
      <Typography>No data</Typography>
    </Box>
  );
  return (
    <>
      <Sheet sx={{ m: -1, p: 1 }}>
        <Tabs
          defaultValue={tabName}
          value={tabName}
          onChange={(e, value) => setTabName(value)}
          sx={{
            bgcolor: "transparent",
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            disableUnderline
            sx={(theme) => ({
              p: 1,
              borderRadius: 16,
              width: "100%",
              mx: 'auto',
              boxShadow: theme.shadow.sm,
              [`& .${tabClasses.root}`]: {
                py: 1,
                flex: 1,
                transition: '0.3s',
                fontWeight: 'md',
                [`&:not(.${tabClasses.selected}):not(:hover)`]: {
                  opacity: 0.7,
                },
              },
            })}
          >
            {tabItems.map((item) => (
              <Tab disableIndicator value={item.value} key={item.value}>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <b>{item.label}</b>
                    {/* <Chip
                      size="sm"
                      sx={{ ml: 1 }}
                      variant="soft"
                      color={"primary"}
                    >
                      14
                    </Chip> */}
                  </Box>
                </Box>
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Grid
          container
          spacing={2}
          sx={{
            my: 1,
          }}
        >
          <Grid sm={12} xs={12}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}>
              <FormControl sx={{ flex: 1 }} size="sm">
                <BaseInput
                  label={searchTextPlaceholder}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </FormControl>
              {tabName === SOStatusEnum.READY_TO_SHIP &&
                <Button size="md" variant='outlined' onClick={() => {
                  // navigate(ROUTERS.PRINT)
                  window.open(ROUTERS.PRINT, '_blank')
                }}>
                  UPS End Of Day
                </Button>
              }
            </Box>
          </Grid>
        </Grid>

        {!isMobile() ? <CommonList
          data={filteredRows}
          pageSize={50}
          noLoading={!shouldShowLoading}
          columns={[
            {
              key: "customerName",
              label: "Sales Order",
              rawValue: (row) => `${row.customer.first_name} ${row.customer.last_name}`,
              render: (row) => <Stack>
                <Typography level="body-md">
                  <b>#{row.so_number}</b>
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography level="body-sm">
                    {row.customer.first_name}{" "}
                    {row.customer.last_name}
                  </Typography>
                </Box>
              </Stack>
            },
            {
              key: "saleRepName",
              label: "Sale Rep",
              rawValue: (row) => row.user?.name,
            },
            {
              key: "deliveryInfo",
              label: "Ship Info",
              rawValue: (row) =>
                `${row.delivery_method} ${row.shipping_service || row.delivery_company ? " - " : ""} ${row.shipping_service || row.delivery_company}`,
            },
            {
              key: "status",
              label: "Status",
              width: 140,
              render: (row) => <ChipStatus status={row.status} size="small" />,
            },
            {
              key: "approved_date",
              label: "Ready On",
              width: 140,
              render: (row) => {
                const check = isOverDueByDays(row.approved_date, 3)
                return <Typography level="body-sm" sx={{
                  color: check ? 'red' : '',
                  fontWeight: check ? '600' : '400'
                }}>
                  {row.approved_date ? formatDate(row.approved_date) : ''}
                </Typography>
              },
            },
          ]}
          sortKeys={["approved_date", "deliveryInfo", "saleRepName", "customerName", "so_number"]}
          onRowClick={(row) =>
            onRowClick?.(row)
          }
          maxHeight={'calc(100vh - 261px)'}
        /> :
          <CommonList
            data={filteredRows}
            noLoading={!shouldShowLoading}
            pageSize={50}
            columns={[
              {
                key: "customerName",
                label: "Sales Order",
                rawValue: (row) => `${row.customer.first_name} ${row.customer.last_name} ${row.so_number}`,
                render: (row) => <Stack>
                  <Typography level="body-md">
                    <b>#{row.so_number}</b> - {row.delivery_method} {row.shipping_service || row.delivery_company ? " - " : ""} {row.shipping_service || row.delivery_company}
                  </Typography>

                  <Typography level="body-sm">
                    {row.customer.first_name}
                  </Typography>
                  <Typography>
                    {row.user?.name}
                  </Typography>
                </Stack>
              },
              {
                key: "approved_date",
                label: "Ready On",
                width: 120,
                render: (row) => {
                  const check = isOverDueByDays(row.approved_date, 3)
                  return <Typography level="body-sm" sx={{
                    color: check ? 'red' : '',
                    fontWeight: check ? '600' : '400'
                  }}>
                    {row.approved_date ? formatDate(row.approved_date) : ''}
                  </Typography>
                },
              },
            ]}
            sortKeys={["approved_date", "customerName"]}
            onRowClick={(row) =>
              onRowClick?.(row)
            }
            maxHeight={'calc(100vh - 261px)'}
          />
        }
      </Sheet >
    </>
  );
}
