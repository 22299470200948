import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";

import { useAppSelector } from "redux-store";

import { ClearOutlined, ModeEdit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { IPurchaseOrderItem } from "types/purchase";
import {
  calculateTotalItems,
  showCurrency,
  showProductItemName,
} from "utils/common";

type TPurchaseTableItem = {
  listItems: IPurchaseOrderItem[];
  onEditItem?: (item: IPurchaseOrderItem) => () => void;
  onRemoveItem?: (id?: number) => (event: any) => void;
};

const PurchaseTableItem = ({
  listItems,
  onEditItem,
  onRemoveItem,
}: TPurchaseTableItem) => {
  const productItems = useAppSelector((state) => state.product.productItems);
  const subTotal = calculateTotalItems(listItems);

  const getId = (item: IPurchaseOrderItem) => item.item_id || item?.item?.id;

  return (
    <Stack spacing={2}>
      <Table variant={"outlined"}>
        <thead>
          <tr>
            <th>Item</th>
            <th style={{ width: "100px", textAlign: "right" }}>Qty</th>
            <th
              style={{
                textAlign: "right",
              }}
            >
              Unit Cost
            </th>
            <th
              style={{
                textAlign: "right",
              }}
            >
              Total
            </th>
            {!!onRemoveItem && <th style={{ width: "65px" }}></th>}
          </tr>
        </thead>
        <tbody>
          {listItems.map((lineItem, idx) => {
            return (
              <tr key={lineItem?.item?.id}>
                <td>{showProductItemName(lineItem?.item, true)}</td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {lineItem.quantity || "-"}
                </td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {showCurrency(lineItem.cost || 0) || "-"}
                </td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {showCurrency(
                    (lineItem?.quantity || 0) * (lineItem?.cost || 0)
                  ) || "-"} {lineItem.item?.unit}
                </td>
                {!!onRemoveItem && (
                  <td>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                      }}
                    >
                      <Tooltip title="Edit" arrow>
                        <ModeEdit
                          sx={{ cursor: "pointer" }}
                          fontSize="small"
                          onClick={onEditItem?.(lineItem)}
                        />
                      </Tooltip>
                      <Tooltip title="Remove" arrow>
                        <ClearOutlined
                          sx={{ cursor: "pointer" }}
                          fontSize="small"
                          onClick={onRemoveItem?.(lineItem?.item?.id)}
                        />
                      </Tooltip>
                    </Box>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flex: 1 }}>
          <></>
        </Box>
        <Box mr={6}>
          <Typography fontSize="sm" fontWeight={600} textAlign="right">
            Subtotal
          </Typography>
          <Typography fontSize="sm" textAlign="right">
            Tax
          </Typography>
          <Typography fontSize="sm" textAlign="right">
            Shipping
          </Typography>

          <Typography fontSize="sm" textAlign="right" mb={2}>
            Other
          </Typography>
          <Typography fontSize="sm" fontWeight={600} textAlign="right">
            Total
          </Typography>
        </Box>
        <Box>
          <Typography fontSize="sm" fontWeight={600} textAlign="right">
            {showCurrency(subTotal)}
          </Typography>
          <Typography fontSize="sm" textAlign="right">
            {showCurrency(0)}
          </Typography>
          <Typography fontSize="sm" textAlign="right">
            {showCurrency(0)}
          </Typography>
          <Typography fontSize="sm" textAlign="right" mb={2}>
            {showCurrency(0)}
          </Typography>
          <Typography fontSize="sm" fontWeight={600} textAlign="right">
            {showCurrency(subTotal)}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default PurchaseTableItem;
