import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  IconButton,
  Stack,
  Switch,
  Table,
  Tooltip,
  Typography,
} from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { ROUTERS_CUSTOMER } from "routers/constants";

import { ClearOutlined, ModeEdit } from "@mui/icons-material";
import { SHIPPING_SERVICES } from "app-constants";
import BaseInput from "components/BaseInput";
import BaseSelect from "components/BaseSelect";
import ConfirmUtils from "components/ConfirmUtils";
import PageWrapper from "components/PageWrapper";
import { createCustomerFreightQuote } from "redux-store/reducers/customer-portal-freight-quote";
import { IFreightQuote, IFreightQuoteItem } from "types/freight-quote";
import { parseAddress, showProductItemName } from "utils/common";
import notification from "utils/notification";
import ModalAddNewCustomerPOItem from "../purchase/components/ModalAddNew";

export default function FreightQuoteNewPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const addressList =
    useAppSelector((state) => state.customerPortalSO.addressList) || [];
  const optionsAddressList = addressList.map((item: any) => ({
    label: parseAddress(item),
    value: item?.id,
    type: item?.type,
  }));

  const [isOpen, setIsOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState<IFreightQuoteItem>();
  const [listItems, setListItems] = useState<IFreightQuoteItem[]>([]);
  const [shippingId, setShippingId] = useState("");
  const [note, setNote] = useState("");
  const [expeditedShipping, setExpeditedShipping] = useState(false);
  const [shippingService, setShippingService] = useState("");

  const onToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const onCloseModal = () => {
    setIsOpen(false);
    setItemSelected(undefined);
  };

  const onCreateItem = (item: IFreightQuoteItem) => {
    onToggleOpen();
    const isExist = listItems.find((i) => i.item_id === item.item_id);
    if (isExist) {
      setListItems(
        listItems.map((i) => (i.item_id === item.item_id ? item : i))
      );
    } else {
      setListItems([...listItems, item]);
    }
    setItemSelected(undefined);
  };

  const onEditItem = (itemSelected: IFreightQuoteItem) => () => {
    setItemSelected(itemSelected);
    onToggleOpen();
  };

  const onRemoveItem = (id?: number) => (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    ConfirmUtils.sure().then((isOk) => {
      if (!isOk) return;
      setListItems(listItems.filter((item) => item.item?.id !== id));
    });
  };

  const handleCreateNewFreightQuote = () => {
    const data: IFreightQuote = {
      note,
      quote_items: listItems.map((item) => ({
        item_id: item.item_id,
        quantity: item.quantity,
        note: item.note,
      })),
      shipping_address_id: +shippingId,
      is_expedited: !!expeditedShipping,
      shipping_service: shippingService,
    };

    setLoading(true);
    dispatch(createCustomerFreightQuote(data))
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        const data = response.data || {};
        if (response.data.id) {
          navigate(
            ROUTERS_CUSTOMER.FREIGHT_QUOTE_DETAIL.replace(":id", data.id as any)
          );
          notification.success();
        } else {
          const err: any = { ...data };
          notification.error(err.message);
        }
      });
  };

  return (
    <Box sx={{ mt: "24px" }}>
      <PageWrapper
        pageName={"New Freight Quote"}
        pageAction={
          <Box>
            <Button
              sx={{ marginRight: 1 }}
              variant="plain"
              size="sm"
              onClick={() => navigate(ROUTERS_CUSTOMER.FREIGHT_QUOTE)}
              disabled={loading}
            >
              Cancel
            </Button>

            <Button
              size="sm"
              onClick={handleCreateNewFreightQuote}
              disabled={loading}
            >
              Save
            </Button>
          </Box>
        }
      >
        <Stack>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid container xs={12} sm={12}>
              <Grid
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ height: 40 }}>
                  <Switch
                    checked={expeditedShipping}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setExpeditedShipping(event.target.checked)
                    }
                    endDecorator={"Expedited Ship"}
                  />
                </Box>
              </Grid>

              {expeditedShipping && (
                <Grid xs={12} sm={6}>
                  <FormControl>
                    <BaseSelect
                      label="Shipping Service"
                      options={SHIPPING_SERVICES}
                      value={shippingService}
                      onChange={(e: any) => setShippingService(e)}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <Grid xs={12} sm={6}>
              <FormControl>
                <BaseSelect
                  label="Ship To"
                  options={optionsAddressList.filter((v) => v.type === "ship")}
                  value={shippingId}
                  onChange={setShippingId}
                />
              </FormControl>
            </Grid>

            <Grid xs={12} sm={6}>
              <FormControl>
                <BaseInput
                  label={"Shipping Instruction"}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
                mt: 2,
              }}
            >
              <IconButton
                color="primary"
                size="sm"
                onClick={onToggleOpen}
                variant="outlined"
              >
                <AddIcon />
              </IconButton>
            </Box>

            <Stack spacing={2}>
              <Table variant={"outlined"} stickyHeader>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th style={{ width: "80px", textAlign: "right" }}>Qty</th>
                    {!!onRemoveItem && <th style={{ width: "65px" }}></th>}
                  </tr>
                </thead>
                <tbody>
                  {listItems.map((item, index) => {
                    const product = item.item;

                    return (
                      <tr key={index}>
                        <td>{showProductItemName(product, true)}</td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {item.quantity || "-"}
                        </td>
                        {!!onRemoveItem && (
                          <td>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                              }}
                            >
                              <Tooltip title="Edit" arrow>
                                <ModeEdit
                                  sx={{ cursor: "pointer" }}
                                  fontSize="small"
                                  onClick={onEditItem?.(item)}
                                />
                              </Tooltip>
                              <Tooltip title="Remove" arrow>
                                <ClearOutlined
                                  sx={{ cursor: "pointer" }}
                                  fontSize="small"
                                  onClick={onRemoveItem?.(item.item?.id)}
                                />
                              </Tooltip>
                            </Box>
                          </td>
                        )}
                      </tr>
                    );
                  })}

                  {!listItems.length ? (
                    <tr>
                      <td
                        colSpan={3}
                        style={{
                          textAlign: "center",
                          padding: "16px 0",
                        }}
                      >
                        <Typography>No Item</Typography>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </Table>
            </Stack>
          </Stack>
        </Stack>
      </PageWrapper>

      <ModalAddNewCustomerPOItem
        isOpen={isOpen}
        itemSelected={itemSelected}
        onCloseModal={onCloseModal}
        onCreateItem={onCreateItem}
        items={listItems}
      />
    </Box>
  );
}
