import { Clear, Refresh } from "@mui/icons-material";
import { Box, IconButton } from "@mui/joy";
import DialogContent from "@mui/joy/DialogContent";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import * as React from "react";
import { useAppDispatch } from "redux-store";
import AppEvent, { AppEventTypes } from "utils/app-events";

export default function BaseFullScreenModal({
  isOpen,
  onClose,
  children,
  title,
  fullscreen,
  showRefresh,
}: {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  title?: string;
  fullscreen?: boolean;
  showRefresh?: boolean;
}) {
  const dispatch = useAppDispatch();

  // detect backpage change page to close modal

  React.useEffect(() => {
    const handleBackPage = () => {
      onClose();
    };
    window.addEventListener("popstate", handleBackPage);
    return () => {
      window.removeEventListener("popstate", handleBackPage);
    };
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={(e, reason) => {
        if (reason !== "escapeKeyDown") {
          onClose();
        }
      }}
    >
      <ModalDialog layout={"fullscreen"} sx={{
        padding: fullscreen ? 0 : 2.5,
        gap: 0,
      }}>
        <Box sx={{ display: "flex", alignItems: "start", alignContent: "space-between" }}>
          <strong style={{
            flexGrow: 1,     /* Make the first element take up the remaining space */
            flexShrink: 0
          }}>{title}</strong>
          <Box sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}>
            {!!showRefresh && <IconButton
              onClick={() => {
                AppEvent.emit(AppEventTypes.FULL_SCREEN_MODAL_REFRESH)
              }}
            >
              <Refresh fontSize="small" />
            </IconButton>}
            <IconButton
              onClick={onClose}
            >
              <Clear
                fontSize="small"
              ></Clear>
            </IconButton>
          </Box>
        </Box>
        <DialogContent sx={{
          "> div": {
            padding: 0,
          }
        }}>
          {children}
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
}
