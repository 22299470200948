import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";

import { Card, Divider, Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import { setBreadcrumb } from "redux-store/reducers/common";

export default function PageWrapper({
  pageName,
  children,
  pageAction,
  breadcrumb,
  skeletonLoading,
  tabs,
  tab,
  onChangeTab,
}: {
  children?: React.ReactNode;
  pageName?: string | React.ReactNode;
  pageAction?: React.ReactNode | string;
  breadcrumb?: {
    label: string;
    link?: string;
  }[];
  skeletonLoading?: React.ReactNode;
  tabs?: {
    label: string;
    content?: React.ReactNode;
    renderContent?: () => React.ReactNode;
  }[];
  tab?: string;
  onChangeTab?: (tab: string) => void;
}) {
  const dispatch = useAppDispatch();
  const renderTabs = () => {
    if (!tabs) return null;
    if (skeletonLoading) return skeletonLoading;

    return <TabsWrapper tabs={tabs} onChangeTab={onChangeTab} tab={tab} />;
  };
  useEffect(() => {
    dispatch(setBreadcrumb(breadcrumb || null));
  }, [breadcrumb]);
  return (
    <>
      <Card
        key="wrapper"
        sx={{
          background: "var(--joy-palette-primary)",
          maxHeight: "100vh"
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            // flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Typography level="body-md">
            <b>{pageName}</b>
          </Typography>
          {pageAction}
        </Box>
        <Divider />
        {tabs?.length ? (
          renderTabs()
        ) : (
          <Box
            sx={{
              minHeight: 0,
              overflow: "hidden auto",
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              pb: 1,

              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            }}
          >
            {skeletonLoading ? skeletonLoading : children}
          </Box>
        )}
      </Card>
    </>
  );
}

function TabsWrapper({
  tabs,
  onChangeTab,
  tab,
}: {
  tabs: {
    label: string;
    content?: React.ReactNode;
    renderContent?: () => React.ReactNode;
  }[];
  tab?: string;
  onChangeTab?: (tab: string) => void;
}) {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  useEffect(() => {
    if (tab) {
      const selected = tabs.find((t) => t.label === tab) || tabs[0];
      setSelectedTab(selected);
      onChangeTab?.(selected.label);
    }
  }, [tab]);
  return (
    <>
      <Tabs
        sx={{
          marginX: -2,
          marginTop: "-0.74rem",
          overflow: "hidden auto",
          background: "var(--joy-palette-primary)",
        }}
        value={selectedTab.label}
        onChange={(e, value) => {
          const selected = tabs.find((tab) => tab.label === value) || tabs[0];
          setSelectedTab(selected);
          onChangeTab?.(selected.label);
        }}
      >
        <TabList variant="soft">
          {tabs.map((tab) => (
            <Tab key={tab.label} value={tab.label}>
              {tab.label}
            </Tab>
          ))}
        </TabList>
        {tabs.map((tab) => (
          <TabPanel
            key={tab.label}
            value={tab.label}
            sx={{
              overflow: "hidden auto",
            }}
          >
            {tab.renderContent ? tab.renderContent() : tab.content}
          </TabPanel>
        ))}
      </Tabs>
    </>
  );
}
