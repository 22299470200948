
import PageWrapper from "components/PageWrapper";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import QuickbookConfigs from "./components/QuickbookConfigs";
import UpsConfigs from "./components/UpsConfigs";

export default function SettingsPage() {
  const dispatch = useAppDispatch();
  const params = new URLSearchParams(window.location.search);
  const [tab, setTab] = useState((params.get("tab") as any) || "UPS Config")

  useEffect(() => {
  }, [])
  return (
    <>
      <PageWrapper
        pageName={'Settings'}
        breadcrumb={[
          {
            label: "Settings",
          },
        ]}
        tab={tab}
        onChangeTab={(newTab) => {
          if (tab === newTab) return;
          setTab(newTab)
          const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?tab=' + newTab;
          window.history.pushState({ path: newurl }, '', newurl);
        }}
        tabs={[
          // {
          //   label: "Email Config",
          //   content: <EmailConfigs />
          // },
          {
            label: "UPS Config",
            content: <UpsConfigs />
          },
          {
            label: "Quickbook Config",
            content: <QuickbookConfigs />
          },

        ]}
      >
      </PageWrapper>
    </>
  );
}
