import { IInventoryItem, IRealItem, IRelocateItem } from "types/inventory";
import { Request } from "utils/request";

export const listInventoryItemByTypeApi = ({
  type,
}: {
  type: "y" | "n" | 'fset' | 'rset';
}) => {
  return Request.call<{ data: IInventoryItem[] }>({
    url: `/product/inventory/items?type=${type}`,
    method: "GET",
  });
};

export const listInventoryItemApi = async () => {
  const [itemRs, partRs, fSetRs, rSetRs] = await Promise.all([
    listInventoryItemByTypeApi({ type: "y" }),
    listInventoryItemByTypeApi({ type: "n" }),
    listInventoryItemByTypeApi({ type: "fset" }),
    listInventoryItemByTypeApi({ type: "rset" }),
  ]);

  return {
    apiStatus: 1,
    data: {
      items: itemRs.data,
      parts: partRs.data,
      fSetItems: fSetRs.data,
      rSetItems: rSetRs.data,
    },
  };
};

export const getDetailInventoryItemApi = (payload: { id: any }) => {
  return Request.call<{ data: IInventoryItem }>({
    url: "/product/inventory/items/detail/" + payload.id,
    method: "GET",
  });
};

export const getDetailInventoryItemHistoryApi = (payload: { id: any }) => {
  return Request.call<{ data: IRealItem[] }>({
    url: "/product/inventory/items/history/" + payload.id,
    method: "GET",
  });
};



export const relocateItemApi = (payload: IRelocateItem) => {
  return Request.call<{ data: IRelocateItem }>({
    url: "/product/inventory/item-relocate/",
    method: "PUT",
    data: payload,
  });
};

export const removeStockItemApi = (payload: { serial_number?: string, ids?: any[] }) => {
  return Request.call<{ data: IRelocateItem }>({
    url: "/product/inventory/delete-real-item/",
    method: "PUT",
    data: payload,
  });
};


export const restoreStockItemApi = (payload: { serial_number?: string, ids?: any[] }) => {
  return Request.call<{ data: any }>({
    url: "/product/inventory/restore-real-item/",
    method: "PUT",
    data: payload,
  });
};



export const addOldSerialItemApi = (payload: {
  id: any;
  old_serial: string;
}) => {
  return Request.call<{ data: IRelocateItem }>({
    url: "/product/inventory/add-old-serial/",
    method: "PUT",
    data: payload,
  });
};

export const updateInventoryItemApi = (payload: {
  length: number;
  width: number;
  height: number;
  weight: number;
  cbm: number;
  code: string;
  id: any;
}) => {
  return Request.call<{ data: IInventoryItem }>({
    url: "/product/inventory/items/update/",
    method: "PUT",
    data: payload,
  });
};


export const updateInventoryItemPriceApi = (payload: {
  cost: number;
  price: number;
  fob_price: number;
  special_price: number;
  regular_price: number;
  code: string;
  unit: string;
  id: any;
}) => {
  return Request.call<{ data: IInventoryItem }>({
    url: "/product/inventory/items/update/price",
    method: "PUT",
    data: payload,
  });
};

export const reStockItemApi = (payload: {
  item_id?: any;
  area_id?: number;
  quantity: any;
  version?: string;
  version_date?: string;
}) => {
  return Request.call<{
    data: {
      id: any;
    };
  }>({
    url: "/product/inventory/item-restock/",
    method: "PUT",
    data: payload,
  });
};


export const updateAdjustQtyApi = (payload: {
  item_id?: any;
  quantity: any;
}) => {
  return Request.call<{
    data: {
      id: any;
    };
  }>({
    url: "/product/inventory/item-adjust-qty/",
    method: "PUT",
    data: payload,
  });
};


