import PageWrapper from "components/PageWrapper";
import ImportProduct from "pages/product/components/ImportProduct";
import { useAppSelector } from "redux-store";
import ROUTERS from "routers/constants";
import { RolesEnum } from "utils/roles";

export default function ProductImportPage() {
  const roleName = useAppSelector((state) => state.auth.user?.roleName);

  return (
    <>
      <PageWrapper
        pageName="Import Product"
        breadcrumb={[
          {
            label: "Products",
            link: ROUTERS.PRODUCT,
          },
          {
            label: "Import",
          },
        ]}
      >
        {roleName === RolesEnum.CEO && <ImportProduct />}
      </PageWrapper>
    </>
  );
}
