import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";

import { AspectRatio, Avatar, FormControl, Grid } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import BaseInput from "components/BaseInput";
import BaseSelect from "components/BaseSelect";
import PageWrapper from "components/PageWrapper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { createEmployee } from "redux-store/reducers/employee";
import ROUTERS from "routers/constants";
import { IEmployee } from "types/employee";
import notification from "utils/notification";

export default function EmployeeNewPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const roleList = useAppSelector((state) => state.employee.roleList);
  const [newEmployee, setNewEmployee] = useState<IEmployee>({
    username: "",
    phone: "",
    email: "",
    name: "",
    pin: "",
    address: "",
    password: "",
    roles: [],
  });

  const handleCreateNewEmployee = () => {
    setLoading(true);
    dispatch(createEmployee(newEmployee))
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        if (response.data?.id) {
          notification.success();
          navigate(ROUTERS.EMPLOYEE);
        } else {
          notification.error();
        }
      });
  };
  return (
    <>
      <PageWrapper
        pageAction={
          <Box>
            <Button
              sx={{ marginRight: 1 }}
              variant="plain"
              onClick={() => navigate(ROUTERS.EMPLOYEE)}
              disabled={loading}
            >
              Cancel
            </Button>

            <Button
              color="primary"
              size="sm"
              onClick={handleCreateNewEmployee}
              loading={loading}
            >
              Save
            </Button>
          </Box>
        }
        pageName="New Employee"
        breadcrumb={[
          {
            label: "Employee",
            link: ROUTERS.EMPLOYEE,
          },
          {
            label: "New",
          },
        ]}
      >
        <Box
          sx={{
            display: "flex",
            py: 1,
          }}
        >
          <AspectRatio
            ratio="1"
            maxHeight={200}
            sx={{ flex: 1, minWidth: 100, borderRadius: "100%", mr: 3 }}
          >
            <Avatar variant="outlined" size="sm" />
          </AspectRatio>
          <Grid container spacing={3}>
            <Grid sm={6}>
              <FormControl>
                <BaseInput
                  label="Name"
                  value={newEmployee.name}
                  onChange={(e) =>
                    setNewEmployee({ ...newEmployee, name: e.target.value })
                  }
                />
              </FormControl>
            </Grid>

            <Grid sm={6}>
              <FormControl>
                <BaseSelect
                  label="Role"
                  options={roleList.map((v) => ({
                    label: v.name,
                    value: v.id,
                  }))}
                  value={newEmployee.roles?.length ? newEmployee.roles[0] : ""}
                  onChange={(e) =>
                    setNewEmployee({ ...newEmployee, roles: e ? [e] : [] })
                  }
                />
              </FormControl>
            </Grid>

            <Grid sm={6}>
              <FormControl>
                <BaseInput
                  label="Username"
                  value={newEmployee.username}
                  onChange={(e) =>
                    setNewEmployee({ ...newEmployee, username: e.target.value })
                  }
                />
              </FormControl>
            </Grid>
            <Grid sm={6}>
              <FormControl>
                <BaseInput
                  label="Password"
                  type="password"
                  id="password"
                  name="password"
                  autoComplete="new-password"
                  value={newEmployee.password}
                  onChange={(e) =>
                    setNewEmployee({ ...newEmployee, password: e.target.value })
                  }
                />
              </FormControl>
            </Grid>

            <Grid sm={6}>
              <FormControl>
                <BaseInput
                  label="Email"
                  value={newEmployee.email}
                  onChange={(e) =>
                    setNewEmployee({ ...newEmployee, email: e.target.value })
                  }
                />
              </FormControl>
            </Grid>
            <Grid sm={6}>
              <FormControl>
                <BaseInput
                  label="Phone"
                  value={newEmployee.phone}
                  onChange={(e) =>
                    setNewEmployee({ ...newEmployee, phone: e.target.value })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </PageWrapper>
    </>
  );
}
