
import { Box } from "@mui/joy";
import { InvoiceStatusEnum } from "app-constants";
import { IInvoice } from "types/invoice";
import PaymentHistoryList from "./PaymentHistoryList";

export default function PaymentHistory({
  invoiceDetail,
}: {
  invoiceDetail: IInvoice;
}) {
  return (
    <Box sx={{ flex: 1 }}>
      {!!invoiceDetail?.invoice?.status &&
        [InvoiceStatusEnum.PARTIALLY_PAID, InvoiceStatusEnum.PAID].includes(
          invoiceDetail?.invoice?.status as any
        ) && <PaymentHistoryList invoiceDetail={invoiceDetail} />}
    </Box>
  );
}
