import { Grid, Typography } from "@mui/joy";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import { unwrapResult } from "@reduxjs/toolkit";
import { PAYMENT_METHODS } from "app-constants";
import BaseCurrencyInput from "components/BaseCurrencyInput";
import BaseDatePicker from "components/BaseDatePicker";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import BaseSelect from "components/BaseSelect";
import ConfirmUtils from "components/ConfirmUtils";
import { useState } from "react";
import { useAppDispatch } from "redux-store";
import { createSODeposit } from "redux-store/reducers/sales-order";
import { IDepositsItem } from "types/invoice";
import { ISalesOrder } from "types/sales-order";
import { calcGrantTotal, showCurrency } from "utils/common";
import notification from "utils/notification";

type TCreateSalesOrderModal = {
  isOpen: boolean;
  onClose: () => void;
  onUpdateSuccess: () => void;
  salesOrderDetail: ISalesOrder;
  detail: IDepositsItem;
};

export default function CreateDeposit({
  isOpen,
  onClose,
  onUpdateSuccess,
  salesOrderDetail,
  detail,
}: TCreateSalesOrderModal) {
  const [loading, setLoading] = useState(false);
  const [depositItem, setDepositItem] = useState<IDepositsItem | null>(
    detail.id
      ? detail
      : {
        amount: 0,
        percent: 0,
        deposit_date: "",
        deposit_transaction: "",
        deposit_method: "",
        note: "",
        receive_bank: "",
      }
  );
  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    onClose();
  };

  const onCreateClick = async () => {
    if (depositItem?.id) {
      if (!(await ConfirmUtils.sure("Receive deposit?"))) {
        return;
      }
    }

    setLoading(true);

    let sendObj: any = {
      so_id: salesOrderDetail.id,
      ...depositItem,
    };
    delete sendObj.created_at;
    delete sendObj.status;
    delete sendObj.updated_at;
    delete sendObj.user_id;
    delete sendObj.deposit_type;

    if (!depositItem?.id) {
      sendObj = {
        so_id: salesOrderDetail.id,
        note: depositItem?.note,
        amount: depositItem?.amount,
        percent: depositItem?.percent,
      };
    }

    dispatch(createSODeposit(sendObj))
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        if (response.apiStatus) {
          onUpdateSuccess();
          notification.success(
            `${detail.id ? "Receive" : "Request"} deposit successfully`
          );
        } else {
          notification.error(
            `${detail.id ? "Receive" : "Request"} deposit failed`
          );
        }
      });
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onCloseModal}
      title={detail.id ? "Receive Deposit" : "Request Deposit"}
      actions={
        <Button
          onClick={onCreateClick}
          loading={loading}
          color="primary"
        >
          {detail.id ? "Receive Deposit" : "Send Request"}
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid sm={6}>
          <FormControl>
            <BaseCurrencyInput
              is_number
              max={100}
              min={0}
              prefix="%"
              label={"Percent"}
              value={Number(depositItem?.percent)}
              onChange={(e) =>
                setDepositItem({
                  ...depositItem,
                  percent: Number(e),
                  amount: calcGrantTotal(salesOrderDetail) * Number(e) / 100,
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid sm={6}>
          <FormControl>
            <Typography>Amount: {
              showCurrency(depositItem?.amount || 0)
            }</Typography>

          </FormControl>
        </Grid>
        {!!detail.id && (
          <>
            <Grid sm={6}>
              <FormControl>
                <BaseDatePicker
                  label={"Deposit Date"}
                  value={depositItem?.deposit_date}
                  onChange={(e) =>
                    setDepositItem({
                      ...depositItem,
                      deposit_date: e,
                    })
                  }
                />
              </FormControl>
            </Grid>

            <Grid sm={12}>
              <FormControl>
                <BaseSelect
                  options={PAYMENT_METHODS.map((v) => ({
                    label: v,
                    value: v,
                  }))}
                  label="Deposit Method"
                  value={depositItem?.deposit_method}
                  onChange={(value: any) =>
                    setDepositItem({
                      ...depositItem,
                      deposit_method: value || "",
                    })
                  }
                />
              </FormControl>
            </Grid>

            <Grid sm={12}>
              <FormControl>
                <BaseInput
                  label={"Deposit Transaction"}
                  value={depositItem?.deposit_transaction}
                  onChange={(e) =>
                    setDepositItem({
                      ...depositItem,
                      deposit_transaction: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>

            <Grid sm={12}>
              <FormControl>
                <BaseInput
                  label={"Abaco Bank Account"}
                  multiline
                  minRows={2}
                  value={depositItem?.receive_bank}
                  onChange={(e) =>
                    setDepositItem({
                      ...depositItem,
                      receive_bank: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
          </>
        )}

        <Grid sm={12}>
          <FormControl>
            <BaseInput
              label={"Note"}
              multiline
              minRows={2}
              value={depositItem?.note}
              onChange={(e) =>
                setDepositItem({
                  ...depositItem,
                  note: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </BaseModal>
  );
}
