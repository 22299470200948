import { Box, Button, Typography } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import BaseInput from "components/BaseInput";
import * as ExcelJS from "exceljs";
import _ from "lodash";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux-store";
import { importProduct } from "redux-store/reducers/product";
import notification from "utils/notification";

const getNameAndSuffix = (name: string) => {
  const parts = name.split("_");
  const suffix = parts[parts.length - 1];
  const productName = _.reduce(
    parts,
    (acc, part, index) => {
      if (index === parts.length - 1) return acc;
      return `${acc} ${part}`;
    },
    ""
  );

  return [productName.trim() || suffix.trim(), suffix.trim()];
};

const getCellValue = (value: any) => {
  if (value === "#N/A") return "";
  if (typeof value === "object") return "";
  return value;
};

const ExcelToJsonConverter: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formatData, setFormatData] = useState<string>("");
  const [jsonData, setJsonData] = useState<any[] | null>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [headerColumns, setHeaderColumns] = useState<any>();
  const [file, setFile] = useState<File | null>(null);
  const [importLoading, setImportLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setFile(file);
    setError(null);
    const items: any[] = []
    const reader = new FileReader();
    reader.onloadend = (e) => {
      const arrayBuffer: any = reader.result;
      const workbook = new ExcelJS.Workbook();
      workbook.xlsx.load(arrayBuffer).then(function (workbook) {
        try {
          const worksheet = workbook.worksheets[0]
          worksheet?.eachRow((row, rowNumber) => {
            const rowValues = row.values as any[]; // Cast to any[] to access as array
            const rowArray = rowValues.slice(1); // Remove the first element (index 0) as it's usually undefined
            if (rowNumber === 1) {
              setHeaderColumns(rowArray)
            }
            if (rowNumber > 1 && row) {
              items.push(rowArray);
            }
          });
          setJsonData(items);
          setTotalItems(items.length);
        } catch (e) {
          setError("Invalid file format");
        }
      });
    };

    reader.readAsBinaryString(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      ".xls": ["application/vnd.ms-excel"],
      ".xlsx": [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
    },
  });


  // Function to check if the provided dynamicKeys is a valid object and indices are in range
  const isValidDynamicKeys = (array: any, keyMappings: any) => {
    if (typeof keyMappings !== 'object' || keyMappings === null) {
      return false; // Validate that dynamicKeys is an object
    }
    const maxLength = array[0].length; // Assume all sub-arrays have the same length
    for (const key in keyMappings) {
      const index = keyMappings[key];
      if (typeof index !== 'number' || index < 0 || index >= maxLength) {
        return false; // Ensure the index is a number and within range
      }
    }
    return true;
  };

  // Function to dynamically map array values to the provided keys
  const mapDynamicValues = (array: any, keyMappings: any) => {
    // Validate dynamicKeys
    if (!isValidDynamicKeys(array, keyMappings)) {
      notification.error('Invalid dynamicKeys object or index out of range.')
      return
    }

    return array.map((item: any) => {
      const mappedObject: any = {};
      for (const key in keyMappings) {
        mappedObject[key] = item[keyMappings[key]]; // Set each dynamic key with its corresponding value
      }
      return mappedObject;
    });
  };

  const handleImport = () => {
    if (!jsonData?.length) return;
    try {
      const dynamicKeys: any = JSON.parse(formatData)
      if (Object.keys(dynamicKeys).length < 2 || !dynamicKeys.hasOwnProperty('code')) {
        return notification.error("need 2 key and code key")
      }
      const result = mapDynamicValues(jsonData, dynamicKeys)
      if (!result) {
        return
      }
      setImportLoading(true);
      dispatch(
        importProduct({
          products_chunk: result,
        })
      )
        .then(unwrapResult)
        .then(({ response }) => {
          setImportLoading(false);
          if (response.apiStatus) {
            setImportLoading(false);
            notification.success();
          } else {
            notification.error(response.message);
          }
        });
    } catch (error: any) {
      notification.error(error.message)
    }
  };

  return (
    <div>
      <div {...getRootProps()}>
        <Box
          sx={{
            ...dropzoneStyle,
            opacity: importLoading ? 0.5 : 1,
          }}
        >
          {!importLoading && <input {...getInputProps()} />}

          {error ? (
            <Typography color="danger">{error}</Typography>
          ) : file ? (
            <p>
              <b>{file.name}</b> ({Math.floor(file.size / 1024)} kb)
            </p>
          ) : (
            <p>Drag and drop an Excel file here, or click to select one</p>
          )}
        </Box>
      </div>

      {jsonData && (
        <>
          <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
            <BaseInput
              value={formatData}
              label="Parse data"
              onChange={(e) =>
                setFormatData(e.target.value)
              }
            />
            <Button sx={{ ml: 2 }} loading={importLoading} onClick={handleImport}>
              Process Import
            </Button>
          </Box>
          <Typography level="body-sm" sx={{ mt: 1 }}>
            {(headerColumns || []).map((e: any, i: number) => {
              return <>{e} - {i} <br /></>
            })}
          </Typography>

          <Typography level="body-sm" sx={{ mt: 1 }}>
            <b>Total: {totalItems} rows</b>
          </Typography>
        </>
      )}
    </div>
  );
};

const dropzoneStyle: React.CSSProperties = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "80px 0",
  textAlign: "center",
  cursor: "pointer",
};

export default ExcelToJsonConverter;
