import Typography from "@mui/joy/Typography";

import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { Card, Grid, IconButton } from "@mui/joy";
import { BarChart, LineChart, PieChart } from "@mui/x-charts";
import PageWrapper from "components/PageWrapper";
import QuickListReport from "./QuickListReport";

const chartSetting = {
  xAxis: [
    {
      label: "rainfall (mm)",
    },
  ],
  height: 300,
};
const dataset = [
  {
    london: 59,
    paris: 57,
    newYork: 86,
    seoul: 21,
    month: "Jan",
  },
  {
    london: 50,
    paris: 52,
    newYork: 78,
    seoul: 28,
    month: "Fev",
  },
  {
    london: 47,
    paris: 53,
    newYork: 106,
    seoul: 41,
    month: "Mar",
  },
  {
    london: 54,
    paris: 56,
    newYork: 92,
    seoul: 73,
    month: "Apr",
  },
  {
    london: 57,
    paris: 69,
    newYork: 92,
    seoul: 99,
    month: "May",
  },
  {
    london: 60,
    paris: 63,
    newYork: 103,
    seoul: 144,
    month: "June",
  },
  {
    london: 59,
    paris: 60,
    newYork: 105,
    seoul: 319,
    month: "July",
  },
  {
    london: 65,
    paris: 60,
    newYork: 106,
    seoul: 249,
    month: "Aug",
  },
  {
    london: 51,
    paris: 51,
    newYork: 95,
    seoul: 131,
    month: "Sept",
  },
  {
    london: 60,
    paris: 65,
    newYork: 97,
    seoul: 55,
    month: "Oct",
  },
  {
    london: 67,
    paris: 64,
    newYork: 76,
    seoul: 48,
    month: "Nov",
  },
  {
    london: 61,
    paris: 70,
    newYork: 103,
    seoul: 25,
    month: "Dec",
  },
];

const valueFormatter = (value: number) => `${value}mm`;

function HorizontalBars() {
  return (
    <BarChart
      dataset={dataset}
      yAxis={[{ scaleType: "band", dataKey: "month" }]}
      series={[{ dataKey: "seoul", label: "Seoul rainfall", valueFormatter }]}
      layout="horizontal"
      {...chartSetting}
    />
  );
}

export default function DashboardPage() {
  return (
    <PageWrapper
      pageName="Dashboard"
      breadcrumb={[
        {
          label: "Dashboard",
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid sm={6} xs={12}>
          <QuickListReport data={{ title: "Open Actions" }}></QuickListReport>
        </Grid>
        <Grid sm={6} xs={12}>
          <Card>
            <div>
              <Typography level="title-lg">Top Products</Typography>
              <Typography level="body-sm">April 24 to May 02, 2021</Typography>
              <IconButton
                aria-label="bookmark Bahamas Islands"
                variant="plain"
                color="neutral"
                size="sm"
                sx={{ position: "absolute", top: "0.875rem", right: "0.5rem" }}
              >
                <MoreHorizRoundedIcon />
              </IconButton>
            </div>
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: 10, label: "series A" },
                    { id: 1, value: 15, label: "series B" },
                    { id: 2, value: 20, label: "series C" },
                  ],
                },
              ]}
              height={300}
            />
          </Card>
        </Grid>
        <Grid sm={6} xs={12}>
          <Card>
            <div>
              <Typography level="title-lg">Sales Order</Typography>
              <Typography level="body-sm">April 24 to May 02, 2021</Typography>
              <IconButton
                aria-label="bookmark Bahamas Islands"
                variant="plain"
                color="neutral"
                size="sm"
                sx={{ position: "absolute", top: "0.875rem", right: "0.5rem" }}
              >
                <MoreHorizRoundedIcon />
              </IconButton>
            </div>
            <BarChart
              height={300}
              xAxis={[
                { scaleType: "band", data: ["group A", "group B", "group C"] },
              ]}
              series={[
                { data: [4, 3, 5] },
                { data: [1, 6, 3] },
                { data: [2, 5, 6] },
              ]}
            />
          </Card>
        </Grid>
        <Grid sm={6} xs={12}>
          <Card>
            <div>
              <Typography level="title-lg">Top Products</Typography>
              <Typography level="body-sm">April 24 to May 02, 2021</Typography>
              <IconButton
                aria-label="bookmark Bahamas Islands"
                variant="plain"
                color="neutral"
                size="sm"
                sx={{ position: "absolute", top: "0.875rem", right: "0.5rem" }}
              >
                <MoreHorizRoundedIcon />
              </IconButton>
            </div>
            <LineChart
              height={300}
              xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
              series={[
                {
                  data: [2, 5.5, 2, 8.5, 1.5, 5],
                },
              ]}
            />
          </Card>
        </Grid>

        <Grid sm={6} xs={12}>
          <Card>
            <div>
              <Typography level="title-lg">Product</Typography>
              <Typography level="body-sm">April 24 to May 02, 2021</Typography>
              <IconButton
                aria-label="bookmark Bahamas Islands"
                variant="plain"
                color="neutral"
                size="sm"
                sx={{ position: "absolute", top: "0.875rem", right: "0.5rem" }}
              >
                <MoreHorizRoundedIcon />
              </IconButton>
            </div>
            <HorizontalBars />
          </Card>
        </Grid>
      </Grid>
    </PageWrapper >
  );
}
