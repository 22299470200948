import { Box, Button } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { DELIVERY_METHOD, SOStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import CustomerView from "components/CustomerView";
import FilterDrawer, { IFilterItem } from "components/FilterDrawer";
import PageWrapper from "components/PageWrapper";
import { ViewDetailModalWrapper } from "components/ViewDetailModal";
import _ from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { savePageFilter } from "redux-store/reducers/common";
import { getListCustomer } from "redux-store/reducers/customer";
import { getListSalesOrder } from "redux-store/reducers/sales-order";
import ROUTERS from "routers/constants";
import { calcGrantTotal, formatDate, showCurrency } from "utils/common";
import useOpenDetailPopup from "utils/common-hooks/useOpenDetailPopup";
import { getPermission } from "utils/roles";

export default function SalesOrderListPage() {
  const userMap = useAppSelector((state) => state.employee.userMap);
  const filterValue = useAppSelector((state) => state.common.searchFilter.SO_LIST)
  const productItems =
    useAppSelector((state) => state.product.productItems) || [];

  const salesOrderList = useAppSelector((state) => state.salesOrder.list);
  const customerList = useAppSelector((state) => state.customer.list);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleGetListSalesOrder = () => {
    dispatch(getListSalesOrder({ backorder: "no", ...filterValue }));
  };
  useEffect(() => {
    dispatch(getListCustomer({}));
  }, []);

  const {
    openDetailPopup,
  } = useOpenDetailPopup({
    type: 'SO'
  })

  useEffect(() => {
    handleGetListSalesOrder();
  }, [filterValue])

  const filterFields: IFilterItem[] = [
    {
      label: "Sales Order / Customer PO",
      type: "texts",
      key: "textSearch",
      placeholder: "Type Sales Order, Customer PO",
    },
    {
      label: "Customers",
      placeholder: "Select customer",
      type: "multiple-select",
      key: "customers",
      options: _.map(customerList, (cus) => ({ label: cus.first_name, value: cus.id })),
    },
    {
      label: "Ship Zip Code",
      placeholder: "Type multi zip code",
      type: "multiple-select",
      freeSolo: true,
      key: "zip_codes",
    },
    {
      label: "Items",
      placeholder: "Select item",
      type: "multiple-select",
      key: "items",
      options: _.map(productItems, (cus) => ({ label: cus.code, value: cus.code })),
    },
    {
      label: "Status",
      placeholder: "Select status",
      type: "multiple-select",
      key: "statuses",
      options: [
        SOStatusEnum.IN_REVIEW,
        SOStatusEnum.AWAITING_MANUFACTURING,
        SOStatusEnum.READY_TO_PICK,
        SOStatusEnum.READY_TO_PACK,
        SOStatusEnum.READY_TO_SHIP,
        SOStatusEnum.SHIPPED,
        SOStatusEnum.COMPLETED,
        SOStatusEnum.CANCELLED,
      ].map(e => ({
        label: e,
        value: e
      }))
    },
    {
      label: "Ship Methods",
      placeholder: "Select method",
      type: "multiple-select",
      key: "methods",
      options: DELIVERY_METHOD.map(e => ({
        label: e,
        value: e
      }))
    },
    {
      label: "Shipped Date",
      placeholder: "Shipped Date",
      type: "date",
      key: "ship_date",
    },
  ];
  return (
    <>
      <PageWrapper
        pageAction={
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}>
            {getPermission('ALLOW_CREATE_SO') && <Button size="sm" onClick={() => navigate(ROUTERS.SALES_ORDER_NEW)}>
              Create
            </Button>}
            <FilterDrawer
              filters={filterFields}
              value={filterValue}
              onChange={(e) => {
                dispatch(savePageFilter({
                  name: 'SO_LIST',
                  data: e,
                }))
              }}
            />
          </Box>

        }
        pageName="Sales Orders"
        breadcrumb={[
          {
            label: "Sales Orders",
          },
        ]}
      >
        <CommonList
          data={salesOrderList}
          columns={[
            {
              key: "so_number",
              label: "#",
              width: 80,
              render: (row) => <b>{row.so_number}</b>,
            },
            {
              key: "customerName",
              label: "Customer",
              render: (row) => <CustomerView customer={row.customer as any} />,
              rawValue: (row) =>
                `${row.customer?.first_name} ${row.customer?.last_name} ${row.customer?.email} ${row.customer?.cus_number}`,
            },
            {
              key: "saleRep",
              label: "Sales Rep",
              rawValue: (row) => row.user_id && userMap[row.user_id]?.name,
            },
            {
              key: "name",
              label: "Customer PO",
              render: (row) => <Typography>{row.name}</Typography>,
            },
            {
              key: "total",
              label: "Total",
              width: 130,
              render: (row) => (
                <Typography>{showCurrency(calcGrantTotal(row))}</Typography>
              ),
            },
            {
              key: "created_at",
              label: "Created At",
              width: 120,
              render: (row) => (
                <Typography>{formatDate(row.created_at)}</Typography>
              ),
            },
            {
              key: "ship_date",
              label: "Shipped",
              width: 120,
              render: (row) => (
                <Typography>
                  {row.ship_date ? formatDate(row.ship_date) : '-'}
                </Typography>
              ),
            },
            {
              key: "delivery_method",
              label: "Ship",
              width: 120,
            },
            {
              key: "ship_zip_code",
              label: "Zip Code",
              width: 120,
              rawValue: (row) => row.shipping_address?.zip_code,
            },
            {
              key: "status",
              label: "Status",
              width: 120,
              render: (row) => <ChipStatus status={row.status} size="small" />,
            },

          ]}
          searchKeys={["so_number", "customerName", "name"]}
          sortKeys={["created_at", "so_number", "status", "delivery_method", 'ship_date', 'created_at', 'customerName', 'total', 'name', 'saleRep', 'ship_zip_code']}
          onRowClick={(row) => openDetailPopup(row.id)}
        />
      </PageWrapper>
      <ViewDetailModalWrapper />
    </>
  );
}
