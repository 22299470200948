import { Box, Button, Typography } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { FreightQuoteStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import EditHoverPopup from "components/EditHoverPopup";
import InlineEditModal from "components/InlineEditModal";
import PageWrapper from "components/PageWrapper";
import { DetailSkeleton } from "components/SkeletonComponents";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  getDetailFreightQuote,
  updateFreightQuote,
} from "redux-store/reducers/freight-quote";
import ROUTERS from "routers/constants";
import { IFreightQuote } from "types/freight-quote";
import { formatDate } from "utils/common";
import notification from "utils/notification";
import DetailContent from "./components/DetailContent";

export default function FreightQuoteDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const id = useParams()?.id || detailId;
  const [provideLoading, setProvideLoading] = useState(false);
  const freightQuoteFromState = useAppSelector((state) =>
    state.freightQuote.list?.find((freightQuote) => freightQuote.id === id)
  );
  const [freightQuoteDetail, setFreightQuoteDetail] = useState<
    IFreightQuote | undefined
  >(freightQuoteFromState);

  const dispatch = useAppDispatch();

  const fetchDetail = () => {
    if (!id) return;
    dispatch(
      getDetailFreightQuote({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.id) setFreightQuoteDetail(response.data);
      });
  };

  useEffect(() => {
    fetchDetail();
  }, [id]);

  const handleProvideCost = ({ shipping_cost }: { shipping_cost: number }) => {
    setProvideLoading(true);
    dispatch(updateFreightQuote({ id, shipping_cost: shipping_cost }))
      .then(unwrapResult)
      .then(({ response }) => {
        setProvideLoading(false);
        if (response.apiStatus) {
          notification.success();
          fetchDetail();
        } else {
          notification.error();
        }
      });
  };

  return (
    <>
      <PageWrapper
        pageName={
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography>
              Freight Quote
              <br />
              <Typography sx={{ fontSize: 14, fontWeight: 300 }}>
                Date: {formatDate(freightQuoteDetail?.created_at, true)}
              </Typography>
            </Typography>
            {!!freightQuoteDetail && (
              <ChipStatus status={freightQuoteDetail?.status} size="medium" />
            )}
          </Box>
        }
        pageAction={
          !!freightQuoteDetail?.id && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <EditHoverPopup
                renderButton={(onClick) => (
                  <Button
                    onClick={onClick}
                    size="sm"
                    variant="plain"
                    loading={provideLoading}
                  >
                    Edit Carrier
                  </Button>
                )}
                title="Edit Carrier"
                editBtnPosition={{}}
                editFields={[
                  {
                    label: "Carrier Name",
                    key: "carrier_name",
                    value: freightQuoteDetail?.carrier_name,
                    type: "text",
                  },
                  {
                    label: "Carrier Quote",
                    key: "carrier_cost",
                    value: freightQuoteDetail?.carrier_cost,
                    type: "money",
                  },
                ]}
                onSave={(newData, closeEdit) => {
                  dispatch(
                    updateFreightQuote({
                      id: freightQuoteDetail.id,
                      ...newData,
                    })
                  )
                    .then(unwrapResult)
                    .then(({ response }) => {
                      if (response.apiStatus) {
                        notification.success();
                        closeEdit();
                        fetchDetail();
                      } else {
                        notification.error();
                      }
                    });
                }}
              />
              {[
                FreightQuoteStatusEnum.SUBMITTED,
                FreightQuoteStatusEnum.CREATED,
              ].includes(freightQuoteDetail?.status as any) && (
                  <InlineEditModal
                    label="Customer Quote"
                    placement="right-start"
                    saveText="Save"
                    type="currency"
                    onSave={(shipping_cost, closeEdit) => {
                      handleProvideCost({
                        shipping_cost,
                      });
                      closeEdit();
                    }}
                  >
                    <Button size="sm" loading={provideLoading}>
                      Provide Quote
                    </Button>
                  </InlineEditModal>
                )}
            </Box>
          )
        }
        breadcrumb={[
          {
            label: "Freight Quote",
            link: ROUTERS.FREIGHT_QUOTE,
          },
          {
            label: "Detail",
          },
        ]}
        skeletonLoading={!freightQuoteDetail ? <DetailSkeleton /> : undefined}
        tabs={[
          {
            label: "Detail",
            content: !!freightQuoteDetail && (
              <DetailContent
                freightQuote={freightQuoteDetail}
                onUpdateSuccess={() => {
                  fetchDetail();
                }}
              />
            ),
          },
        ]}
      />
    </>
  );
}
