import { ClearOutlined, MoreVertOutlined } from "@mui/icons-material";
import { Divider, FormControl, Grid, IconButton, Stack } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import { unwrapResult } from "@reduxjs/toolkit";
import { ITEM_TYPE_VALUE } from "app-constants";
import BaseCurrencyInput from "components/BaseCurrencyInput";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import BaseNumberInput from "components/BaseNumberInput";
import BaseSelect from "components/BaseSelect";
import CardWithLabel from "components/CardWithLabel";
import ConfirmUtils from "components/ConfirmUtils";
import PageWrapper from "components/PageWrapper";
import { DetailSkeleton } from "components/SkeletonComponents";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  createProductItem,
  getDetailProduct,
} from "redux-store/reducers/product";
import ROUTERS from "routers/constants";
import { IProductItem, IProductWithId } from "types/product";
import { generateItemsDetail } from "utils/common";
import notification from "utils/notification";
import CreateBOMItems from "./components/CreateBOMItems";
import ModalUpdateCustomFields from "./components/ModalUpdateCustomFields";
import { ItemTypeOptions } from "./new";

export default function ProductDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const id = useParams()?.id || detailId;
  const [loading, setLoading] = useState(false);
  const [openAddNewItemModal, setOpenAddNewItemModal] =
    useState<IProductItem>();
  const productFromState = useAppSelector((state) =>
    state.product.list?.find((product) => product.id === id)
  );
  const [productDetail, setProductDetail] = useState<
    IProductWithId | undefined
  >(productFromState);
  const [selectedItemCusField, setSelectedItemCusField] =
    useState<IProductItem | null>(null);
  const [productItems, setProductItems] = useState<IProductItem[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [itemType, setItemType] = useState("y");
  const [itemComp, setItemComp] = useState(1);
  const [subItems, setSubItems] = useState<{
    sub_id: string;
    qty: number;
  }[]>([]);

  const fetchDetail = () => {
    if (!id) return;
    dispatch(
      getDetailProduct({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.id) setProductDetail(response.data);
      });
  }
  useEffect(() => {
    if (!id) return;
    fetchDetail();
  }, [id]);

  useEffect(() => {
    if (!productDetail?.id) return;
    const itemTypeF = productDetail && productDetail?.items?.length ? productDetail?.items[0]?.type as string : 'y'
    const itemComP = productDetail && productDetail?.items?.length && productDetail?.items[0]?.compo ? productDetail?.items[0]?.compo : 1
    setItemComp(itemComP)
    setItemType(itemTypeF)
    setProductItems(generateItemsDetail(productDetail?.items || []));

    if (itemTypeF === ITEM_TYPE_VALUE.bom) {
      setSubItems(productDetail?.items?.[0]?.sub_items as any || []);
    }



  }, [productDetail]);

  const handleUpdateProductItems = () => {
    if (!productDetail?.id) return;
    setLoading(true);
    dispatch(
      createProductItem({
        id: productDetail?.id,
        items: productItems,
        itemType,
        itemComp,
        name: productDetail?.name,
        description: productDetail?.description,
        sub_items: subItems.map(v => ({
          sub_id: v.sub_id,
          qty: v.qty
        })),
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        if (response.apiStatus) {
          fetchDetail();
          notification.success();
        } else {
          notification.error();
        }
      });
  };

  const handleOpenCustomFieldsModal = (item: IProductItem) => {
    setSelectedItemCusField(item);
  };
  return (
    <>
      <PageWrapper
        pageAction={
          <Box>
            <Button
              sx={{ marginRight: 1 }}
              variant="plain"
              size="sm"
              onClick={() => navigate(ROUTERS.PRODUCT)}
              disabled={loading}
            >
              Cancel
            </Button>
            {!!productDetail && (
              <Button
                color="primary"
                size="sm"
                onClick={handleUpdateProductItems}
                loading={loading}
                disabled={
                  !productDetail.name ||
                  !productItems.length ||
                  productItems.some(
                    (item) =>
                      !item.name ||
                      !item.price ||
                      !item.cost ||
                      !item.unit
                  )
                }
              >
                Update
              </Button>
            )}
          </Box>
        }
        pageName="Detail Product"
        breadcrumb={[
          {
            label: "Products",
            link: ROUTERS.PRODUCT,
          },
          {
            label: "Detail",
          },
        ]}
        skeletonLoading={!productDetail ? <DetailSkeleton /> : undefined}
      >
        {!!productDetail && (
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid sm={6}>
              <FormControl>
                <BaseInput
                  label="Name"
                  value={productDetail.name}
                  onChange={(e) =>
                    setProductDetail({
                      ...productDetail,
                      name: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid sm={4}>
              <FormControl>
                <BaseSelect
                  readOnly={true}
                  disabled
                  value={itemType}
                  label="Item Type"
                  onChange={(e) => {
                    setItemType(e)
                  }}
                  options={ItemTypeOptions}
                />
              </FormControl>
            </Grid>
            <Grid sm={2}>
              <FormControl>
                <BaseNumberInput
                  disabled={true}
                  value={itemComp}
                  min={1}
                  label="Component Number"
                  onChange={(e) => {
                    setItemComp(Number(e))
                  }}
                />
              </FormControl>
            </Grid>
            <Grid sm={12}>
              <CardWithLabel label="Variants">
                <Stack spacing={1}>
                  <Stack spacing={1}>
                    {productItems?.map((item, index) => (
                      <Box key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            container
                            spacing={1}
                            sx={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Grid sm={3}>
                              <BaseInput
                                label="Name"
                                value={item?.name}
                                onChange={(e) => {
                                  setProductItems((prev) => {
                                    const newItems = [...prev];
                                    newItems[index].name = e.target.value;
                                    return newItems;
                                  });
                                }}
                              />
                              <BaseInput
                                sx={{
                                  mt: 2,
                                }}
                                label="Code"
                                value={item?.code}
                                onChange={(e) => {
                                  setProductItems((prev) => {
                                    const newItems = [...prev];
                                    newItems[index].code = e.target.value;
                                    return newItems;
                                  });
                                }}
                              />
                            </Grid>

                            <Grid sm>
                              <FormControl>
                                <BaseCurrencyInput
                                  label="Cost"
                                  value={item?.cost}
                                  onChange={(e: any) => {
                                    setProductItems((prev) => {
                                      const newItems = [...prev];
                                      newItems[index].cost = Number(e);
                                      return newItems;
                                    });
                                  }}
                                />
                              </FormControl>
                            </Grid>

                            <Grid sm>
                              <FormControl>
                                <BaseCurrencyInput
                                  label="FOB Price"
                                  value={item?.fob_price}
                                  onChange={(e: any) => {
                                    setProductItems((prev) => {
                                      const newItems = [...prev];
                                      newItems[index].fob_price = Number(e);
                                      return newItems;
                                    });
                                  }}
                                />
                              </FormControl>
                            </Grid>

                            <Grid sm>
                              <FormControl>
                                <BaseCurrencyInput
                                  label="US WH Price"
                                  value={item?.price}
                                  onChange={(e: any) => {
                                    setProductItems((prev) => {
                                      const newItems = [...prev];
                                      newItems[index].price = Number(e);
                                      return newItems;
                                    });
                                  }}
                                />
                              </FormControl>
                            </Grid>

                            <Grid sm>
                              <FormControl>
                                <BaseCurrencyInput
                                  label="Special Price"
                                  value={item?.special_price}
                                  onChange={(e: any) => {
                                    setProductItems((prev) => {
                                      const newItems = [...prev];
                                      newItems[index].special_price = Number(e);
                                      return newItems;
                                    });
                                  }}
                                />
                              </FormControl>
                            </Grid>

                            <Grid sm>
                              <FormControl>
                                <BaseCurrencyInput
                                  label="End User Price"
                                  value={item?.regular_price}
                                  onChange={(e: any) => {
                                    setProductItems((prev) => {
                                      const newItems = [...prev];
                                      newItems[index].regular_price = Number(e);
                                      return newItems;
                                    });
                                  }}
                                />
                              </FormControl>
                            </Grid>

                            <Grid sm>
                              <FormControl>
                                <BaseInput
                                  label="Unit"
                                  value={item?.unit}
                                  onChange={(e: any) => {
                                    setProductItems((prev) => {
                                      const newItems = [...prev];
                                      newItems[index].unit = e.target.value;
                                      return newItems;
                                    });
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <IconButton
                            sx={{
                              marginLeft: 1,
                            }}
                            onClick={() => handleOpenCustomFieldsModal(item)}
                          >
                            <MoreVertOutlined />
                          </IconButton>
                          <IconButton
                            disabled={!!(item as any)?.id}
                            sx={{
                              marginLeft: 1,
                            }}
                            onClick={() => {
                              ConfirmUtils.sure().then((isOk) => {
                                if (isOk) {
                                  const newItems = [...productItems];
                                  newItems.splice(index, 1);
                                  setProductItems(newItems);
                                }
                              });
                            }}
                          >
                            <ClearOutlined />
                          </IconButton>
                        </Box>
                        <Divider
                          sx={{
                            my: 2,
                          }}
                        />
                      </Box>
                    ))}

                    {!(itemType === ITEM_TYPE_VALUE.bom && productItems.length === 1) ? <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                      }}
                    >
                      <Button
                        size="sm"
                        variant="outlined"
                        onClick={() => {
                          setOpenAddNewItemModal({
                            name: "",
                            code: "",
                            price: 0,
                            fob_price: 0,
                            cost: 0,
                            special_price: 0,
                            container_price: 0,
                            regular_price: 0,
                            unit: "",
                            custom_field: [],
                          });
                        }}
                      >
                        Add Item
                      </Button>
                    </Box> :
                      <CreateBOMItems
                        subItems={subItems}
                        setSubItems={setSubItems}
                      />
                    }
                  </Stack>
                </Stack>
              </CardWithLabel>
            </Grid>
            <Grid sm={12}>
              <FormControl>
                <BaseInput
                  multiline
                  label="Description"
                  minRows={3}
                  maxRows={5}
                  value={productDetail?.description}
                  onChange={(e) =>
                    setProductDetail({
                      ...productDetail,
                      description: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        )}

        {!!selectedItemCusField && (
          <ModalUpdateCustomFields
            isOpen={!!selectedItemCusField}
            onClose={() => setSelectedItemCusField(null)}
            onChange={(custom_field) => {
              selectedItemCusField.custom_field = custom_field;
              setProductItems([...productItems]);
            }}
            customFields={
              selectedItemCusField?.custom_field?.length
                ? selectedItemCusField?.custom_field
                : [
                  {
                    name: "",
                    value: "",
                  },
                ]
            }
          />
        )}

        {!!openAddNewItemModal && (
          <BaseModal
            title="Add Item"
            isOpen={!!openAddNewItemModal}
            onClose={() => setOpenAddNewItemModal(undefined)}
            actions={
              <Button
                size="sm"
                onClick={() => {
                  setProductItems([...productItems, openAddNewItemModal]);
                  setOpenAddNewItemModal(undefined);
                }}
                disabled={
                  !openAddNewItemModal.name ||
                  !openAddNewItemModal.code ||
                  !openAddNewItemModal.price ||
                  !openAddNewItemModal.unit
                }
              >
                Create
              </Button>
            }
          >
            <Grid container spacing={2}>
              <Grid xs={6}>
                <FormControl>
                  <BaseInput
                    label="Name"
                    value={openAddNewItemModal.name}
                    onChange={(e) => {
                      setOpenAddNewItemModal({
                        ...openAddNewItemModal,
                        name: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={6}>
                <FormControl>
                  <BaseInput
                    label="Code"
                    value={openAddNewItemModal.code}
                    onChange={(e) => {
                      setOpenAddNewItemModal({
                        ...openAddNewItemModal,
                        code: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              {itemType !== ITEM_TYPE_VALUE.bom && <>
                <Grid xs={6}>
                  <FormControl>
                    <BaseCurrencyInput
                      label="Cost"
                      value={openAddNewItemModal.cost}
                      onChange={(e) => {
                        setOpenAddNewItemModal({
                          ...openAddNewItemModal,
                          cost: Number(e),
                        });
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid xs={6}>
                  <FormControl>
                    <BaseCurrencyInput
                      label="FOB Price"
                      value={openAddNewItemModal.fob_price}
                      onChange={(e) => {
                        setOpenAddNewItemModal({
                          ...openAddNewItemModal,
                          fob_price: Number(e),
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
              </>}
              <Grid xs={6}>
                <FormControl>
                  <BaseCurrencyInput
                    label="US WH Price"
                    value={openAddNewItemModal.price}
                    onChange={(e) => {
                      setOpenAddNewItemModal({
                        ...openAddNewItemModal,
                        price: Number(e),
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={6}>
                <FormControl>
                  <BaseCurrencyInput
                    label="Special Price"
                    value={openAddNewItemModal.special_price}
                    onChange={(e) => {
                      setOpenAddNewItemModal({
                        ...openAddNewItemModal,
                        special_price: Number(e),
                      });
                    }}
                  />
                </FormControl>
              </Grid>


              <Grid xs={6}>
                <FormControl>
                  <BaseCurrencyInput
                    label="End User Price"
                    value={openAddNewItemModal.regular_price}
                    onChange={(e) => {
                      setOpenAddNewItemModal({
                        ...openAddNewItemModal,
                        regular_price: Number(e),
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={6}>
                <FormControl>
                  <BaseInput
                    label="Unit"
                    value={openAddNewItemModal.unit}
                    onChange={(e) => {
                      setOpenAddNewItemModal({
                        ...openAddNewItemModal,
                        unit: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </BaseModal>
        )}
      </PageWrapper>
    </>
  );
}
