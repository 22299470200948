import { Box } from "@mui/joy";
import BwipJs from "bwip-js";
import * as ExcelJS from "exceljs";
import _ from "lodash";
import { PDFDocument } from "pdf-lib";
import slugify from "slugify";
import { IEmployee } from "types/employee";
import { IVariants } from "types/product";
import { IPurchaseOrderItem } from "types/purchase";
import { IArea } from "types/warehouse";
import dayjs from 'utils/dayjs-timezone';

export function generateItemsDetail(originalItems: any[] = []) {
  return originalItems?.map((item: any) => {
    return {
      name: item.name,
      price: item?.price || 0,
      cost: item?.cost || 0,
      unit: item?.unit || "",
      code: item?.code || "",
      type: item?.type || "y",
      special_price: item?.special_price || 0,
      fob_price: item?.fob_price || 0,
      regular_price: item?.regular_price || 0,
      container_price: item?.container_price || 0,
      id: item?.id || "",
      custom_field: item?.custom_field || [],
    };
  });
}

export const parseAddress = (item: any) => {
  // Check each item and filter out null or undefined values
  const parts = [
    item?.address,
    item?.address2,
    item?.address3,
    item?.city,
    item?.state,
    item?.zip_code,
    item?.country
  ].filter(Boolean); // This filters out any falsy values like null, undefined, empty strings, etc.
  return parts.join(', ');
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getLocationName(area: any) {
  const t = area?.type.charAt(0).toUpperCase() + area?.type.slice(1);

  return t ? `${t} ${area.name}` : "-";
}

export function displayAreasLevel(areas: IArea[]) {
  return areas?.map((area) => getLocationName(area))?.join(" - ") || "";
}

export function findAreaLevel(
  id: any,
  list: IArea[],
  results: IArea[] = []
): any {
  const foundItem = list.find((item) => {
    return item.id === id;
  });

  if (!foundItem) {
    return results;
  }

  results = [foundItem, ...results];

  if (foundItem?.parent_id && results.length < 4) {
    return findAreaLevel(foundItem.parent_id, list, results) as any;
  }

  return results as any;
}
export function generateItemsFromVariants(
  variants: IVariants[],
  originalItems: any[] = []
) {
  // Parse option_value strings into arrays
  const newVariants = variants.map((variant) => {
    return {
      ...variant,
      option_values: variant.option_value.split(","),
    };
  });

  const combinations: any[] = [];

  function generateCombinations(
    options: any[],
    index: number,
    currentCombination: any
  ) {
    const option = options[index];

    for (const value of option.option_values) {
      const newCombination = currentCombination
        ? currentCombination + " / " + value
        : value;

      if (index < options.length - 1) {
        generateCombinations(options, index + 1, newCombination);
      } else {
        combinations.push(newCombination);
      }
    }
  }

  generateCombinations(newVariants, 0, "");

  return combinations?.map((name: string) => {
    const item = originalItems.find((item) => item.name === name);

    return {
      name,
      price: item?.price || 0,
      cost: item?.cost || 0,
      unit: item?.unit || "",
      special_price: item?.special_price || 0,
      fob_price: item?.fob_price || 0,
      container_price: item?.container_price || 0,
      id: item?.id || "",
      custom_field: item?.custom_field || [],
    };
  });
}

export const formatDate = (date: any, includeTime?: any) => {
  return dayjs(date).format(includeTime ? "MM/DD/YYYY HH:mm" : "MM/DD/YYYY");
};

export const showCurrency = (m: any) => {
  if (m === null) {
    return "-";
  }
  const num = Number(m) || 0;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(num);
};

export const calculateTotalItems = (items: any[]) => {
  return items.reduce((acc, item) => {
    return acc + (Number(item.cost || 0)) * item.quantity;
  }, 0);
};


export const calculateDiscountItems = (items: any[]) => {
  return items.reduce((acc, item) => {
    return acc + Number(item.discount || 0);
  }, 0);
};

export const displayPriceTotalItems = (items: any[]) => {
  const total = calculateTotalItems(items);

  return showCurrency(total);
};

export const calcGrantTotal = ({
  total = 0,
  tax_cost = 0,
  shipping_cost = 0,
  discount_item = 0,
  pallet_cost = 0
}: {
  total?: number;
  tax_cost?: number;
  shipping_cost?: number;
  discount_item?: number;
  pallet_cost?: number;
}) => {
  return (
    Number(total || 0) +
    Number(tax_cost || 0) +
    Number(pallet_cost || 0) +
    Number(shipping_cost || 0) -
    Number(discount_item || 0)
  );
};

export const calcGrantTotalPO = ({
  total = 0,
}: {
  total: number;
}) => {
  return Number(total || 0)
};

export const showUserName = (user: IEmployee) => {
  return `${user?.name} ${user?.username}`;
};

export const showProductNameString = (item: any, showCode?: boolean) => {
  const parts = [];

  if (item?.product?.name) {
    parts.push(item.product.name);
  }
  if (item?.name && item.name.trim() !== item.product.name.trim()) {
    parts.push(item.name);
  }
  if (showCode && item?.code) {
    parts.push(item.code);
  }
  return parts.join(" - ");
}

export const showProductItemName = (item: any, showCode?: boolean) => {
  if (!showCode) {
    return showProductNameString(item);
  }

  return (
    <>

      {!!item?.code && (
        <>
          <Box sx={{ fontSize: 16 }} ><strong>{item?.code}</strong></Box>
        </>
      )}
      <small>{showProductNameString(item)}</small>

    </>
  );
};

export const showCustomerName = (item: any) => {
  return `${item?.first_name}`;
};

export const showFileSize = (size?: number) => {
  if (!size) {
    return 0;
  }
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const num = (size / Math.pow(1024, i)).toFixed(2);
  const unit = ["B", "KB", "MB", "GB", "TB"][i];
  return `${num} ${unit}`;
};

export function compareVersions(version1: string, version2: string): number {
  const v1parts = version1.slice(1).split(".").map(Number);
  const v2parts = version2.slice(1).split(".").map(Number);

  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] === v2parts[i]) {
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  return v1parts.length !== v2parts.length ? -1 : 0;
}

export const printSerialBarCodeFromArrays = async (codes: string[]) => {
  const pdfDoc = await PDFDocument.create();
  let page: any;
  let idx = 0;
  for (const code of codes) {
    const barcodeString = BwipJs.toSVG({
      bcid: "code128",
      text: code,
      height: 20,
      includetext: true,
      textxalign: "left",
      paddingwidth: 2,
      paddingheight: 2,
    });

    const svgBlob = new Blob([barcodeString], { type: "image/svg+xml" });
    const svgUrl = URL.createObjectURL(svgBlob);
    const img = new Image();
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    img.onload = async () => {
      img.width = 320 * 3;
      img.height = 108 * 3;
      canvas.width = img.width;
      canvas.height = img.height;

      // Clear canvas
      context?.clearRect(0, 0, canvas.width, canvas.height);
      // Draw image on canvas
      context?.drawImage(img, 0, 0, img.width, img.height);

      // Convert canvas to PNG image data with maximum quality
      const imageData = canvas.toDataURL("image/png", 1.0);
      page = pdfDoc.addPage();
      const image = await pdfDoc.embedPng(imageData);
      const { width, height } = image.scale(1);
      page.setSize(width, height);

      page.drawImage(image, {
        x: 0,
        y: 0,
        width: width,
        height: height,
      });

      ++idx;

      if (idx === codes.length) {
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = 'barcodes.pdf';
        // a.click();
        // URL.revokeObjectURL(url);
      }
    };

    img.src = svgUrl;
  }
};

export const exportBarCodeCSV = async (codes: any[], name: string = '') => {
  // Create a new workbook and a worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Barcode-data");

  worksheet.getCell(`A1`).value = "Barcode Data";
  worksheet.getColumn("A").width = 20;
  worksheet.getCell(`B1`).value = "Code";
  worksheet.getColumn("B").width = 15;

  // Add your data to the worksheet
  codes.forEach(({ code, serial_number }, index) => {
    worksheet.getCell(`A${index + 2}`).value = serial_number;
    worksheet.getCell(`B${index + 2}`).value = code;
  });

  const buffer = await workbook.xlsx.writeBuffer();

  // Create a Blob from the buffer and create a URL for it
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);

  // Create a link element and click it to start the download
  const link = document.createElement("a");
  link.href = url;

  if (name) {
    link.download = name + codes[0].code + ".xlsx";
  } else {
    link.download = 'Serial_Number_of_' + codes[0].code + ".xlsx";
  }

  link.click();

  // Release the URL
  URL.revokeObjectURL(url);
};


export const exportLotBarCodeCSV = async (codes: any[]) => {
  // Create a new workbook and a worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Barcode-data");

  worksheet.getCell(`A1`).value = "Barcode Data";
  worksheet.getColumn("A").width = 20;
  worksheet.getCell(`B1`).value = "Code";
  worksheet.getColumn("B").width = 15;

  // Add your data to the worksheet
  codes.forEach(({ code, lot_number }, index) => {
    worksheet.getCell(`A${index + 2}`).value = lot_number;
    worksheet.getCell(`B${index + 2}`).value = code;
  });

  const buffer = await workbook.xlsx.writeBuffer();

  // Create a Blob from the buffer and create a URL for it
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);

  // Create a link element and click it to start the download
  const link = document.createElement("a");
  link.href = url;
  link.download = 'Lot_Number_of_' + codes[0].code + ".xlsx";
  link.click();

  // Release the URL
  URL.revokeObjectURL(url);
};

export const exportInvertory = async (items: any[], columns: any[], arrayColumns: any[], cb: void) => {
  // Create a new workbook and a worksheet
  const name = "Inventory_Data"
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(name);

  const exlColumn = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M']

  const newcolumns: any[] = []
  arrayColumns.forEach(e => {
    if (columns.includes(e.key)) {
      newcolumns.push(e.key)
    }
  })
  const getName = (key: any) => {
    const d = _.find(arrayColumns, { key })
    return d ? d.name : "no column"
  }

  const getValue = (key: any, item: any) => {
    if (key === 'code') {
      return item.code
    }

    if (key === 'price') {
      return item.price
    }

    if (key === 'cost') {
      return item.cost
    }

    if (key === 'special_price') {
      return item.special_price
    }

    if (key === 'avail') {
      return item.available
    }

    if (key === 'instock') {
      return item.quantity
    }

    if (key === 'in_transit') {
      return item.in_transit
    }

    if (key === 'backorder') {
      return item.backorder
    }

    if (key === 'progress') {
      return item.progress
    }

    if (key === 'money') {
      return item.quantity * item.cost
    }
  }

  newcolumns.forEach((c, i) => {
    worksheet.getCell(`${exlColumn[i]}1`).value = getName(c);
    worksheet.getCell(`${exlColumn[i]}1`).font = { bold: true };
  })

  // Add your data to the worksheet
  items.forEach((item, index) => {
    newcolumns.forEach((c, i) => {
      const cell = worksheet.getCell(`${exlColumn[i]}${index + 2}`);
      cell.value = getValue(c, item);
      if (['money', 'price', 'special_price', 'cost'].includes(c)) {
        cell.numFmt = '"$"#,##0.00;[Red]\-"$"#,##0.00';
      }
    });
  });


  const buffer = await workbook.xlsx.writeBuffer();

  // Create a Blob from the buffer and create a URL for it
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);

  // Create a link element and click it to start the download
  const link = document.createElement("a");
  link.href = url;
  link.download = name + '_' + new Date().toLocaleDateString() + ".xlsx";
  link.click();
  // Release the URL
  URL.revokeObjectURL(url);
};


export const exportPOExcel = async (po: any, priceView: boolean) => {
  // Create a new workbook and a worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("PO Items");

  worksheet.getCell(`A1`).value = "#";
  worksheet.getColumn("A").width = 5;
  worksheet.getCell(`B1`).value = "Code";
  worksheet.getColumn("B").width = 15;

  worksheet.getCell(`C1`).value = "Description";
  worksheet.getColumn("C").width = 30;

  worksheet.getCell(`D1`).value = "Qty";
  worksheet.getColumn("D").width = 12;

  worksheet.getCell(`E1`).value = "Unit";
  worksheet.getColumn("E").width = 9;

  if (priceView) {
    worksheet.getCell(`F1`).value = "Unit Cost";
    worksheet.getColumn("F").width = 15;
    worksheet.getCell(`G1`).value = "Total";
    worksheet.getColumn("G").width = 15;
  }



  // Add your data to the worksheet
  const items: IPurchaseOrderItem[] = po.purchase_order_items
  items.forEach((lineItem, index) => {
    worksheet.getCell(`A${index + 2}`).value = index + 1;
    worksheet.getCell(`B${index + 2}`).value = lineItem?.item?.code;
    worksheet.getCell(`C${index + 2}`).value = showProductItemName(lineItem.item, false) as string;
    worksheet.getCell(`D${index + 2}`).value = lineItem?.quantity;
    worksheet.getCell(`E${index + 2}`).value = lineItem?.item?.unit;

    if (priceView) {
      worksheet.getCell(`F${index + 2}`).value = lineItem?.cost;
      worksheet.getCell(`G${index + 2}`).value = lineItem?.total;
    }

  });

  const buffer = await workbook.xlsx.writeBuffer();

  // Create a Blob from the buffer and create a URL for it
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);

  // Create a link element and click it to start the download
  const link = document.createElement("a");
  link.href = url;
  link.download = "Items_of_PO_Number_" + po.po_number + ".xlsx";
  link.click();

  // Release the URL
  URL.revokeObjectURL(url);
};


export const exportProductItems = async (data: any) => {
  // Create a new workbook and a worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("ABACO PRODUCT LIST");

  worksheet.getCell(`A1`).value = "#";
  worksheet.getColumn("A").width = 5;
  worksheet.getCell(`B1`).value = "Code";
  worksheet.getColumn("B").width = 25;

  worksheet.getCell(`C1`).value = "Description";
  worksheet.getColumn("C").width = 40;

  worksheet.getCell(`D1`).value = "Cost";
  worksheet.getColumn("D").width = 20;

  worksheet.getCell(`E1`).value = "FOB_Price";
  worksheet.getColumn("E").width = 20;

  worksheet.getCell(`F1`).value = "US_WH_Price";
  worksheet.getColumn("F").width = 20;

  worksheet.getCell(`G1`).value = "Special_Price";
  worksheet.getColumn("G").width = 20;

  worksheet.getCell(`H1`).value = "End_User_Price";
  worksheet.getColumn("H").width = 20;

  worksheet.getCell(`I1`).value = "Weight";
  worksheet.getColumn("I").width = 20;

  worksheet.getCell(`J1`).value = "Length";
  worksheet.getColumn("J").width = 20;

  worksheet.getCell(`K1`).value = "Width";
  worksheet.getColumn("K").width = 20;

  worksheet.getCell(`L1`).value = "Height";
  worksheet.getColumn("L").width = 20;

  worksheet.getCell(`M1`).value = "CBM";
  worksheet.getColumn("M").width = 20;

  worksheet.getCell(`N1`).value = "Unit";
  worksheet.getColumn("N").width = 15;


  let index = 0
  // Add your data to the worksheet
  data.forEach((item: any, _: number) => {
    worksheet.getCell(`A${index + 2}`).value = index + 1;
    worksheet.getCell(`B${index + 2}`).value = item?.code;
    worksheet.getCell(`C${index + 2}`).value = showProductNameString(item);
    worksheet.getCell(`D${index + 2}`).value = item?.cost;
    worksheet.getCell(`E${index + 2}`).value = item?.fob_price;
    worksheet.getCell(`F${index + 2}`).value = item.price;
    worksheet.getCell(`G${index + 2}`).value = item.special_price;
    worksheet.getCell(`H${index + 2}`).value = item.regular_price;

    worksheet.getCell(`I${index + 2}`).value = item.weight;
    worksheet.getCell(`J${index + 2}`).value = item.length;
    worksheet.getCell(`K${index + 2}`).value = item.width;
    worksheet.getCell(`L${index + 2}`).value = item.height;
    worksheet.getCell(`M${index + 2}`).value = item.cbm;
    worksheet.getCell(`N${index + 2}`).value = item.unit;

    index += 1;
  });


  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = "ABACO_PRODUCT_LIST_" + dayjs().format("MMDDYYYY_hmmss_a") + ".xlsx";
  link.click();

  URL.revokeObjectURL(url);
};

export const exportPalletCheckListExcel = async (data: any) => {
  // Create a new workbook and a worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Pallet Checklist");

  worksheet.getCell(`A1`).value = "#";
  worksheet.getColumn("A").width = 5;
  worksheet.getCell(`B1`).value = "Code";
  worksheet.getColumn("B").width = 15;

  worksheet.getCell(`C1`).value = "Description";
  worksheet.getColumn("C").width = 50;

  worksheet.getCell(`D1`).value = "Qty";
  worksheet.getColumn("D").width = 12;

  worksheet.getCell(`E1`).value = "Unit";
  worksheet.getColumn("E").width = 9;

  worksheet.getCell(`F1`).value = "#PO";
  worksheet.getColumn("F").width = 20;

  let index = 0
  // Add your data to the worksheet
  for (const po of data.pallet_map) {
    po.items.forEach((p: any, _: number) => {
      worksheet.getCell(`A${index + 2}`).value = index + 1;
      worksheet.getCell(`B${index + 2}`).value = p?.code;
      worksheet.getCell(`C${index + 2}`).value = p.name;
      worksheet.getCell(`D${index + 2}`).value = p?.qty;
      worksheet.getCell(`E${index + 2}`).value = p?.unit;
      worksheet.getCell(`F${index + 2}`).value = po.po_number;
      index += 1;
    });
  }


  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = "Checklist_Pallet_Number_" + data.id + ".xlsx";
  link.click();

  URL.revokeObjectURL(url);
};

export const exportBackorderItem = async (data: any) => {
  // Create a new workbook and a worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Backorder Item");

  worksheet.getCell(`A1`).value = "#";
  worksheet.getColumn("A").width = 5;
  worksheet.getCell(`B1`).value = "Code";
  worksheet.getColumn("B").width = 30;

  worksheet.getCell(`C1`).value = "Quantity";
  worksheet.getColumn("C").width = 20;

  worksheet.getCell(`D1`).value = "Unit";
  worksheet.getColumn("D").width = 10;

  let index = 0
  // Add your data to the worksheet
  for (const item of data || []) {
    worksheet.getCell(`A${index + 2}`).value = index + 1;
    worksheet.getCell(`B${index + 2}`).value = item?.code;
    worksheet.getCell(`C${index + 2}`).value = item.quantity;
    worksheet.getCell(`D${index + 2}`).value = item.unit;
    index += 1;
  }

  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = "Backorder_Item" + ".xlsx";
  link.click();

  URL.revokeObjectURL(url);
};

export const getSerialDateFromSerial = (serial: string) => {
  const datePart = serial.substring(2, 8);
  const formattedDate =
    datePart.substring(0, 2) + datePart.substring(2, 4) + datePart.substring(4);

  return dayjs(formattedDate, "DDMMYY").format("MM/DD/YYYY");
};


export const exportPalletBarcode = async (code: string) => {
  const barcodeString = BwipJs.toSVG({
    bcid: "code128",
    text: code,
    height: 20,
    includetext: true,
    textxalign: "left",
    paddingwidth: 2,
    paddingheight: 2,
    backgroundcolor: "#ffffff"
  });

  const svgBlob = new Blob([barcodeString], { type: "image/svg+xml" });
  const svgUrl = URL.createObjectURL(svgBlob);
  const img = new Image();
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  img.onload = async () => {
    img.width = 320 * 10;
    img.height = 108 * 10;
    canvas.width = img.width;
    canvas.height = img.height;

    // Clear canvas
    context?.clearRect(0, 0, canvas.width, canvas.height);
    // Draw image on canvas
    context?.drawImage(img, 0, 0, img.width, img.height);

    // Convert canvas to PNG image data with maximum quality
    const imageData = canvas.toDataURL("image/jpg", 1.0);

    // Create a link element
    const link = document.createElement("a");
    link.href = imageData;
    link.download = `${code}.jpg`; // Set the download file name

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up
    URL.revokeObjectURL(svgUrl);
  };

  img.src = svgUrl;
};


export const soBarcode = async (so_number: string) => {
  const barcodeString = BwipJs.toSVG({
    bcid: "code128",
    text: so_number,
    height: 20,
    includetext: false,
    textxalign: "left",
    paddingwidth: 2,
    paddingheight: 2,
    backgroundcolor: "#ffffff"
  });

  const svgBlob = new Blob([barcodeString], { type: "image/svg+xml" });
  const svgUrl = URL.createObjectURL(svgBlob);
  const img = new Image();
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  return new Promise<string>((resolve, reject) => {
    img.onload = () => {
      img.width = 320 * 5;
      img.height = 108 * 5;
      canvas.width = img.width;
      canvas.height = img.height;

      // Clear canvas
      context?.clearRect(0, 0, canvas.width, canvas.height);
      // Draw image on canvas
      context?.drawImage(img, 0, 0, img.width, img.height);

      // Convert canvas to base64 image data
      const base64 = canvas.toDataURL("image/png", 1.0);

      // Resolve the promise with the base64 string
      resolve(base64);
    };

    img.onerror = (error) => {
      resolve("error");
    };

    img.src = svgUrl;
  });
};

export const downloadBase64Image = (base64: string, name: string) => {
  const a = document.createElement("a");
  a.href = base64;
  a.download = name;
  a.click();
  a.remove();
}

export const downloadPrintPdf = (rs: any, name: string) => {
  const url = window.URL.createObjectURL(new Blob([rs]));
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

export const slugifyCompare = (fullText?: string, searchText?: string) => {
  return slugify(_.trim(fullText))
    .toLowerCase()
    .includes(slugify(_.trim(searchText)).toLowerCase());
};

export const isOverDue = (dueDate?: string) => {
  if (!dueDate) {
    return false;
  }

  const today = dayjs();
  const due = dayjs(dueDate);

  return due.isBefore(today, "day");
}

export const isOverDueByDays = (dueDate: string, days: number) => {
  if (!dueDate) {
    return false;
  }

  const today = dayjs();
  const due = dayjs(dueDate).add(days, 'day');
  return due.isBefore(today, "day");
}

export const getOverdueStatus = (dueDate?: string) => {
  if (!dueDate) {
    return '';
  }

  const today = dayjs();
  const due = dayjs(dueDate);
  const diff = due.diff(today, "days");

  if (diff < 0) {
    return `Over due date ${Math.abs(diff)} days`;
  }

  return `Due date in ${diff} days`;
}