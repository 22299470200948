import { Box, Button } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { unwrapResult } from "@reduxjs/toolkit";
import { SOStatusEnum } from "app-constants";
import InvoiceOverdueImg from "assets/images/overdue-invoice.png";
import { ChipBackOrder, ChipStatus } from "components/ChipStatus";
import ConfirmUtils from "components/ConfirmUtils";
import Documents from "components/Documents";
import HistoryTimeline from "components/HistoryTimeline";
import MoreActions from "components/MoreActions";
import PageWrapper from "components/PageWrapper";
import { DetailSkeleton } from "components/SkeletonComponents";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { setBreadcrumb } from "redux-store/reducers/common";
import { getProductItems } from "redux-store/reducers/product";
import {
  cancelSalesOrder,
  completeOrder,
  deleteSalesOrder,
  getDetailSalesOrder,
  voidHoldOrder
} from "redux-store/reducers/sales-order";
import ROUTERS from "routers/constants";
import { printSOApi } from "services/sales-order.service";
import { ISalesOrder } from "types/sales-order";
import AppEvent from "utils/app-events";
import { calcGrantTotal, downloadPrintPdf, formatDate, soBarcode } from "utils/common";
import useListenEmitEvent from "utils/common-hooks/useListenEmitEvent";
import notification from "utils/notification";
import BackorderSOActions from "./components/BackorderSOActions";
import DepositList from "./components/DepositList";
import DetailContent from "./components/DetailContent";
import SalesOrderDetailPickPage from "./detail-pick";

export default function SalesOrderDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const id = useParams()?.id || detailId;
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Detail");
  const [actionLoading, setActionLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState('');
  const [markAsLoading, setMarkAsLoading] = useState(false);
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const salesOrderFromState = useAppSelector((state) =>
    state.salesOrder.list?.find((salesOrder) => salesOrder.id === id)
  );
  const [salesOrderDetail, setSalesOrderDetail] = useState<
    ISalesOrder | undefined
  >(salesOrderFromState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!salesOrderDetail) return;
    dispatch(setBreadcrumb([
      {
        label: salesOrderDetail?.is_back_order === 'yes' ? "Backorders" : "Sales Order",
        link: salesOrderDetail?.is_back_order === 'yes' ? ROUTERS.SALES_ORDER_BACKORDER : ROUTERS.SALES_ORDER,
      },
      {
        label: "Detail",
      },
    ]))
  }, [salesOrderDetail?.is_back_order]);

  const handleCancelOrder = async () => {
    if (!salesOrderDetail?.id) return;

    if (!(await ConfirmUtils.sure())) return;

    setActionLoading(true);
    dispatch(
      cancelSalesOrder({
        so_id: salesOrderDetail?.id as any,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success();
        } else {
          notification.error(response.message);
        }
        fetchDetail();
        setActionLoading(false);
      })
      .catch(() => {
        setActionLoading(false);
      });
  };

  const handleVoidHoldOrder = async () => {
    if (!salesOrderDetail?.id) return;

    if (!(await ConfirmUtils.sure())) return;

    setActionLoading(true);
    dispatch(
      voidHoldOrder({
        so_id: salesOrderDetail?.id as any,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success();
        } else {
          notification.error(response.message);
        }
        fetchDetail();
        setActionLoading(false);
      })
      .catch(() => {
        setActionLoading(false);
      });

  }

  const handleDeleteOrder = async () => {
    if (!salesOrderDetail?.id) return;

    if (!(await ConfirmUtils.sure())) return;

    setActionLoading(true);
    dispatch(
      deleteSalesOrder({
        so_id: salesOrderDetail?.id as any,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          navigate(ROUTERS.SALES_ORDER);
          notification.success();
        } else {
          notification.error(response.message);
        }
        fetchDetail();
        setActionLoading(false);
      })
      .catch(() => {
        setActionLoading(false);
      });

  }

  useEffect(() => {
    fetchDetail();
  }, [id]);

  useEffect(() => {
    dispatch(getProductItems({}));
  }, []);

  const fetchDetail = () => {
    if (!id) return;
    dispatch(
      getDetailSalesOrder({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.id) setSalesOrderDetail(response.data);
      });
  };

  useListenEmitEvent({
    event: AppEvent.types.FULL_SCREEN_MODAL_REFRESH,
    handle: () => {
      fetchDetail();
    },
  });

  useListenEmitEvent({
    event: AppEvent.types.SO_STATUS_CHANGE,
    matchCondition: (payload) =>
      salesOrderDetail?.id && payload.so_id == salesOrderDetail.id,
    handle: () => {
      fetchDetail();
    },
  });

  useListenEmitEvent({
    event: AppEvent.types.NOTI_PULL,
    matchCondition: (payload) => true,
    handle: () => {
      fetchDetail();
    },
  });

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <PageWrapper
          pageName={
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Typography>
                Sales Order: #{salesOrderDetail?.so_number || ""} <br />
                <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                  Date: {formatDate(salesOrderDetail?.created_at, true)}
                  <br />
                  {salesOrderDetail?.delivery_type}
                </Typography>
              </Typography>

              {!!salesOrderDetail && (
                <>
                  <ChipStatus status={salesOrderDetail.status} size="small" />
                  {salesOrderDetail?.is_back_order === "yes" &&
                    <ChipBackOrder />
                  }</>
              )}
            </Box>
          }
          pageAction={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {
                salesOrderDetail?.is_back_order === "yes" && <BackorderSOActions
                  onUpdateSuccess={() => {
                    fetchDetail();
                  }}
                  salesOrderDetail={salesOrderDetail}
                />
              }

              {!!salesOrderDetail?.so_number &&
                <>
                  <Button loading={printLoading === 'SO'} size="sm" variant="outlined" onClick={async () => {
                    setPrintLoading('SO')
                    printSOApi({
                      printType: 'SO',
                      so: {
                        ...salesOrderDetail,
                        grandTotal: calcGrantTotal(salesOrderDetail),
                      },
                      barcode: await soBarcode(salesOrderDetail?.so_number || '')
                    })
                      .then((rs: any) => {
                        if (!rs.data) {
                          notification.error()
                          setPrintLoading('')
                          return
                        }
                        downloadPrintPdf(rs.data, `so_${salesOrderDetail.so_number}.pdf`)
                        setPrintLoading('')
                      });

                  }}>
                    Print
                  </Button>



                  {[SOStatusEnum.SHIPPED].includes(salesOrderDetail?.status as SOStatusEnum) && (
                    <Button loading={printLoading === 'COMPLETE_ORDER'} size="sm" onClick={async () => {
                      if (!(await ConfirmUtils.sure())) return;
                      setPrintLoading('COMPLETE_ORDER')
                      dispatch(completeOrder({
                        id: salesOrderDetail.id
                      })).unwrap()
                        .then((rs) => {
                          setPrintLoading('')
                          if (rs.response.apiStatus) {
                            notification.success()
                            fetchDetail();
                          } else {
                            notification.error()
                          }
                        });

                    }}>
                      Order Completed
                    </Button>
                  )}
                </>
              }
              <MoreActions
                loading={actionLoading}
                actions={[

                  // {
                  //   hide: ![
                  //     SOStatusEnum.READY_TO_PICK,
                  //     SOStatusEnum.READY_TO_PACK,
                  //     SOStatusEnum.READY_TO_SHIP,
                  //   ].includes(salesOrderDetail?.status as SOStatusEnum),
                  //   label: "Void Order",
                  //   onClick: () => { handleVoidHoldOrder('void') },
                  // },

                  {
                    hide: ![
                      SOStatusEnum.READY_TO_PICK,
                      SOStatusEnum.READY_TO_PACK,
                      SOStatusEnum.READY_TO_SHIP,
                    ].includes(salesOrderDetail?.status as SOStatusEnum),
                    label: "Hold on Order",
                    onClick: () => { handleVoidHoldOrder() },
                  },

                  {
                    hide: ![
                      SOStatusEnum.ON_HOLD,
                    ].includes(salesOrderDetail?.status as SOStatusEnum),
                    label: "Resume Order",
                    onClick: () => { handleVoidHoldOrder() },
                  },

                  {
                    hide: [
                      SOStatusEnum.CANCELLED,
                      SOStatusEnum.SHIPPED,
                      SOStatusEnum.COMPLETED,
                    ].includes(salesOrderDetail?.status as SOStatusEnum),
                    disabled: [
                      SOStatusEnum.SHIPPED,
                      SOStatusEnum.COMPLETED,
                    ].includes(salesOrderDetail?.status as SOStatusEnum),
                    label: "Cancel Order",
                    onClick: handleCancelOrder,
                  },

                  {
                    hide: ![
                      SOStatusEnum.CANCELLED,
                    ].includes(salesOrderDetail?.status as SOStatusEnum),
                    label: "Delete Order",
                    onClick: handleDeleteOrder,
                  },
                ]}
              />
            </Box>
          }
          skeletonLoading={!salesOrderDetail ? <DetailSkeleton /> : undefined}
          tab={selectedTab}
          onChangeTab={setSelectedTab}
          tabs={[
            {
              label: "Detail",
              content: !!salesOrderDetail && (
                <DetailContent
                  onUpdateSuccess={() => {
                    fetchDetail();
                  }}
                  salesOrderDetail={salesOrderDetail}
                />
              ),
            },
            // {
            //   label: "Approval",
            //   content: !!salesOrderDetail && (
            //     <ApprovalContent
            //       onApproveSuccess={() => {
            //         fetchDetail();
            //       }}
            //       salesOrderDetail={salesOrderDetail}
            //       changeTabDetail={setSelectedTab}
            //     />
            //   ),
            // },

            {
              label: "Deposits",
              content: !!salesOrderDetail && (
                <DepositList
                  key={reloadTrigger}
                  salesOrderDetail={salesOrderDetail}
                  onUpdateSuccess={() => {
                    fetchDetail();
                  }}
                />
              ),
            },
            {
              label: "PPS Process",
              content: !!salesOrderDetail && (
                <SalesOrderDetailPickPage
                  isPageMode={false}
                  detailId={id}
                  key={reloadTrigger}
                  onUpdateSuccess={() => {
                    fetchDetail();
                  }}
                />
              ),
            },
            {
              label: "Documents",
              content: !!salesOrderDetail && (
                <Documents source_id={"so_" + salesOrderDetail?.id} />
              ),
            },
            {
              label: "History",
              content: !!salesOrderDetail?.id && (
                <HistoryTimeline source_id={"so_" + salesOrderDetail?.id} />
              ),
            },
          ]}
        ></PageWrapper>
        {(salesOrderDetail?.invoice_due_over_3 as number) > 0 && (
          <img
            style={{
              position: "absolute",
              top: 50,
              right: 60,
              width: 100,
              height: "auto",
              zIndex: 7,
              pointerEvents: "none",
              transform: "rotate(10deg)",
            }}
            src={InvoiceOverdueImg}
            alt="invoice overdue"
          />
        )}
      </div>
    </>
  );
}
