import ViewDetail from "components/ViewDetailModal";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const useOpenDetailPopup = ({
  type
}: {
  type: "CUSTOMER" | "INVOICE" | "SO";
}) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const detailPopupId = searchParams.get(type + "detail_popup_id");
  useEffect(() => {
    if (detailPopupId) {
      ViewDetail.show({
        type,
        id: detailPopupId,
      });
    }
  }, [detailPopupId]);
  const openDetailPopup = useCallback((id: any) => {
    setSearchParams({ [type + 'detail_popup_id']: id });
  }, []);
  return {
    openDetailPopup
  }
}

export default useOpenDetailPopup;