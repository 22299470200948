import { Add, ClearOutlined } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/joy";
import Box from "@mui/joy/Box";
import BaseNumberInput from "components/BaseNumberInput";
import BaseSelect from "components/BaseSelect";
import _ from "lodash";
import { useMemo } from "react";
import { useAppSelector } from "redux-store";
import notification from "utils/notification";

const CreateBOMItems = ({
  subItems,
  setSubItems,
}: {
  subItems: {
    sub_id: string;
    qty: number;
  }[];
  setSubItems: (items: any) => void;
}) => {
  const productItemsAll =
    useAppSelector((state) => state.product.productItems);
  const allItems = useMemo(() => {
    return _.map(productItemsAll, (item) => ({ label: item.code, value: item.id }));
  }, [productItemsAll]);

  return <Box sx={{
    width: 500
  }}>
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
    }}>
      <Typography level='title-md'>Children Items</Typography>
      <IconButton
        color="primary"
        size="sm"
        onClick={() => {
          setSubItems([...subItems, { sub_id: '', qty: 0 }]);
        }}
        variant="outlined"
      >
        <Add />
      </IconButton>
    </Box>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      mt: 2,
    }}>
      {subItems.map((item, index) => (
        <Box sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
        }}
          key={item.sub_id}
        >
          <Box flex={2}>
            <BaseSelect
              value={item.sub_id}
              label="Item"
              options={allItems}
              onChange={(e) => {
                if (subItems?.find((i) => i.sub_id === e)) {
                  return notification.error('Item already exists');
                }

                const newItems = [...subItems];

                newItems[index] = {
                  ...newItems[index],
                  sub_id: e
                };

                setSubItems(newItems);
              }}
            />
          </Box>

          <Box flex={1} display={'flex'} gap={1} alignItems={'center'}>
            <BaseNumberInput
              value={item.qty}
              label="Qty"
              onChange={(e) => {
                const newItems = [...subItems];
                newItems[index] = {
                  ...newItems[index],
                  qty: e
                };

                setSubItems(newItems);
              }}
            />

            <Tooltip title="Remove" arrow>
              <ClearOutlined
                sx={{ cursor: "pointer" }}
                fontSize="small"
                onClick={() => {
                  const newItems = [...subItems];
                  newItems.splice(index, 1);
                  setSubItems(newItems);
                }}
              />
            </Tooltip>
          </Box>
        </Box>
      ))}
    </Box>
  </Box>
}

export default CreateBOMItems;