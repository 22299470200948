import Box from "@mui/joy/Box";
import Table from "@mui/joy/Table";

import { ClearOutlined, ModeEdit } from "@mui/icons-material";
import { Badge } from "@mui/joy";
import { Tooltip } from "@mui/material";
import { SOStatusEnum } from "app-constants";
import { useAppSelector } from "redux-store";
import { ISalesOrder, ISalesOrderItem } from "types/sales-order";
import {
  showCurrency,
  showProductItemName,
} from "utils/common";
import { getPermission } from "utils/roles";

type TSalesOrderTableItem = {
  listItems: ISalesOrderItem[];
  onEditItem?: (item: ISalesOrderItem) => void;
  onRemoveItem?: (item: ISalesOrderItem) => void;
  allowUpdateItems?: boolean;
  isCreateNew?: boolean;
  salesOrderDetail?: ISalesOrder
};

const SOTableItems = ({
  listItems,
  onEditItem,
  onRemoveItem,
  allowUpdateItems,
  isCreateNew = false,
  salesOrderDetail
}: TSalesOrderTableItem) => {
  const mapProductItems = useAppSelector(
    state => state.product.mapProductItems
  )

  const isAllowEditPrice = getPermission("ALLOW_EDIT_SO_ITEM_PRICE") && ![
    SOStatusEnum.COMPLETED,
  ].includes(salesOrderDetail?.status as SOStatusEnum);

  return (
    <Table variant={"outlined"}>
      <thead>
        <tr>
          <th style={{ width: "40px" }}>#</th>
          <th>Item</th>
          <th style={{ width: "110px", textAlign: "right" }}>Qty</th>
          <th style={{ textAlign: "right" }}> Unit Cost </th>
          <th style={{ textAlign: "right" }}> Discount </th>
          <th style={{ textAlign: "right" }}> Total </th>
          {(allowUpdateItems || isAllowEditPrice) && <th style={{ width: "65px" }}></th>}
        </tr>
      </thead>
      <tbody>
        {listItems?.map((item, i: number) => {
          const product = item.item;
          return (
            <>
              <tr className={!!item.sub_items?.length ? "is_bom" : 'not_bom'} key={item.item_id || product?.id}>
                <td>
                  <Badge invisible={!item.is_ready}>
                    <Box sx={{ mr: 1 }}>
                      {i + 1}
                    </Box>
                  </Badge>
                </td>
                <td>{showProductItemName(product, true)}</td>
                <td style={{ textAlign: "right" }}>
                  {item.quantity} {product?.unit}
                </td>
                <td style={{ textAlign: "right" }}>
                  {showCurrency(item.cost)}{" "}
                </td>
                <td style={{ textAlign: "right" }}>
                  {showCurrency(item.discount)}{" "}
                </td>
                <td style={{ textAlign: "right" }}>
                  {showCurrency(Number(item.total) - Number(item.discount))}{" "}
                </td>

                {(allowUpdateItems || isAllowEditPrice) && (
                  <td>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                      }}
                    >
                      <Tooltip title="Edit" arrow>
                        <ModeEdit
                          sx={{ cursor: "pointer" }}
                          fontSize="small"
                          onClick={(e) => {
                            onEditItem?.(item);
                          }}
                        />
                      </Tooltip>
                      {!!allowUpdateItems && <Tooltip title="Remove" arrow>
                        <ClearOutlined
                          sx={{ cursor: "pointer" }}
                          fontSize="small"
                          onClick={(e) => {
                            onRemoveItem?.(item);
                          }}
                        />
                      </Tooltip>}
                    </Box>
                  </td>
                )}
              </tr>
              {item.sub_items?.map(v => {
                let subItem: any = {};

                if (isCreateNew) {
                  subItem = {
                    ...mapProductItems[v.sub_id],
                    quantity: v.qty * Number(item.quantity)
                  };
                } else {
                  subItem = {
                    ...mapProductItems[v.item_id],
                    quantity: v.quantity,
                  }
                }


                return <tr className="sub_item">
                  <td></td>
                  <td>
                    <b>
                      {subItem?.code}
                    </b>
                  </td>
                  <td style={{ textAlign: "right" }}>{subItem.quantity} {subItem?.unit}</td>
                  <td colSpan={allowUpdateItems ? 4 : 3} />
                </tr>
              }
              )}
            </>
          );
        })}
        {
          !listItems.length && <tr>
            <td style={{ textAlign: 'center' }} colSpan={allowUpdateItems ? 7 : 6}>
              No item selected
            </td>
          </tr>
        }
      </tbody>
    </Table>
  );
};

export default SOTableItems;
