import { Add, ClearOutlined, ModeEdit } from "@mui/icons-material";
import { Divider, FormLabel, Grid, IconButton, Tooltip } from "@mui/joy";
import Box from "@mui/joy/Box";
import { unwrapResult } from "@reduxjs/toolkit";
import AddressModal from "components/AddressModal";
import CommonList from "components/CommonList";
import ConfirmUtils from "components/ConfirmUtils";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  createCustomerAddress,
  deleteCustomerAddress,
  getListCustomerAddress,
  updateCustomerAddress,
} from "redux-store/reducers/customer-portal-so";
import { ICustomerAddress } from "types/customer-portal-so";
import { parseAddress } from "utils/common";
import notification from "utils/notification";

const AddressesListPage = () => {
  const dispatch = useAppDispatch();
  const addressList =
    useAppSelector((state) => state.customerPortalSO.addressList) || [];

  const shippingAddress = addressList.filter((item) => item.type === "ship");
  const billingAddress = addressList.filter((item) => item.type === "bill");
  const [newAddress, setNewAddress] = useState<ICustomerAddress | null>(null);

  const fetchList = () => {
    dispatch(getListCustomerAddress({}));
  };

  useEffect(() => {
    fetchList();
  }, []);

  const handleSaveAddress = (newAddress: ICustomerAddress) => {
    const addJob = {
      ...newAddress,
    };
    delete addJob.customer_id;

    dispatch(
      newAddress?.id
        ? updateCustomerAddress(addJob)
        : createCustomerAddress(addJob)
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success();
          setNewAddress(null);
          fetchList();
        } else {
          notification.error();
        }
      });
  };

  const onRemoveItem = async (item: ICustomerAddress) => {
    if (!(await ConfirmUtils.sure())) {
      return;
    }

    dispatch(
      deleteCustomerAddress({
        id: item.id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success();
          fetchList();
        } else {
          notification.error();
        }
      });
  };

  return (
    <>
      <Grid container>
        {[
          {
            type: "ship",
            list: shippingAddress,
          },
          {
            type: "bill",
            list: billingAddress,
          },
        ].map(({ type, list }, index) => (
          <>
            <Grid xs={12} sm={12} key={type}>
              <Divider sx={{ mt: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  mb: 1,
                  mt: 2,
                }}
              >
                <FormLabel sx={{ mb: 1 }}>
                  {index === 0 ? "Shipping Addresses" : "Billing Addresses"}
                </FormLabel>
                <IconButton
                  size="sm"
                  onClick={() => {
                    setNewAddress({
                      phone: "",
                      type: index === 0 ? "ship" : "bill",
                    });
                  }}
                  variant="outlined"
                  color="primary"
                >
                  <Add />
                </IconButton>
              </Box>
              <CommonList
                pageSize={100}
                noLoading={true}
                data={list}
                size="sm"
                columns={[
                  {
                    key: "name",
                    label: "Name",
                  },
                  {
                    key: "phone",
                    label: "Phone",
                  },
                  {
                    key: "email",
                    label: "Email",
                  },
                  {
                    key: "full_address",
                    label: "Address",
                    rawValue: (item) => {
                      return parseAddress(item);
                    },
                  },
                  {
                    key: "actions",
                    label: "",
                    width: 70,
                    render: (item) => (
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                        }}
                      >
                        <Tooltip title="Edit" arrow>
                          <ModeEdit
                            fontSize="small"
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setNewAddress(item);
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Remove" arrow>
                          <ClearOutlined
                            sx={{ cursor: "pointer" }}
                            fontSize="small"
                            onClick={(e) => {
                              onRemoveItem(item);
                            }}
                          />
                        </Tooltip>
                      </Box>
                    ),
                  },
                ]}
                searchKeys={[]}
                filterStatus={{
                  statusKey: "",
                  data: [],
                }}
                sortKeys={["status", "serial_number", "item_slot", "location"]}
              />
            </Grid>
          </>
        ))}
      </Grid>

      <AddressModal
        isOpen={!!newAddress}
        onClose={() => setNewAddress(null)}
        onSave={(data) => handleSaveAddress(data)}
        selectedAddress={newAddress}
      />
    </>
  );
};

export default AddressesListPage;
