import { Add, ClearOutlined, ModeEdit } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/joy";
import Box from "@mui/joy/Box";
import { unwrapResult } from "@reduxjs/toolkit";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import CommonList from "components/CommonList";
import ConfirmUtils from "components/ConfirmUtils";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import {
  createCustomerContact,
  deleteCustomerContact,
  getListCustomerContact,
  updateCustomerContact,
} from "redux-store/reducers/customer-portal-so";
import { ICustomerContact } from "types/customer-portal-so";
import notification from "utils/notification";

const ContactListPage = () => {
  const dispatch = useAppDispatch();
  const [contactList, setContactList] = useState<ICustomerContact[]>([]);

  const [newContact, setNewContact] = useState<ICustomerContact | null>(null);
  const openCreateContactModal = () => {
    setNewContact({
      name: "",
      email: "",
      phone: "",
      role: "",
    });
  };

  const fetchList = () => {
    dispatch(getListCustomerContact({}))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          setContactList(response.data);
        }
      });
  };

  useEffect(() => {
    fetchList();
  }, []);

  const handleSaveContact = () => {
    const addJob = {
      ...newContact,
    };
    delete addJob.customer_id;

    dispatch(
      newContact?.id
        ? updateCustomerContact(addJob)
        : createCustomerContact(addJob)
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success();
          setNewContact(null);
          fetchList();
        } else {
          notification.error();
        }
      });
  };

  const onRemoveItem = async (item: ICustomerContact) => {
    if (!(await ConfirmUtils.sure())) {
      return;
    }

    dispatch(
      deleteCustomerContact({
        id: item.id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success();
          fetchList();
        } else {
          notification.error();
        }
      });
  };

  return (
    <>
      <Grid container>
        <Grid xs={12} sm={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              mb: 1,
              mt: 2,
            }}
          >
            <FormLabel sx={{ mb: 1 }}>Contacts</FormLabel>
            <IconButton
              size="sm"
              onClick={openCreateContactModal}
              variant="outlined"
              color="primary"
            >
              <Add />
            </IconButton>
          </Box>
          <CommonList
            pageSize={100}
            noLoading={true}
            data={contactList}
            size="sm"
            columns={[
              {
                key: "name",
                label: "Name",
              },
              {
                key: "role",
                label: "Role",
              },
              {
                key: "phone",
                label: "Phone",
              },
              {
                key: "email",
                label: "Email",
              },
              {
                key: "actions",
                label: "",
                width: 70,
                render: (item) => (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                    }}
                  >
                    <Tooltip title="Edit" arrow>
                      <ModeEdit
                        fontSize="small"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setNewContact(item);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Remove" arrow>
                      <ClearOutlined
                        sx={{ cursor: "pointer" }}
                        fontSize="small"
                        onClick={(e) => {
                          onRemoveItem(item);
                        }}
                      />
                    </Tooltip>
                  </Box>
                ),
              },
            ]}
            searchKeys={[]}
            filterStatus={{
              statusKey: "",
              data: [],
            }}
            sortKeys={["role", "name", "phone", "email"]}
          />
        </Grid>
      </Grid>

      <BaseModal
        title={newContact?.id ? "Edit Contact" : "New Contact"}
        isOpen={!!newContact}
        onClose={() => setNewContact(null)}
        actions={<Button onClick={() => handleSaveContact()}>Save</Button>}
      >
        <Grid container spacing={2}>
          <Grid sm={12} xs={12}>
            <FormControl>
              <BaseInput
                label="Name"
                value={newContact?.name}
                onChange={(e) =>
                  setNewContact({ ...newContact, name: e.target.value })
                }
              />
            </FormControl>
          </Grid>

          <Grid sm={12} xs={12}>
            <FormControl>
              <BaseInput
                label="Role"
                value={newContact?.role}
                onChange={(e) =>
                  setNewContact({ ...newContact, role: e.target.value })
                }
              />
            </FormControl>
          </Grid>

          <Grid sm={12} xs={12}>
            <FormControl>
              <BaseInput
                label="Phone"
                value={newContact?.phone}
                onChange={(e) =>
                  setNewContact({ ...newContact, phone: e.target.value })
                }
              />
            </FormControl>
          </Grid>

          <Grid sm={12} xs={12}>
            <FormControl>
              <BaseInput
                label="Email"
                value={newContact?.email}
                onChange={(e) =>
                  setNewContact({ ...newContact, email: e.target.value })
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </BaseModal>
    </>
  );
};

export default ContactListPage;
