import { Button } from "@mui/joy";
import { SOStatusEnum } from "app-constants";
import { useState } from "react";
import { useAppDispatch } from "redux-store";
import {
  approveBackorder,
  changeBackorderToSentEmail,
  getOpenAction,
  markBackOrderPick,
} from "redux-store/reducers/sales-order";
import { ISalesOrder } from "types/sales-order";
import notification from "utils/notification";
import { getPermission } from "utils/roles";

const BackorderSOActions = ({
  salesOrderDetail,
  onUpdateSuccess,
}: {
  salesOrderDetail: ISalesOrder;
  onUpdateSuccess: () => void;
}) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const sendEmail = async () => {
    setLoading(true);
    dispatch(
      changeBackorderToSentEmail({
        id: salesOrderDetail.id,
      })
    )
      .unwrap()
      .then(({ response }) => {
        setLoading(false);
        if (response.apiStatus) {
          notification.success();
          onUpdateSuccess();
        } else {
          notification.error();
        }
      });
  };

  const approveBackorderSO = async () => {
    setLoading(true);
    dispatch(
      approveBackorder({
        id: salesOrderDetail.id,
      })
    )
      .unwrap()
      .then(({ response }) => {
        setLoading(false);
        if (response.apiStatus) {
          notification.success();
          onUpdateSuccess();
        } else {
          notification.error();
        }
      });
  };

  const markBackOrderPickAct = async () => {
    setLoading(true);
    dispatch(
      markBackOrderPick({
        id: salesOrderDetail.id,
      })
    )
      .unwrap()
      .then(({ response }) => {
        setLoading(false);
        if (response.apiStatus) {
          notification.success();
          onUpdateSuccess();
          dispatch(getOpenAction({}))
        } else {
          notification.error();
        }
      });
  };

  if (!getPermission("ALLOW_UPDATE_SO")) {
    return null;
  }
  return (
    <>
      {salesOrderDetail.status === SOStatusEnum.IN_REVIEW && (
        <Button loading={loading} size="sm" onClick={sendEmail}>
          Send Email
        </Button>
      )}

      {salesOrderDetail.status === SOStatusEnum.SENT_EMAIL && (
        <Button loading={loading} size="sm" onClick={approveBackorderSO}>
          Approve Backorder
        </Button>
      )}

      {salesOrderDetail?.status === SOStatusEnum.AWAITING_MANUFACTURING && (
        <Button loading={loading} size="sm" onClick={markBackOrderPickAct}>
          Mark as Ready to Pick
        </Button>
      )}
    </>
  );
};

export default BackorderSOActions;
