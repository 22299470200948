import Typography from "@mui/joy/Typography";

import { Card, Grid, Stack } from "@mui/joy";
import { IInvoice } from "types/invoice";
import { formatDate } from "utils/common";
import InvoiceTableItem from "./InvoiceTableItem";

export default function DetailContent({
  invoiceDetail,
  onUpdateSuccess,
}: {
  invoiceDetail: IInvoice;
  onUpdateSuccess: () => void;
}) {
  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <Card
          color="neutral"
          invertedColors={false}
          orientation="vertical"
          size="sm"
          variant="outlined"
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid sx={{ flex: 1, mr: 1 }}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Customer
              </Typography>

              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {invoiceDetail?.customer?.first_name}{" "}
                {invoiceDetail?.customer?.last_name}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                <b>ID</b>:  {invoiceDetail?.customer?.cus_number}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                <b>P</b>: {invoiceDetail?.customer?.phone || "-"}
              </Typography>
              <Typography sx={{ fontSize: 14, mb: 1 }}>
                <b>M</b>: {invoiceDetail?.customer?.email || "-"}
              </Typography>
            </Grid>

            <Grid sx={{ flex: 1, ml: 1 }}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Bill To
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {invoiceDetail?.billing_address.name}
              </Typography>

              <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                {invoiceDetail?.billing_address?.company}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {invoiceDetail?.billing_address.address}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {invoiceDetail?.billing_address.city},{" "}
                {invoiceDetail?.billing_address.state}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {invoiceDetail?.billing_address.zip_code}{" "}
                {invoiceDetail?.billing_address.country}
              </Typography>

              {invoiceDetail?.billing_address.phone && (
                <Typography sx={{ fontSize: 14 }}>
                  Phone: {invoiceDetail?.billing_address.phone}
                </Typography>
              )}
            </Grid>
            <Grid sx={{ flex: 1, ml: 1 }}>
              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                Customer PO
              </Typography>
              <Typography sx={{ mb: 1, fontWeight: 600 }}>
                {invoiceDetail?.name || "-"}
              </Typography>


              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                Sent Date
              </Typography>
              <Typography sx={{ mb: 1, fontWeight: 600 }}>
                {invoiceDetail?.invoice?.sent_date ? formatDate(invoiceDetail?.invoice?.sent_date) : '-'}
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                Due Date
              </Typography>
              <Typography sx={{ mb: 1, fontWeight: 600 }}>
                {formatDate(invoiceDetail?.invoice?.due_date)}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Stack>

      <InvoiceTableItem invoiceDetail={invoiceDetail} onUpdateSuccess={onUpdateSuccess} />
    </Stack>
  );
}
