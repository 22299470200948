
import { Box, Button, Typography } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import { getQuickBooksToken, getQuickBooksUrl } from "redux-store/reducers/employee";

export default function QuickbookConfigs() {
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const state = params.get('state');
  const realmId = params.get('realmId')
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const dispatch = useAppDispatch();
  const handleGetUrlAuth = () => {
    dispatch(getQuickBooksUrl({}))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          if (response.data.url) {
            window.location.href = response.data.url
          }
        }
      });;
  };

  const handleGetStatus = (state: string | null, code: string | null, realmId: string | null) => {
    setIsLoading(true)
    dispatch(getQuickBooksToken({ state, code, realmId, url: window.location.href }))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          if (code && state && realmId) {
            const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?tab=Quickbook Config';
            window.history.pushState({ path: newurl }, '', newurl);
          }
          setIsConnected(!!response?.data?.connected)
        }
        setIsLoading(false)
      });;
  };

  useEffect(() => {
    handleGetStatus(state, code, realmId);
  }, []);
  return <Box sx={{ textAlign: "center" }} py={5}>
    <img width={280} style={{ marginBottom: "30px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/79/Intuit_QuickBooks_logo.svg/1024px-Intuit_QuickBooks_logo.svg.png?20240123223906" alt="" />

    <Typography>
      QuickBooks Connection: {isConnected ? <b style={{ color: "green" }}>Connected</b> : "Not Connected"}
    </Typography>
    <br />
    <Typography>
      <Button variant="outlined"
        loading={isLoading}
        disabled={isConnected}
        onClick={() => handleGetUrlAuth()}
      >
        {isLoading ? "Loading..." : "Click to connect now"}
      </Button>
    </Typography>

  </Box>
}