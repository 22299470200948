import { Add, ClearOutlined, ModeEdit } from "@mui/icons-material";
import { Box, Grid, IconButton, Tooltip } from "@mui/joy";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import { unwrapResult } from "@reduxjs/toolkit";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import CommonList from "components/CommonList";
import ConfirmUtils from "components/ConfirmUtils";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import { getListCustomerContact } from "redux-store/reducers/customer";
import {
  createCustomerContact,
  deleteCustomerContact,
  updateCustomerContact,
} from "redux-store/reducers/customer-portal-so";
import { ICustomer } from "types/customer";
import { ICustomerContact } from "types/customer-portal-so";
import notification from "utils/notification";
import { getPermission } from "utils/roles";

export default function ContactContent({
  customerDetailProp,
  onUpdateSuccess,
}: {
  customerDetailProp: ICustomer;
  onUpdateSuccess: () => void;
}) {
  const dispatch = useAppDispatch();
  const [contactList, setContactList] = useState<ICustomerContact[]>([]);

  const [newContact, setNewContact] = useState<ICustomerContact | null>(null);
  const openCreateContactModal = () => {
    if (!getPermission('ALLOW_UPDATE_CUSTOMER')) {
      notification.error("You don't have permission to perform this action");
      return;
    }
    setNewContact({
      name: "",
      email: "",
      phone: "",
      role: "",
    });
  };

  const fetchList = () => {
    if (!customerDetailProp.id) return;
    dispatch(
      getListCustomerContact({
        customer_id: customerDetailProp.id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) setContactList(response.data);
      });
  };

  const handleSaveContact = () => {
    const addJob = {
      ...newContact,
      customer_id: customerDetailProp.id,
      fromAdmin: true,
    };

    dispatch(
      newContact?.id
        ? updateCustomerContact(addJob)
        : createCustomerContact(addJob)
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success();
          setNewContact(null);
          fetchList();
        } else {
          notification.error();
        }
      });
  };

  useEffect(() => {
    fetchList();
  }, [customerDetailProp]);

  const onRemoveItem = async (item: ICustomerContact) => {
    if (!(await ConfirmUtils.sure())) {
      return;
    }

    dispatch(
      deleteCustomerContact({
        id: item.id,
        fromAdmin: true,
        customer_id: customerDetailProp.id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success();
          fetchList();
        } else {
          notification.error();
        }
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              size="sm"
              onClick={() => openCreateContactModal()}
              variant="outlined"
              color="primary"
            >
              <Add />
            </IconButton>
          </Box>
        </Grid>
        <Grid xs={12} sm={12}>
          <CommonList
            pageSize={100}
            noLoading={true}
            data={contactList}
            size="sm"
            columns={[
              {
                key: "name",
                label: "Name",
              },
              {
                key: "role",
                label: "Role",
              },
              {
                key: "email",
                label: "Email",
              },
              {
                key: "phone",
                label: "Phone",
              },
              {
                key: "actions",
                label: "",
                width: 70,
                render: (item) => (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                    }}
                  >
                    <Tooltip title="Edit" arrow>
                      <ModeEdit
                        fontSize="small"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!getPermission('ALLOW_UPDATE_CUSTOMER')) {
                            notification.error("You don't have permission to perform this action");
                            return;
                          }
                          setNewContact(item);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Remove" arrow>
                      <ClearOutlined
                        sx={{ cursor: "pointer" }}
                        fontSize="small"
                        onClick={(e) => {
                          if (!getPermission('ALLOW_UPDATE_CUSTOMER')) {
                            notification.error("You don't have permission to perform this action");
                            return;
                          }
                          onRemoveItem(item);
                        }}
                      />
                    </Tooltip>
                  </Box>
                ),
              },
            ]}
            searchKeys={[]}
            filterStatus={{
              statusKey: "",
              data: [],
            }}
          />
        </Grid>
      </Grid>

      <BaseModal
        title={newContact?.id ? "Edit Contact" : "New Contact"}
        isOpen={!!newContact}
        onClose={() => setNewContact(null)}
        actions={<Button onClick={() => handleSaveContact()}>Save</Button>}
      >
        <Grid container spacing={2}>
          <Grid sm={12} xs={12}>
            <FormControl>
              <BaseInput
                label="Name"
                value={newContact?.name}
                onChange={(e) =>
                  setNewContact({ ...newContact, name: e.target.value })
                }
              />
            </FormControl>
          </Grid>

          <Grid sm={12} xs={12}>
            <FormControl>
              <BaseInput
                label="Role"
                value={newContact?.role}
                onChange={(e) =>
                  setNewContact({ ...newContact, role: e.target.value })
                }
              />
            </FormControl>
          </Grid>

          <Grid sm={12} xs={12}>
            <FormControl>
              <BaseInput
                label="Phone"
                value={newContact?.phone}
                onChange={(e) =>
                  setNewContact({ ...newContact, phone: e.target.value })
                }
              />
            </FormControl>
          </Grid>

          <Grid sm={12} xs={12}>
            <FormControl>
              <BaseInput
                label="Email"
                value={newContact?.email}
                onChange={(e) =>
                  setNewContact({ ...newContact, email: e.target.value })
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </BaseModal>
    </>
  );
}
