import { Badge, Box, Button } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { unwrapResult } from "@reduxjs/toolkit";
import { DELIVERY_METHOD, SOStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import CustomerView from "components/CustomerView";
import FilterDrawer, { IFilterItem } from "components/FilterDrawer";
import PageWrapper from "components/PageWrapper";
import { ViewDetailModalWrapper } from "components/ViewDetailModal";
import _ from "lodash";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import { savePageFilter } from "redux-store/reducers/common";
import { getListCustomer } from "redux-store/reducers/customer";
import { getBackorderItems, getListSalesOrder } from "redux-store/reducers/sales-order";
import { exportBackorderItem, formatDate, showCurrency } from "utils/common";
import useOpenDetailPopup from "utils/common-hooks/useOpenDetailPopup";
import notification from "utils/notification";

export default function SalesOrderListBackorderPage() {
  const filterValue = useAppSelector((state) => state.common.searchFilter.BACK_ODER_SO_LIST)
  const salesOrderList = useAppSelector((state) => state.salesOrder.list);
  const productItems =
    useAppSelector((state) => state.product.productItems) || [];

  const userMap = useAppSelector((state) => state.employee.userMap);

  const customerList = useAppSelector((state) => state.customer.list);
  const dispatch = useAppDispatch();

  const {
    openDetailPopup,
  } = useOpenDetailPopup({
    type: 'SO'
  })

  const handleGetListSalesOrder = () => {
    dispatch(getListSalesOrder({ backorder: "yes", ...filterValue }));
  };

  const handleExportItem = () => {
    dispatch(getBackorderItems({}))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success();
          console.log('response.data', response.data)
          exportBackorderItem(response.data)
        } else {
          notification.error(response.message);
        }
      })
      .catch((error) => {
        notification.error(error.message);
      });
  };

  useEffect(() => {
    handleGetListSalesOrder();
    dispatch(getListCustomer({}));
  }, []);

  useEffect(() => {
    handleGetListSalesOrder();
  }, [filterValue])

  const filterFields: IFilterItem[] = [
    {
      label: "Sales Order / Customer PO",
      type: "texts",
      key: "textSearch",
      placeholder: "Type Sales Order, Customer PO",
    },
    {
      label: "Customers",
      placeholder: "Select customer",
      type: "multiple-select",
      key: "customers",
      options: _.map(customerList, (cus) => ({ label: cus.first_name, value: cus.id })),
    },
    {
      label: "Ship Zip Code",
      placeholder: "Type multi zip code",
      type: "multiple-select",
      freeSolo: true,
      key: "zip_codes",
    },
    {
      label: "Items",
      placeholder: "Select item",
      type: "multiple-select",
      key: "items",
      options: _.map(productItems, (cus) => ({ label: cus.code, value: cus.code })),
    },
    {
      label: "Status",
      placeholder: "Select status",
      type: "multiple-select",
      key: "statuses",
      options: [
        SOStatusEnum.IN_REVIEW,
        SOStatusEnum.SENT_EMAIL,
        SOStatusEnum.BACKORDER_APPROVED,
        SOStatusEnum.AWAITING_MANUFACTURING
      ].map(e => ({
        label: e,
        value: e
      }))
    },
    {
      label: "Ship Methods",
      placeholder: "Select method",
      type: "multiple-select",
      key: "methods",
      options: DELIVERY_METHOD.map(e => ({
        label: e,
        value: e
      }))
    },
  ];
  return (
    <>
      <PageWrapper
        pageName="Backorders"
        breadcrumb={[
          {
            label: "Backorder",
          },
        ]}
        pageAction={
          <Box
            sx={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <Button
              variant="outlined"
              size="sm"
              sx={{ mr: 1 }}
              onClick={handleExportItem}
            >
              Export Item
            </Button>
            <FilterDrawer
              filters={filterFields}
              value={filterValue}
              onChange={(e) => {
                dispatch(savePageFilter({
                  name: 'BACK_ODER_SO_LIST',
                  data: e,
                }))
              }}
            />
          </Box>
        }
      >
        <CommonList
          data={salesOrderList}
          columns={[
            {
              key: "so_number",
              label: "#",
              width: 80,
              render: (row) => <Badge invisible={!row.is_ready}><b>{row.so_number}</b></Badge>,
            },
            {
              key: "customerName",
              label: "Customer",
              render: (row) => <CustomerView customer={row.customer as any} />,
              rawValue: (row) =>
                `${row.customer?.first_name} ${row.customer?.last_name} ${row.customer?.email} ${row.customer?.cus_number}`,
            },
            {
              key: "saleRep",
              label: "Sales Rep",
              rawValue: (row) => row.user_id && userMap[row.user_id]?.name,
            },
            {
              key: "name",
              label: "Customer PO",
              render: (row) => <Typography>{row.name}</Typography>,
            },
            {
              key: "created_at",
              label: "Created At",
              width: 120,
              render: (row) => (
                <Typography>{formatDate(row.created_at)}</Typography>
              ),
            },
            {
              key: "total",
              label: "Total",
              width: 120,
              render: (row) => (
                <Typography>{showCurrency(row.total)}</Typography>
              ),
            },
            {
              key: "status",
              label: "Status",
              width: 120,
              render: (row) => <ChipStatus status={row.status} size="small" />,
            },

          ]}
          searchKeys={["so_number", "customerName", 'name']}
          sortKeys={["created_at", "so_number", "total", 'status', 'customerName', 'name', 'saleRep']}
          onRowClick={(row) =>
            openDetailPopup(row.id)
          }
        />
      </PageWrapper>
      <ViewDetailModalWrapper />
    </>
  );
}
