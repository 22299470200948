import Dropdown from "@mui/joy/Dropdown";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";

import MoreVertRounded from "@mui/icons-material/MoreVertRounded";
import { Divider } from "@mui/joy";
import { PopperPlacementType } from "@mui/material";

export default function MoreActions({
  actions,
  loading,
  placement = "bottom-start",
  icon,
}: {
  actions: {
    hide?: boolean;
    disabled?: boolean;
    label: React.ReactNode;
    onClick: () => void;
  }[];
  loading?: boolean;
  placement?: PopperPlacementType;
  icon?: React.ReactNode;
}) {
  const actionsRender = (actions || []).filter(e => { return !e.hide })
  return (
    <>
      {
        !!actionsRender.length &&
        <Dropdown>
          <MenuButton
            loading={loading}
            slots={{ root: IconButton }}
            slotProps={{ root: { variant: "plain", color: "neutral", size: "sm" } }}
          >
            {icon ? icon : <MoreVertRounded />}
          </MenuButton>
          <Menu placement={placement} size="sm" sx={{ minWidth: 120 }}>
            {actionsRender.map((action, index) => (
              action.hide ? null :
                <>
                  {index > 0 && actionsRender.length > 1 && <Divider></Divider>}
                  <MenuItem
                    disabled={action.disabled}
                    key={index}
                    onClick={action.onClick}
                  >
                    {action.label}
                  </MenuItem>

                </>
            ))}
          </Menu>
        </Dropdown>
      }
    </>

  );
}
