import Typography from "@mui/joy/Typography";
import { SOStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import PickingList from "components/PickingList";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { setBreadcrumb } from "redux-store/reducers/common";
import { getListSalesOrderPick } from "redux-store/reducers/sales-order";
import ROUTERS from "routers/constants";
import AppEvent from "utils/app-events";
import { formatDate, showCurrency } from "utils/common";
import useListenEmitEvent from "utils/common-hooks/useListenEmitEvent";

export default function SalesOrderPickListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status") as any || SOStatusEnum.READY_TO_PICK;
  const navigate = useNavigate();
  const salesOrderPickList = useAppSelector(
    (state) => state.salesOrder.pickList
  );
  const dispatch = useAppDispatch();
  const handleGetListSalesOrder = () => {
    dispatch(getListSalesOrderPick({ status }));
  };
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          label: "PPS Processing",
        },
      ])
    );
  }, []);

  useEffect(() => {
    handleGetListSalesOrder();
  }, [status]);

  useListenEmitEvent({
    event: AppEvent.types.NOTI_PULL,
    matchCondition: (payload) => true,
    handle: () => {
      handleGetListSalesOrder();
    },
  });


  return (
    <PickingList
      pageSize={200}
      data={salesOrderPickList}
      tabName={status}
      setTabName={(tabName) => {
        setSearchParams({ status: tabName });
      }}
      columns={[
        {
          key: "so_number",
          label: "#SO",
          width: 80,
        },
        {
          key: "customerName",
          label: "Customer",
          width: 140,
          rawValue: (row) =>
            `${row.customer?.first_name} ${row.customer?.last_name}`,
        },
        {
          key: "total",
          label: "Total",
          width: 140,
          render: (row) => <Typography>{showCurrency(row.total)}</Typography>,
        },
        {
          key: "status",
          label: "Status",
          width: 140,
          render: (row) => <ChipStatus status={row.status} size="small" />,
        },
        {
          key: "created_at",
          label: "Created At",
          width: 120,
          render: (row) => (
            <Typography>{formatDate(row.created_at)}</Typography>
          ),
        },
      ]}
      searchKeys={["so_number", "customerName"]}
      sortKeys={["created_at"]}
      filterStatus={{
        statusKey: "",
        data: [],
      }}
      onRowClick={(row) =>
        navigate(
          `${ROUTERS.SALES_ORDER_PICK_DETAIL.replace(":id", row.id as any)}`
        )
      }
    />
  );
}
