import { Box, Button, CircularProgress, Grid, Table, Typography } from "@mui/joy";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import { unwrapResult } from "@reduxjs/toolkit";
import { PalletStatusEnum } from "app-constants";
import CardWithLabel from "components/CardWithLabel";
import ConfirmUtils from "components/ConfirmUtils";
import SelectWarehouseLocation from "components/SelectWarehouseLocation";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import { setBreadcrumb } from "redux-store/reducers/common";
import { getWarehouseAreas, receiveItemPalletV2 } from "redux-store/reducers/warehouse";
import { IArea } from "types/warehouse";
import notification from "utils/notification";

let scanningText = "";
export default function ReceivePage() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [warehouseAreas, setWarehouseAreas] = useState<IArea[]>([]);
  const [selectedArea, setSelectedArea] = useState<number | undefined>();
  const [palletData, setPalletData] = useState<any>();
  const [selectedType, setSelectedType] = useState<"Package" | "Pallet" | "Item">(
    "Pallet"
  );
  const scanModeRef = React.createRef<HTMLDivElement>();
  useEffect(() => {
    dispatch(getWarehouseAreas({ warehouse_id: 1 }))
      .then(unwrapResult)
      .then(({ response }) => {
        setWarehouseAreas(response.data);
      });

    dispatch(
      setBreadcrumb([
        {
          label: "Receive",
        },
      ])
    );
    scanningText = "";
  }, []);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (loading) {
      scanningText = "";
      return;
    }
    // Assuming your scanner adds a special character before/after the scanned code
    // You may need to adjust this based on your scanner's behavior
    if (e.key === "Enter") {
      setPalletData(null)
      if (!scanningText) return;
      if (!selectedArea) {
        return notification.error("Please select location")
      }
      setTimeout(() => {
        // call api
        setLoading(true);
        dispatch(
          receiveItemPalletV2({
            area_id: selectedArea,
            text: scanningText,
            type: selectedType,
            isConfirm: false
          })
        )
          .then(unwrapResult)
          .then(({ response }) => {
            setLoading(false);
            scanningText = "";
            if (response.apiStatus) {
              const data = response.data;
              setPalletData(data)
            } else {
              return notification.error(response.data.message)
            }
          })
      }, 0);
    } else {
      scanningText += e.key.replace(/^Shift/, "");
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", handleKeyDown);
    return () => {
      document.removeEventListener("keypress", handleKeyDown);
    };
  }, [handleKeyDown, selectedArea, selectedType, loading]);

  const handlePalletScan = async () => {
    if (!palletData) return

    if (!(await ConfirmUtils.sure(
      "Confirmation Required",
      "Please ensure you have reviewed the package and item quantities carefully before proceeding. Double-check your checklist to make sure all items are accounted for."
    ))) return;

    // call api to receive pallet
    setLoading(true);
    dispatch(
      receiveItemPalletV2({
        area_id: selectedArea,
        text: palletData.pallet_number,
        type: selectedType,
        isConfirm: true
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        if (response.apiStatus && response.data.ok) {
          setPalletData({ ...palletData, status: PalletStatusEnum.RECEIVED })
          notification.success("Complete to revieve pallet #" + palletData.pallet_number)
        }
      });

  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          sm={12}
          xs={12}
          ref={scanModeRef}
          tabIndex={-1}
          sx={{
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <RadioGroup
            aria-label="Type"
            name="type"
            defaultValue="Pallet"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value as any)}
          >
            <List
              orientation="horizontal"
              sx={{
                "--List-gap": "0.3rem",
                "--ListItem-paddingY": "0.3rem",
                "--ListItem-radius": "8px",
                "--ListItemDecorator-size": "32px",
                flexWrap: "wrap",
              }}
            >
              {["Pallet"].map((item, index) => (
                <ListItem
                  variant="outlined"
                  key={item}
                  sx={{ boxShadow: "sm", flex: 1 }}
                >
                  <Radio
                    overlay
                    value={item}
                    label={item === "Package" ? "Pkg" : item}
                    sx={{ flexGrow: 1, flexDirection: "row-reverse" }}
                    slotProps={{
                      action: ({ checked }) => ({
                        sx: (theme) => ({
                          ...(checked && {
                            inset: -1,
                            border: "1px solid",
                            borderColor: theme.vars.palette.primary[500],
                          }),
                        }),
                      }),
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </RadioGroup>
        </Grid>
        <Grid
          sm={12}
          xs={12}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              scanModeRef.current?.focus();
            }
          }}
        >
          <CardWithLabel label="Select location">
            <SelectWarehouseLocation
              areas={warehouseAreas}
              onSelect={(area) => {
                scanModeRef.current?.focus();
                setTimeout(() => {
                  setSelectedArea(area?.id);
                }, 100);
              }}
            />
          </CardWithLabel>
        </Grid>

        <Grid sm={12} xs={12}>
          {loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              <CircularProgress variant="plain" size="lg" value={60} />
            </Box>
          )}


          {selectedType === "Pallet" && palletData && (
            <>
              <Box sx={
                {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: '0.5rem'
                }
              }>
                <Box
                  sx={{
                    fontSize: "13px"
                  }}
                >
                  Scaned: <strong> PALLET{palletData.pallet_number}</strong>
                </Box>
                <Box>
                  {
                    palletData.status == "Received" ? <Typography color="success">
                      <b>Pallet Received</b>
                    </Typography> :
                      <Button
                        onClick={() => {
                          handlePalletScan()
                        }}
                      >
                        Confirm to Receive
                      </Button>
                  }
                </Box>
              </Box>
              <Table
                borderAxis="both"
              >
                <thead>
                  <tr>
                    <th style={{
                      paddingTop: 0,
                      width: "170px"
                    }}>
                      Item
                    </th>
                    <th>
                      Qty
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (palletData?.result || []).map((e: any) => {
                      return <tr key={`${e.item.code}`}>
                        <td>
                          <strong className="brT"> {e.item.code}</strong>
                        </td>
                        <td>
                          <strong> {e.quantity}</strong> {e.item.unit}
                        </td>
                      </tr>
                    })
                  }

                </tbody>
              </Table>
            </>
          )}

        </Grid>
      </Grid>
    </Box>
  );
}

// ABACO-240627-004
