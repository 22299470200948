import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";

import { Button } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "redux-store";

import { FreightQuoteStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import ConfirmUtils from "components/ConfirmUtils";
import PageWrapper from "components/PageWrapper";
import { DetailSkeleton } from "components/SkeletonComponents";
import {
  acceptFreightQuote,
  cancelFreightQuote,
  getDetailCustomerFreightQuote,
} from "redux-store/reducers/customer-portal-freight-quote";
import { ROUTERS_CUSTOMER } from "routers/constants";
import { IFreightQuote } from "types/freight-quote";
import AppEvent from "utils/app-events";
import { formatDate } from "utils/common";
import useListenEmitEvent from "utils/common-hooks/useListenEmitEvent";
import notification from "utils/notification";
import DetailContent from "./components/DetailContent";

export default function FreightQuoteDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const navigate = useNavigate();
  const id = useParams()?.id || detailId;
  const dispatch = useAppDispatch();
  const [cancelLoading, setCancelLoading] = useState(false);

  const [freightQuote, setfreightQuote] = useState<IFreightQuote>();

  const fetchDetailData = () => {
    if (!id) return;
    dispatch(
      getDetailCustomerFreightQuote({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.id) setfreightQuote(response.data);
        setCancelLoading(false);
      });
  };

  useEffect(() => {
    fetchDetailData();
  }, [id]);

  const handleCancelOrder = async () => {
    if (!(await ConfirmUtils.sure())) return;
    setCancelLoading(true);
    dispatch(
      cancelFreightQuote({
        id: id as any,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success();
          navigate(ROUTERS_CUSTOMER.FREIGHT_QUOTE);
          setCancelLoading(false);
        } else {
          notification.error();
          setCancelLoading(false);
        }
      });
  };

  const handleAccept = async () => {
    if (!(await ConfirmUtils.sure())) return;
    setCancelLoading(true);
    dispatch(
      acceptFreightQuote({
        id: id as any,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success();
          navigate(ROUTERS_CUSTOMER.FREIGHT_QUOTE);
          setCancelLoading(false);
        } else {
          notification.error();
          setCancelLoading(false);
        }
      });
  };

  useListenEmitEvent({
    event: AppEvent.types.FQ_STATUS_CHANGE,
    matchCondition: (payload) =>
      !!freightQuote?.id && payload.fq_id == freightQuote.id,
    handle: () => {
      fetchDetailData();
    },
  });

  return (
    <Box sx={{ mt: "24px" }}>
      <PageWrapper
        pageName={
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography>
              Freight Quote
              <br />
              <Typography sx={{ fontSize: 14, fontWeight: 300 }}>
                Date: {formatDate(freightQuote?.created_at, true)}
              </Typography>
            </Typography>
            {!!freightQuote && (
              <ChipStatus status={freightQuote.status} size="medium" />
            )}
          </Box>
        }
        pageAction={
          !!freightQuote?.id && (
            <Box sx={{ display: "flex", gap: 1 }}>
              {![FreightQuoteStatusEnum.APPROVED].includes(
                freightQuote?.status as any
              ) && (
                  <Button
                    variant="outlined"
                    size="sm"
                    color="neutral"
                    onClick={handleCancelOrder}
                    loading={cancelLoading}
                  >
                    Ignore
                  </Button>
                )}
              {[FreightQuoteStatusEnum.COST_PROVIDED].includes(
                freightQuote?.status as any
              ) && (
                  <Button
                    size="sm"
                    onClick={handleAccept}
                    loading={cancelLoading}
                  >
                    Accept
                  </Button>
                )}
            </Box>
          )
        }
        skeletonLoading={!freightQuote ? <DetailSkeleton /> : undefined}
        tabs={[
          {
            label: "Detail",
            content: !!freightQuote && (
              <DetailContent
                freightQuote={freightQuote}
                onUpdateSuccess={() => {
                  fetchDetailData();
                }}
              />
            ),
          },
        ]}
      ></PageWrapper>
    </Box>
  );
}
