import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import { formLabelClasses } from "@mui/joy/FormLabel";
import GlobalStyles from "@mui/joy/GlobalStyles";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { unwrapResult } from "@reduxjs/toolkit";
import BaseInput from "components/BaseInput";
import ColorSchemeToggle from "components/ColorSchemeToggle";
import * as React from "react";
import { useAppDispatch } from "redux-store";
import { fetchLogin, setSession } from "redux-store/reducers/auth";
import notification from "utils/notification";

interface FormElements extends HTMLFormControlsCollection {
  username: HTMLInputElement;
  password: HTMLInputElement;
  persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export default function LoginPage() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);

  const handleLogin = (event: React.FormEvent<SignInFormElement>) => {
    event.preventDefault();
    const formElements = event.currentTarget.elements;
    setLoading(true);
    dispatch(
      fetchLogin({
        username: formElements.username.value,
        password: formElements.password.value,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        if (response.data?.access_token) {
          dispatch(
            setSession({
              user: {
                name: formElements.username.value,
                roles: response.data.roles || [],
              },
              access_token: response.data.access_token,
            })
          );
        } else {
          notification.error();
        }
      });
  };

  return (
    <>
      <GlobalStyles
        styles={{
          ":root": {
            "--Collapsed-breakpoint": "769px", // form will stretch when viewport is below `769px`
            "--Cover-width": "50vw", // must be `vw` only
            "--Form-maxWidth": "800px",
            "--Transition-duration": "0.4s", // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width:
            "clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)",
          transition: "width var(--Transition-duration)",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "flex-end",
          backdropFilter: "blur(12px)",
          backgroundColor: "rgba(255 255 255 / 0.2)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundColor: "rgba(19 19 24 / 0.4)",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
            width:
              "clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)",
            maxWidth: "100%",
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: "flex",
              alignItems: "left",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ gap: 2, display: "flex", alignItems: "center" }}>
              {/* <IconButton variant='soft' color='primary' size='sm'>
                <BadgeRoundedIcon />
              </IconButton> */}
              {/* <Typography level='title-lg'>Abaco</Typography> */}
            </Box>
            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: "hidden",
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography level="h3">Sign in</Typography>
                {/* <Typography level='body-sm'>
                  New to company?{' '}
                  <Link href='#replace-with-a-link' level='title-sm'>
                    Sign up!
                  </Link>
                </Typography> */}
              </Stack>
            </Stack>
            <Stack gap={4}>
              <form onSubmit={handleLogin}>
                <FormControl required>
                  <BaseInput label="Username" type="text" name="username" />
                </FormControl>
                <FormControl required>
                  <BaseInput label="Password" type="password" name="password" />
                </FormControl>
                <Stack gap={4} sx={{ mt: 2 }}>
                  {/* <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox size='sm' label='Remember me' name='persistent' />
                    <Link level='title-sm' href='#replace-with-a-link'>
                      Forgot your password?
                    </Link>
                  </Box> */}
                  <Button type="submit" fullWidth loading={loading}>
                    Sign in
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © Abaco {new Date().getFullYear()} -{" "}
              {process.env.REACT_APP_PUBLIC_VERSION}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
          left: "clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))",
          transition:
            "background-image var(--Transition-duration), left var(--Transition-duration) !important",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          backgroundColor: "background.level1",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundImage:
              "url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)",
          },
        })}
      />
    </>
  );
}
