import Typography from "@mui/joy/Typography";

import { CloseOutlined, Edit, VerifiedUserOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  Stack
} from "@mui/joy";
import { Tooltip } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { ITEM_TYPE, ITEM_TYPE_VALUE, SERIAL_VERSIONS } from "app-constants";
import BaseDatePicker from "components/BaseDatePicker";
import BaseModal from "components/BaseModal";
import BaseNumberInput from "components/BaseNumberInput";
import BaseSelect from "components/BaseSelect";
import CardWithLabel from "components/CardWithLabel";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import ConfirmUtils from "components/ConfirmUtils";
import MoreActions from "components/MoreActions";
import PageWrapper from "components/PageWrapper";
import SelectWarehouseLocation from "components/SelectWarehouseLocation";
import { DetailSkeleton } from "components/SkeletonComponents";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  getDetailInventoryItem,
  getDetailInventoryItemHistory,
  reStockItem,
  relocateItem,
  removeStockItem,
  restoreStockItem,
  updateAdjustQty
} from "redux-store/reducers/inventory";
import { getWarehouseAreas } from "redux-store/reducers/warehouse";
import ROUTERS from "routers/constants";
import { IInventoryItem, IRealItem, IRelocateItem } from "types/inventory";
import {
  displayAreasLevel,
  exportBarCodeCSV,
  findAreaLevel,
  formatDate,
  printSerialBarCodeFromArrays,
  showCurrency
} from "utils/common";
import notification from "utils/notification";
import { RolesEnum, getPermission } from "utils/roles";
import { isMobile } from "utils/ui-uitils";

export default function InventoryItemDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const warehouseAreas = useAppSelector(
    (state) => state.warehouse.warehouseAreas
  );
  const id = useParams()?.id || detailId;
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const inventoryFromState = useAppSelector((state) =>
    [...state.inventory.allItems]?.find((inventory) => inventory.id === id)
  );
  const [inventoryDetail, setInventoryItemDetail] = useState<
    IInventoryItem | undefined
  >(inventoryFromState);

  const [displayList, setDisplayList] = useState<any[]>([]);
  const [historyList, setHistoryList] = useState<any[]>([]);

  const [selectedArea, setSelectedArea] = useState<number | null>(null);
  const [showRelocateModal, setShowRelocateModal] = useState(false);
  const [loadingRelocate, setLoadingRelocate] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [openAdjustQty, setOpenAdjustQty] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [quantityAjust, setQuantityAjust] = useState(0);

  const [openRestockModal, setOpenRestockModal] = useState<
    | {
      item_id: any;
      quantity: number;
      version?: string;
      version_date?: string;
      area_id?: any;
    }
    | undefined
  >();
  const { user } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const loadDetailData = () => {
    if (!id) return;
    dispatch(
      getDetailInventoryItem({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.id) {
          const o: any = { ...response.data }
          const qty = o?.item_real.reduce((sum: number, r: any) => sum + (r?.qty || 0), 0)
          const quantity = Math.floor(qty / o.compo)
          setInventoryItemDetail({ ...response.data, in_stock: quantity, available: quantity - o.on_hold });
        };
      });
  };
  useEffect(() => {
    loadDetailData();
  }, [id]);

  useEffect(() => {
    dispatch(getWarehouseAreas({ warehouse_id: 1 }));
  }, []);

  const loadDetailHistory = () => {
    // call api
    if (!id) return;
    dispatch(
      getDetailInventoryItemHistory({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus && response.data) {
          setHistoryList(response.data);
        };
      });
  };

  useEffect(() => {
    if (showHistory) {
      loadDetailHistory()

    }
  }, [showHistory]);

  const handleSelectedItem = (checked: boolean, item: IRealItem) => {
    if (checked) {
      setSelectedList([...selectedList, item.id]);
    } else {
      setSelectedList(selectedList.filter((id) => id !== item.id));
    }
  };

  const relocateItemHandle = (payload: IRelocateItem) => {
    setLoadingRelocate(true);
    dispatch(relocateItem(payload))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          loadDetailData();
          setSelectedList([]);
          setShowRelocateModal(false);
          notification.success();
        } else {
          notification.error();
        }
        setLoadingRelocate(false);
      });
  };

  const handleAdjustQty = () => {
    if (!quantityAjust) return;
    setLoadingRelocate(true);
    dispatch(
      updateAdjustQty({
        item_id: inventoryDetail?.id,
        quantity: quantityAjust
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          setOpenAdjustQty(false);
          loadDetailData();
          notification.success();
        } else {
          notification.error(response.message);
        }
        setLoadingRelocate(false);
      });
  };

  const restockItemHandle = () => {
    if (!openRestockModal) return;
    setLoadingRelocate(true);
    dispatch(
      reStockItem({
        ...openRestockModal,
        version_date: dayjs(openRestockModal?.version_date).format("DDMMYY"),
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          loadDetailData();
          setOpenRestockModal(undefined);
          notification.success();
        } else {
          notification.error();
        }
        setLoadingRelocate(false);
      });
  };
  const handleDeleteMulti = async () => {
    if (user?.roleName !== RolesEnum.CEO) {
      return
    }
    let title = "Adjust Stock";
    if (await ConfirmUtils.sure(title)) {
      dispatch(removeStockItem({
        ids: selectedList
      }))
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) {
            loadDetailData();
            setSelectedList([])
            notification.success();
          } else {
            notification.error(response.message);
          }
        });
    }
  };

  const handleRestoreMulti = async () => {
    if (user?.roleName !== RolesEnum.CEO) {
      return
    }
    let title = "Restore Serial Number";
    if (await ConfirmUtils.sure(title)) {
      dispatch(restoreStockItem({
        ids: selectedList
      }))
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) {
            loadDetailHistory()
            loadDetailData();
            setSelectedList([])
            notification.success();
          } else {
            notification.error(response.message);
          }
        });
    }
  };

  const columns = [
    ...(isMobile() ? [] : [{
      key: "item_selected",
      label: (
        <MoreActions
          actions={[
            {
              label: "Select this page",
              onClick: () => {
                setSelectedList(
                  _.uniq(selectedList.concat(displayList))
                );
              },
            },
            {
              label: "Select all",
              onClick: () => {
                setSelectedList(
                  inventoryDetail?.item_real.map((e) => e.id) || []
                );
              },
            },
            {
              label: "Unselect",
              onClick: () => {
                setSelectedList([]);
              },
            },
          ]}
        />
      ),
      render: (row: any) => (
        <Checkbox
          checked={selectedList.includes(row?.id)}
          onChange={(e) => handleSelectedItem(e.target.checked, row)}
        />
      ),
      width: 40,
    }]),
    {
      key: "serial_number",
      label: "Serial Number",
      render: (item: any) => (
        <>
          <Typography>{item.serial_number || "-"}</Typography>
          {item.old_serial ? (
            <small>{item.old_serial}</small>
          ) : ""}
          {!!item.picked.length && <Typography color="danger">
            {[ITEM_TYPE_VALUE.n].includes(inventoryDetail?.type as string) ?
              <small>
                Picked: {item.picked.reduce(
                  (a: number, i: any) => a + Number(i.qty as number),
                  0,
                )}
              </small>
              :
              <small>Picked</small>}
          </Typography>}
        </>
      ),
      width: 200,
    },
    {
      key: "pick",
      hide: true,
      label: "Picked",
      rawValue: (item: any) => `${!!item.picked.length ? 'Picked' : ''}`,
    },
    {
      key: "received_date",
      label: "Received Date",
      render: (row: any) => <>{row?.received_date ? formatDate(row.received_date) : "--"}</>,
      width: 130,
    },
    {
      key: "location",
      label: "Location",
      render: (item: any) =>
        !!item?.warehouse_area_id
          ? displayAreasLevel(
            findAreaLevel(item?.warehouse_area_id, warehouseAreas)
          )
          : "-",
    },
    ...(inventoryDetail?.type === ITEM_TYPE_VALUE.n ? [{
      key: "qty",
      label: "Qty",
      rawValue: (item: any) => item.qty,
      width: 80,
    }, {
      key: "sold",
      label: "Sold",
      rawValue: (item: any) => item.sold,
      width: 80,
    }] : []),

    {
      key: "po_id",
      label: "PO",
      render: (row: any) => <>{row?.po?.po_number}</>,
      width: 100,
    },
    {
      key: "status",
      label: "Status",
      rawValue: (item: any) => (item.status),
      render: (row: any) => <ChipStatus status={row?.status} size="small" />,
      width: 100,
    },
  ]

  return (
    <>
      <PageWrapper
        pageName={inventoryDetail?.checked ? <>
          {detailId ? <IconButton onClick={onViewModalCancel}><CloseOutlined fontSize="small" /></IconButton> : "Item Detail"}
          <Tooltip
            title={"Item Counted"}
          >
            <VerifiedUserOutlined
              fontSize="small"
              sx={{ ml: 1 }}
              color="success"
            />
          </Tooltip>
        </> : <>

          {detailId ? <IconButton onClick={onViewModalCancel}><CloseOutlined fontSize="small" /></IconButton> : "Item Detail"}

        </>}
        breadcrumb={[
          {
            label: "Inventory",
            link: ROUTERS.INVENTORY,
          },
          {
            label: "Detail",
          },
        ]}
        skeletonLoading={!inventoryDetail ? <DetailSkeleton /> : undefined}
        pageAction={[
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {
              user?.roleName === RolesEnum.CEO &&
              <Button
                variant="plain"
                color="primary"
                size="sm"
                onClick={() => {
                  setOpenRestockModal({
                    quantity: 1,
                    item_id: inventoryDetail?.id,
                    version: "01",
                  });
                }}
              >
                Manual Stock
              </Button>
            }

            <Checkbox checked={showHistory} onChange={(e) => setShowHistory(e.target.checked)} label="History" />

          </Box >
        ]
        }
      >
        <Grid container spacing={2}>
          <Grid sm={5}>
            <Stack>
              {inventoryDetail &&
                <>
                  <Typography fontWeight={500} className={"lineItem"}>
                    <strong>{inventoryDetail.code} </strong>
                    {ITEM_TYPE[inventoryDetail?.type] && [ITEM_TYPE_VALUE.fset, ITEM_TYPE_VALUE.rset].includes(inventoryDetail?.type) ? <small className={inventoryDetail?.type}>
                      {ITEM_TYPE[inventoryDetail?.type]} - {inventoryDetail?.compo} components
                    </small>
                      :
                      ITEM_TYPE[inventoryDetail?.type] && <small className={inventoryDetail?.type}>
                        {ITEM_TYPE[inventoryDetail?.type]}
                      </small>
                    }
                  </Typography>
                  <Typography>
                    <small>{inventoryDetail.product.name} - {inventoryDetail.name}</small>
                  </Typography>

                  {inventoryDetail?.sku && <Typography><small>Location notice: <b>{inventoryDetail.sku}</b></small></Typography>}

                </>
              }
              {showHistory && <div style={{ color: "red" }}><b>YOU ARE VIEWING HISTORY</b></div>}
            </Stack>
          </Grid>
          <Grid sm={2}>
            <Stack>
              <Typography sx={{ fontSize: 14 }}>Available</Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {
                  inventoryDetail?.available || 0
                }
                {' '}
                {
                  inventoryDetail?.unit
                }
              </Typography>
            </Stack>
          </Grid>
          <Grid sm={2}>
            <Stack>
              <Typography sx={{ fontSize: 14, position: "relative" }}> Instock
                {user?.roleName === RolesEnum.CEO && <IconButton sx={{
                  position: "absolute",
                  top: 0,
                  left: 80
                }}
                  onClick={() => {
                    setOpenAdjustQty(true)
                    setQuantityAjust(inventoryDetail?.in_stock || 0)
                  }}
                  size="sm">
                  <Edit fontSize="small" />
                </IconButton>}
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {
                  inventoryDetail?.in_stock || 0
                }
                {' '}
                {
                  inventoryDetail?.unit
                }
              </Typography>
            </Stack>
          </Grid>

          {getPermission("VIEW_STOCK_VALUE") && <Grid sm={2}>
            <Stack>
              <Typography sx={{ fontSize: 14 }}>Stock Value</Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {!!inventoryDetail &&
                  showCurrency(
                    ((inventoryDetail?.in_stock || 0) *
                      inventoryDetail?.cost) as number
                  )}
              </Typography>
            </Stack>
          </Grid>}
        </Grid>

        {
          !showHistory ?
            <CommonList
              pageSize={100}
              noLoading={true}
              data={inventoryDetail?.item_real || []}
              onChangeList={setDisplayList}
              tableActions={
                !isMobile() &&
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 1,
                  }}
                >
                  {!!selectedList.length && (
                    <Typography>{selectedList.length} selected</Typography>
                  )}

                  <MoreActions
                    loading={actionLoading}
                    actions={[
                      {
                        label: "Delete selected item",
                        disabled: user?.roleName !== RolesEnum.CEO || !selectedList.length,
                        onClick: () => {
                          handleDeleteMulti()
                        },
                      },
                      {
                        label: "Relocate selected item",
                        disabled: !selectedList.length,
                        onClick: () => {
                          setSelectedArea(null);
                          setShowRelocateModal(true);
                        },
                      },
                      {
                        label: "Print Barcode",
                        disabled: !selectedList.length,
                        onClick: () => {
                          printSerialBarCodeFromArrays(
                            inventoryDetail?.item_real
                              .filter(
                                (v) =>
                                  v.serial_number && selectedList.includes(v.id)
                              )
                              .map((v) => v.serial_number) || []
                          );
                        },
                      },
                      {
                        label: "Export Barcode Data",
                        disabled: !selectedList.length,
                        onClick: () => {
                          exportBarCodeCSV(
                            inventoryDetail?.item_real
                              .filter(
                                (v) =>
                                  v.serial_number && selectedList.includes(v.id)
                              )
                              .map((v) => ({
                                serial_number: v.serial_number,
                                code: inventoryDetail?.code,
                              })) || []
                          );
                        },
                      },
                    ]}
                  />
                </Box>
              }
              columns={columns}
              searchKeys={["serial_number", 'pick']}
              filterStatus={{
                statusKey: "",
                data: [],
              }}
              sortKeys={["serial_number", "status", "received_date"]}
            />
            :

            <CommonList
              pageSize={100}
              noLoading={true}
              data={historyList || []}
              onChangeList={setDisplayList}
              tableActions={
                !isMobile() &&
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 1,
                  }}
                >
                  {!!selectedList.length && (
                    <Typography>{selectedList.length} selected</Typography>
                  )}

                  <MoreActions
                    loading={actionLoading}
                    actions={[
                      {
                        label: "Restore",
                        disabled: user?.roleName !== RolesEnum.CEO || !selectedList.length,
                        onClick: () => {
                          handleRestoreMulti()
                        },
                      },

                    ]}
                  />
                </Box>
              }
              columns={columns}
              searchKeys={["serial_number", 'pick']}
              filterStatus={{
                statusKey: "",
                data: [],
              }}
              sortKeys={["serial_number", "status", "received_date"]}
            />
        }

      </PageWrapper >

      {!!openRestockModal && (
        <BaseModal
          isOpen={!!openRestockModal}
          onClose={() => setOpenRestockModal(undefined)}
          title="Manual Stock"
          actions={
            <Button
              loading={loadingRelocate}
              onClick={restockItemHandle}
              disabled={
                !openRestockModal?.quantity ||
                !openRestockModal?.area_id ||
                !openRestockModal?.version_date ||
                !openRestockModal?.version
              }
            >
              Process
            </Button>
          }
          width={900}
        >
          <Box>
            <Grid container spacing={2}>
              <Grid xs={12} sm={3}>
                <FormControl>
                  <BaseNumberInput
                    label="Quantity"
                    value={openRestockModal?.quantity}
                    onChange={(val) => {
                      setOpenRestockModal({
                        ...openRestockModal,
                        quantity: val,
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} sm={3}>
                <FormControl>
                  <BaseSelect
                    label="Version"
                    value={openRestockModal?.version}
                    options={SERIAL_VERSIONS}
                    onChange={(e) => {
                      setOpenRestockModal({
                        ...openRestockModal,
                        version: e,
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} sm={3}>
                <FormControl>
                  <BaseDatePicker
                    label="Date Version"
                    value={openRestockModal?.version_date}
                    onChange={(e) => {
                      setOpenRestockModal({
                        ...openRestockModal,
                        version_date: e,
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid sm={12} xs={12}>
                <CardWithLabel label="To Location">
                  <SelectWarehouseLocation
                    areas={warehouseAreas}
                    onSelect={(area) => {
                      setOpenRestockModal({
                        ...openRestockModal,
                        area_id: area?.id,
                      });
                    }}
                  />
                </CardWithLabel>
              </Grid>
            </Grid>
          </Box>
        </BaseModal>
      )}

      <BaseModal
        isOpen={showRelocateModal}
        onClose={() => setShowRelocateModal(false)}
        title="Relocate Items"
        actions={
          <Button
            loading={loadingRelocate}
            onClick={() => {
              relocateItemHandle({
                area_id: Number(selectedArea),
                id: selectedList,
              });
            }}
            disabled={!selectedList.length || !selectedArea}
          >
            Relocate
          </Button>
        }
        width={900}
      >
        <Box>
          <Grid container spacing={2}>
            <Grid sm={12}>
              <CommonList
                pageSize={20}
                noLoading={true}
                data={
                  selectedList.map((id) =>
                    inventoryDetail?.item_real.find((e) => e.id === id)
                  ) as IRealItem[]
                }
                size="sm"
                columns={[
                  {
                    key: "serial_number",
                    label: "Serial Number",
                  },
                  {
                    key: "location",
                    label: "From Location",
                    render: (item) =>
                      !!item?.warehouse_area_id
                        ? displayAreasLevel(
                          findAreaLevel(
                            item?.warehouse_area_id,
                            warehouseAreas
                          )
                        )
                        : "-",
                  },
                ]}
                searchKeys={[]}
                filterStatus={{
                  statusKey: "",
                  data: [],
                }}
                sortKeys={["status", "serial_number", "item_slot"]}
              />
            </Grid>

            <Grid sm={12} xs={12}>
              <CardWithLabel label="To Location">
                <SelectWarehouseLocation
                  areas={warehouseAreas}
                  onSelect={(area) => setSelectedArea(area?.id as any)}
                />
              </CardWithLabel>
            </Grid>
          </Grid>
        </Box>
      </BaseModal>

      <BaseModal
        isOpen={!!openAdjustQty}
        onClose={() => setOpenAdjustQty(false)}
        title="Manual Adjust Quantity"
        width={290}
        actions={
          <Button
            onClick={handleAdjustQty}
          >
            Process
          </Button>
        }
      >
        <Box>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <FormControl>
                <BaseNumberInput
                  label="Quantity"
                  value={quantityAjust}
                  onChange={(val) => {
                    setQuantityAjust(Number(val))
                  }}
                />
              </FormControl>
            </Grid>

          </Grid>
        </Box>
      </BaseModal>
    </>
  );
}
