import { ClearOutlined, MoreVertOutlined } from "@mui/icons-material";
import { Divider, FormControl, Grid, IconButton, Stack } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import { unwrapResult } from "@reduxjs/toolkit";
import { ITEM_TYPE_VALUE } from "app-constants";
import BaseCurrencyInput from "components/BaseCurrencyInput";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import BaseNumberInput from "components/BaseNumberInput";
import BaseSelect from "components/BaseSelect";
import CardWithLabel from "components/CardWithLabel";
import ConfirmUtils from "components/ConfirmUtils";
import PageWrapper from "components/PageWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux-store";
import { createProduct } from "redux-store/reducers/product";
import ROUTERS from "routers/constants";
import { IProductItem } from "types/product";
import notification from "utils/notification";
import CreateBOMItems from "./components/CreateBOMItems";
import ModalUpdateCustomFields from "./components/ModalUpdateCustomFields";

export const ItemTypeOptions = [
  {
    label: "Invididual",
    value: ITEM_TYPE_VALUE.y,
  },
  {
    label: "Spare part",
    value: ITEM_TYPE_VALUE.n,
  },
  {
    label: "Fixed Set",
    value: ITEM_TYPE_VALUE.fset,
  },
  {
    label: "Random Set",
    value: ITEM_TYPE_VALUE.rset,
  },
  {
    label: "BOM",
    value: ITEM_TYPE_VALUE.bom,
  }
]

export default function ProductNewPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [name, setName] = useState("");
  const [itemType, setItemType] = useState("y");
  const [itemComp, setItemComp] = useState(1);
  const [desc, setDesc] = useState("");
  const [productItems, setProductItems] = useState<IProductItem[]>([]);
  const [openAddNewItemModal, setOpenAddNewItemModal] =
    useState<IProductItem>();
  const [selectedItemCusField, setSelectedItemCusField] =
    useState<IProductItem | null>(null);
  const [subItems, setSubItems] = useState<{
    sub_id: string;
    qty: number;
  }[]>([]);

  const handleOpenCustomFieldsModal = (item: IProductItem) => {
    setSelectedItemCusField(item);
  };

  useEffect(() => {
    if (![ITEM_TYPE_VALUE.fset, ITEM_TYPE_VALUE.rset].includes(itemType)) {
      setItemComp(1)
    }
  }, [itemType]);

  const handleCreateNewProduct = () => {
    if ([ITEM_TYPE_VALUE.fset, ITEM_TYPE_VALUE.rset].includes(itemType) && itemComp === 1) {
      return notification.error("Component Number must be grater than 1")
    }
    setLoading(true);
    dispatch(
      createProduct({
        name,
        itemType,
        itemComp,
        description: desc,
        items: productItems,
        sub_items: subItems,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        if (response.data?.id) {
          notification.success();
          navigate(ROUTERS.PRODUCT);
        } else {
          notification.error();
        }
      });
  };
  return (
    <>
      <PageWrapper
        pageAction={
          <Box>
            <Button
              sx={{ marginRight: 1 }}
              variant="plain"
              size="sm"
              onClick={() => navigate(ROUTERS.PRODUCT)}
              disabled={loading}
            >
              Cancel
            </Button>

            <Button
              color="primary"
              size="sm"
              onClick={handleCreateNewProduct}
              loading={loading}
              disabled={
                !name ||
                !productItems.length ||
                productItems.some(
                  (item) =>
                    !item.name ||
                    !item.code ||
                    !item.price ||
                    !item.unit
                )
              }
            >
              Save
            </Button>
          </Box>
        }
        pageName="New Product"
        breadcrumb={[
          {
            label: "Product",
            link: ROUTERS.PRODUCT,
          },
          {
            label: "New",
          },
        ]}
      >
        <Grid container spacing={3} sx={{ py: 1 }}>
          <Grid sm={6}>
            <FormControl>
              <BaseInput
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
          </Grid>

          <Grid sm={4}>
            <FormControl>
              <BaseSelect
                value={itemType}
                label="Item Type"
                onChange={(e) => {
                  setItemType(e)
                }}
                options={ItemTypeOptions}
              />
            </FormControl>
          </Grid>
          <Grid sm={2}>
            <FormControl>
              <BaseNumberInput
                disabled={![ITEM_TYPE_VALUE.fset, ITEM_TYPE_VALUE.rset].includes(itemType as string)}
                value={itemComp}
                min={1}
                label="Component Number"
                onChange={(e) => {
                  setItemComp(Number(e))
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Stack>
          {/* items */}
          <CardWithLabel label="Variants">
            <Stack spacing={1}>
              <Stack>
                {productItems?.map((item, index) => (
                  <Box key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid sm={3}>
                          <BaseInput
                            label="Name"
                            value={item?.name}
                            onChange={(e) => {
                              setProductItems((prev) => {
                                const newItems = [...prev];
                                newItems[index].name = e.target.value;
                                return newItems;
                              });
                            }}
                          />
                          <BaseInput
                            sx={{
                              mt: 2,
                            }}
                            label="Code"
                            value={item?.code}
                            onChange={(e) => {
                              setProductItems((prev) => {
                                const newItems = [...prev];
                                newItems[index].code = e.target.value;
                                return newItems;
                              });
                            }}
                          />
                        </Grid>

                        <Grid sm>
                          <FormControl>
                            <BaseCurrencyInput
                              label="Cost"
                              value={item?.cost}
                              onChange={(e: any) => {
                                setProductItems((prev) => {
                                  const newItems = [...prev];
                                  newItems[index].cost = Number(e);
                                  return newItems;
                                });
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid sm>
                          <FormControl>
                            <BaseCurrencyInput
                              label="FOB Price"
                              value={item?.fob_price}
                              onChange={(e: any) => {
                                setProductItems((prev) => {
                                  const newItems = [...prev];
                                  newItems[index].fob_price = Number(e);
                                  return newItems;
                                });
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid sm>
                          <FormControl>
                            <BaseCurrencyInput
                              label="US WH Price"
                              value={item?.price}
                              onChange={(e: any) => {
                                setProductItems((prev) => {
                                  const newItems = [...prev];
                                  newItems[index].price = Number(e);
                                  return newItems;
                                });
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid sm>
                          <FormControl>
                            <BaseCurrencyInput
                              label="Special Price"
                              value={item?.special_price}
                              onChange={(e: any) => {
                                setProductItems((prev) => {
                                  const newItems = [...prev];
                                  newItems[index].special_price = Number(e);
                                  return newItems;
                                });
                              }}
                            />
                          </FormControl>
                        </Grid>


                        <Grid sm>
                          <FormControl>
                            <BaseCurrencyInput
                              label="End User Price"
                              value={item?.regular_price}
                              onChange={(e: any) => {
                                setProductItems((prev) => {
                                  const newItems = [...prev];
                                  newItems[index].regular_price = Number(e);
                                  return newItems;
                                });
                              }}
                            />
                          </FormControl>
                        </Grid>



                        <Grid sm>
                          <FormControl>
                            <BaseInput
                              label="Unit"
                              value={item?.unit}
                              onChange={(e: any) => {
                                setProductItems((prev) => {
                                  const newItems = [...prev];
                                  newItems[index].unit = e.target.value;
                                  return newItems;
                                });
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <IconButton
                        sx={{
                          marginLeft: 1,
                        }}
                        onClick={() => handleOpenCustomFieldsModal(item)}
                      >
                        <MoreVertOutlined />
                      </IconButton>
                      <IconButton
                        sx={{
                          marginLeft: 1,
                        }}
                        onClick={() => {
                          ConfirmUtils.sure().then((isOk) => {
                            if (isOk) {
                              const newItems = [...productItems];
                              newItems.splice(index, 1);
                              setProductItems(newItems);
                            }
                          });
                        }}
                      >
                        <ClearOutlined />
                      </IconButton>
                    </Box>
                    <Divider
                      sx={{
                        my: 2,
                      }}
                    />
                  </Box>
                ))}
                {!(itemType === ITEM_TYPE_VALUE.bom && productItems.length === 1) ?
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: 2,
                    }}
                  >
                    <Button
                      size="sm"
                      variant="outlined"
                      onClick={() => {
                        setOpenAddNewItemModal({
                          name: "",
                          code: "",
                          price: 0,
                          fob_price: 0,
                          cost: 0,
                          special_price: 0,
                          container_price: 0,
                          regular_price: 0,
                          unit: "",
                          custom_field: [],
                        });
                      }}
                    >
                      Add Item
                    </Button>
                  </Box> :
                  <CreateBOMItems
                    subItems={subItems}
                    setSubItems={setSubItems}
                  />
                }
              </Stack>
            </Stack>
          </CardWithLabel>
          {/* items */}

          <FormControl sx={{ mt: 2 }}>
            <BaseInput
              label="Description"
              multiline
              minRows={3}
              maxRows={5}
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </FormControl>
        </Stack>

        {!!selectedItemCusField && (
          <ModalUpdateCustomFields
            isOpen={!!selectedItemCusField}
            onClose={() => setSelectedItemCusField(null)}
            onChange={(custom_field) => {
              selectedItemCusField.custom_field = custom_field;
              setProductItems([...productItems]);
            }}
            customFields={
              selectedItemCusField?.custom_field?.length
                ? selectedItemCusField?.custom_field
                : [
                  {
                    name: "",
                    value: "",
                  },
                ]
            }
          />
        )}

        {!!openAddNewItemModal && (
          <BaseModal
            title="Add Item"
            isOpen={!!openAddNewItemModal}
            onClose={() => setOpenAddNewItemModal(undefined)}
            actions={
              <Button
                size="sm"
                onClick={() => {
                  setProductItems([...productItems, openAddNewItemModal]);
                  setOpenAddNewItemModal(undefined);
                }}
                disabled={
                  !openAddNewItemModal.name ||
                  !openAddNewItemModal.code ||
                  !openAddNewItemModal.price ||
                  !openAddNewItemModal.unit
                }
              >
                Create
              </Button>
            }
          >
            <Grid container spacing={2}>

              <Grid xs={6}>
                <FormControl>
                  <BaseInput
                    label="Name"
                    value={openAddNewItemModal.name}
                    onChange={(e) => {
                      setOpenAddNewItemModal({
                        ...openAddNewItemModal,
                        name: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={6}>
                <FormControl>
                  <BaseInput
                    label="Code"
                    value={openAddNewItemModal.code}
                    onChange={(e) => {
                      setOpenAddNewItemModal({
                        ...openAddNewItemModal,
                        code: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              {itemType !== ITEM_TYPE_VALUE.bom && <>
                <Grid xs={6}>
                  <FormControl>
                    <BaseCurrencyInput
                      label="Cost"
                      value={openAddNewItemModal.cost}
                      onChange={(e) => {
                        setOpenAddNewItemModal({
                          ...openAddNewItemModal,
                          cost: Number(e),
                        });
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid xs={6}>
                  <FormControl>
                    <BaseCurrencyInput
                      label="FOB Price"
                      value={openAddNewItemModal.fob_price}
                      onChange={(e) => {
                        setOpenAddNewItemModal({
                          ...openAddNewItemModal,
                          fob_price: Number(e),
                        });
                      }}
                    />
                  </FormControl>
                </Grid>

              </>}
              <Grid xs={6}>
                <FormControl>
                  <BaseCurrencyInput
                    label="US WH Price"
                    value={openAddNewItemModal.price}
                    onChange={(e) => {
                      setOpenAddNewItemModal({
                        ...openAddNewItemModal,
                        price: Number(e),
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={6}>
                <FormControl>
                  <BaseCurrencyInput
                    label="Special Price"
                    value={openAddNewItemModal.special_price}
                    onChange={(e) => {
                      setOpenAddNewItemModal({
                        ...openAddNewItemModal,
                        special_price: Number(e),
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={6}>
                <FormControl>
                  <BaseCurrencyInput
                    label="End User Price"
                    value={openAddNewItemModal.regular_price}
                    onChange={(e) => {
                      setOpenAddNewItemModal({
                        ...openAddNewItemModal,
                        regular_price: Number(e),
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={6}>
                <FormControl>
                  <BaseInput
                    label="Unit"
                    value={openAddNewItemModal.unit}
                    onChange={(e) => {
                      setOpenAddNewItemModal({
                        ...openAddNewItemModal,
                        unit: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </BaseModal>
        )}
      </PageWrapper>
    </>
  );
}
