import { Edit } from "@mui/icons-material";
import {
  Box
} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux-store";

import EditHoverPopup from "components/EditHoverPopup";
import {
  updateSOInternalNote
} from "redux-store/reducers/sales-order";
import { ISalesOrder } from "types/sales-order";
import notification from "utils/notification";
import { getPermission } from "utils/roles";

const SOInternalNote = ({
  salesOrderDetail,
  onUpdateSuccess,
  noteLabel = 'Internal Note',
  noteKey = 'internal_note'
}: {
  salesOrderDetail: ISalesOrder;
  onUpdateSuccess: () => void;
  noteLabel: string;
  noteKey: 'note' | 'internal_note';
}) => {
  const dispatch = useAppDispatch();

  const noteValue = (salesOrderDetail as any)[noteKey];

  return <Box>
    <Typography fontSize="sm" sx={{ mt: 1 }}>
      {noteLabel}:
      {getPermission("ALLOW_UPDATE_SO") && (<EditHoverPopup
        renderButton={(onClick) => (
          <Edit
            onClick={onClick}
            style={{
              marginLeft: 8,
              cursor: "pointer",
            }}
          />
        )}
        title={noteLabel}
        editBtnPosition={{}}
        editFields={[
          {
            label: noteLabel,
            key: noteKey,
            value: noteValue,
            type: "textarea",
          },
        ]}
        onSave={(newData, closeEdit) => {
          dispatch(
            updateSOInternalNote({
              id: salesOrderDetail.id,
              [noteKey]: newData[noteKey],
            })
          )
            .then(unwrapResult)
            .then(({ response }) => {
              if (response.apiStatus) {
                notification.success();
                closeEdit();
                onUpdateSuccess();
              } else {
                notification.error();
              }
            });
        }}
      />)}
    </Typography>

    <Typography fontSize="sm" fontWeight={500} sx={{
      whiteSpace: "pre-line",
    }}>
      {noteValue || "-"}
    </Typography>
  </Box>
}

export default SOInternalNote;