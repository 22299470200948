import { Box, GlobalStyles, Table, Typography } from '@mui/joy';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'redux-store';
import { getSOUPSSummary } from 'redux-store/reducers/sales-order';
import { formatDate, parseAddress } from 'utils/common';

export default function PrintPPSList() {
  const dispatch = useAppDispatch()
  const [soData, setSOData] = useState<any[]>([])
  useEffect(() => {
    dispatch(getSOUPSSummary({})).unwrap().then(({ response }) => {
      setSOData(response.data || [])

      setTimeout(() => {
        window.print()
      }, 500)
      // window.onfocus = function () {
      //   setTimeout(function () {
      //     window.close();
      //     window.history.back();
      //   }, 500);
      // }
    })
  }, [])

  const totalPkgs = soData.reduce((acc, so) => acc + so.packages?.length || 0, 0)


  return <Box
  >
    <GlobalStyles
      styles={{
        '@media print': {
          tr: {
            pageBreakInside: 'avoid !important'
          },
        }
      }}
    />
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
    }}>
      <Box>
        <Typography
          level='title-md'
          fontSize={15}
        >UPS End of Day Report</Typography>
        <Typography level='body-sm' fontSize={14}>{formatDate(Date.now())}</Typography>
      </Box>
      <Box>
        <Typography
          level='title-md'
          fontSize={15}
          textAlign='right'
        >Abaco Warehouse US</Typography>
        <Typography textAlign='right' level='body-sm' fontSize={13}>14508 S Garfield Ave, Paramount</Typography>
        <Typography textAlign='right' level='body-sm' fontSize={13}>CA, 90723, USA</Typography>

      </Box>

    </Box>
    <Table>
      <thead>
        <tr>
          <th style={{
            width: '50%'
          }}>Ship Addresses</th>
          <th>Shipment Details</th>
        </tr>
      </thead>
      <tbody>
        {soData.map((so, idx) => <>
          {
            so.packages.map((packageDetail: any, jdx: any) => <tr key={`${idx}_${jdx}`}>
              {jdx === 0 && <td rowSpan={
                so.packages.length
              }
                style={{
                  verticalAlign: 'top'
                }}
              >
                <Box sx={{
                  whiteSpace: 'pre-wrap'
                }}>
                  <Typography fontWeight={500}>Sales Order: {so.so_number}</Typography>
                  <Typography fontWeight={500}>{so.customer.first_name}</Typography>
                  <Typography sx={{ mt: 1 }} level='body-sm' fontSize={11}>Shipping Address:</Typography>
                  <Typography fontSize={13}>{parseAddress(so.shipping_address)}</Typography>
                </Box>
              </td>}
              <td>
                <Typography level='body-sm'
                  fontSize={10}
                >Ship service: <b>{so.shipping_service}</b></Typography>
                <Typography level='body-sm' fontSize={10}>Package type: Package</Typography>
                <Typography level='body-sm' fontSize={10}>Tracking number: <b>{packageDetail.tracking_number}</b></Typography>
                <Typography sx={{ mt: 1 }} level='body-sm' fontSize={10}>Weight: {packageDetail.weight} lbs</Typography>
                <Typography level='body-sm' fontSize={10}>Dimensions:  {packageDetail.length} x {packageDetail.width} x {packageDetail.height} inches</Typography>
                <Typography level='body-sm' fontSize={10}>Ref Package: <b>{packageDetail.id}</b></Typography>
              </td>
            </tr >)
          }
          <tr>
            <td colSpan={2}
              style={{
                padding: 0,
                backgroundColor: '#ddd',
                height: 2,
              }}
            >
            </td>
          </tr>
        </>)}
      </tbody>
    </Table>

    <Box
      id='footer'
      sx={{
        mt: 5,
      }}
    >
      <Typography
        level='body-sm'
        fontSize={12}
      >
        SHIPMENTS SUBJECT TO TERMS OF AGREEMENT ON FILE
      </Typography>
      <Typography
        level='title-sm'
        fontSize={12}
      >
        Received By: _______________________
      </Typography>
      <Typography
        level='title-sm'
        fontSize={12}
      >
        Pickup Time: _______________________ Pkgs: {totalPkgs}
      </Typography>

    </Box>
  </Box >
}