import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import dayjs from 'utils/dayjs-timezone';

export default function BaseDatePicker(props: {
  value?: string
  onChange?: (newVal: string) => void
} & Omit<DatePickerProps<any>, 'value' | 'onChange'>,) {
  return <DatePicker {...props}
    value={props.value ? dayjs(props.value, "YYYY-MM-DD") : null}
    onChange={(e) => {
      const newVal = dayjs(e).format("YYYY-MM-DD")
      props.onChange?.(newVal)
    }}
    slotProps={{ textField: { size: 'small' } }}
    format="MM/DD/YYYY"
  />
}